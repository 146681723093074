import React, { FC, useMemo } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/MenuOpen';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';

import { IBanner, IBannerTableTooltip, IBannerTreeTable, Placement } from '../interfaces';
import { API_DOMAIN } from '../../../../constants';
import { useFormatMessage } from '../../../../hooks';

const useTreeItemStyles = makeStyles((theme: Theme) => ({
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(0.7),
    paddingTop: theme.spacing(0.7),
  },
  labelText: {
    flexGrow: 1,
    fontWeight: 500,
    fontSize: 14,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  labelIcon: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  img: {
    width: 100,
    height: 50,
  },
}));

interface IPropsRenderTree {
  title: string;
  banners: IBanner[] | undefined;
  classes: any;
  handleDeleteDialog: any;
  editAction: any;
  pushAction: any;
  tooltip: IBannerTableTooltip;
  productListAction: any;
  selectItemAction: any;
  selectPlacement: any;
  is_admin: boolean;
  is_manager: boolean;
}

interface IPropsTreeNode {
  item: IBanner;
  classes: any;
  handleDeleteDialog: any;
  editAction: any;
  pushAction: any;
  tooltip: IBannerTableTooltip;
  productListAction: any;
  selectItemAction: any;
  is_admin: boolean;
  is_manager: boolean;
}

const TreeNode: FC<IPropsTreeNode> = React.memo(
  ({
    item,
    classes,
    handleDeleteDialog,
    editAction,
    tooltip,
    selectItemAction,
    is_admin,
    is_manager,
  }) => (
    <div className={classes.labelRoot} onClick={() => selectItemAction(item.id)}>
      <Table aria-label='simple table'>
        <TableBody>
          <TableRow key={item.id}>
            <TableCell align='left'>
              <CardMedia
                className={classes.img}
                style={{
                  objectFit: 'contain',
                }}
                image={`${API_DOMAIN}/${item?.photo.small}`}
              />
            </TableCell>
            <TableCell style={{ width: 300 }} align='left'>
              {item.url ? (
                <a className={classes.buttons} href={item.url}>
                  {item.title}
                </a>
              ) : (
                <Link
                  component={RouterLink}
                  to={`/viewproduct/${item?.product?.id}`}
                  className={classes.buttons}
                >
                  {item.title}
                </Link>
              )}
            </TableCell>
            <TableCell align='right'>
              {is_admin && (
                <>
                  <Tooltip title={tooltip.edit}>
                    <IconButton
                      onClick={() => editAction(item)}
                      size='small'
                      className={classes.labelIcon}
                    >
                      <EditIcon color='primary' fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  {!is_manager && (
                    <Tooltip title={tooltip.delete}>
                      <IconButton
                        onClick={() => {
                          handleDeleteDialog(item.id);
                        }}
                        size='small'
                        className={classes.labelIcon}
                      >
                        <DeleteIcon color='error' fontSize='small' />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
);

const RenderTree: FC<IPropsRenderTree> = React.memo(
  ({
    title,
    banners,
    classes,
    handleDeleteDialog,
    editAction,
    pushAction,
    tooltip,
    productListAction,
    selectItemAction,
    selectPlacement,
    is_admin,
    is_manager,
  }) => {
    const fm = useFormatMessage();

    return (
      <TreeItem
        key={title}
        itemId={title || 'key'}
        label={
          <div className={classes.labelRoot} onClick={() => selectPlacement(title)}>
            <Typography variant='body2' className={classes.labelText}>
              {fm(title)}
            </Typography>

            <Tooltip title={tooltip.add}>
              <IconButton
                onClick={() => {
                  pushAction(title);
                }}
                size='small'
                className={classes.labelIcon}
              >
                <AddIcon color='primary' fontSize='small' />
              </IconButton>
            </Tooltip>
          </div>
        }
      >
        {banners?.map(item => (
          <TreeNode
            item={item}
            classes={classes}
            handleDeleteDialog={handleDeleteDialog}
            editAction={editAction}
            pushAction={pushAction}
            tooltip={tooltip}
            productListAction={productListAction}
            selectItemAction={selectItemAction}
            is_admin={is_admin}
            is_manager={is_manager}
          />
        ))}
      </TreeItem>
    );
  }
);

const CategoriesTree: React.FC<IBannerTreeTable> = ({
  banners,
  handleDeleteDialog,
  editAction,
  pushAction,
  productListAction,
  selectProductId,
  selectPlacement,
  classes,
  is_admin,
  is_manager,
}) => {
  const innerClasses = useTreeItemStyles();
  const intl = useIntl();
  const tooltip: IBannerTableTooltip = {
    productList: intl.formatMessage({ id: 'CATEGORIES.TABLE.TOOLTIP.PRODUCTS' }),
    edit: intl.formatMessage({ id: 'BANNER.TABLE.TOOLTIP.EDIT' }),
    add: intl.formatMessage({ id: 'BANNER.TABLE.TOOLTIP.ADD' }),
    delete: intl.formatMessage({ id: 'BANNER.TABLE.TOOLTIP.DELETE' }),
  };
  const placements = [Placement.TOP, Placement.MID, Placement.BOTTOM];

  const placemensList = useMemo(() => {
    return (
      <>
        {placements.map(item => (
          <RenderTree
            title={item}
            banners={banners.get(item)}
            classes={innerClasses}
            handleDeleteDialog={handleDeleteDialog}
            editAction={editAction}
            pushAction={pushAction}
            tooltip={tooltip}
            productListAction={productListAction}
            selectItemAction={selectProductId}
            selectPlacement={selectPlacement}
            is_admin={is_admin}
            is_manager={is_manager}
          />
        ))}
      </>
    );
  }, [banners, innerClasses]);

  return (
    <Card className={classes.card}>
      <CardContent>
        <SimpleTreeView
        // col={<ArrowDropDownSharp color='secondary' />}
        // defaultExpanded={[]}
        // defaultExpandIcon={<ArrowRightSharpIcon color='secondary' />}
        >
          {placemensList}
        </SimpleTreeView>
      </CardContent>
    </Card>
  );
};

export default CategoriesTree;
