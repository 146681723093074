import React, { useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
} from '@mui/material';

import { useIntl } from 'react-intl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';

import moment from 'moment';
import FilterListIcon from '@mui/icons-material/FilterList';

import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { IAppState } from '../../../../store/rootDuck';
import { actions as statisticsActions } from '../../../../store/ducks/statistics.duck';
import { actions as companiesActions } from '../../../../store/ducks/companies.duck';
import homeStyles from '../../../../constants/homeStyles';

import { actions as profileActions } from '../../../../store/ducks/profile.duck';
import { useStyles } from '../hooks/useStyles';
import { UserRoles } from '../../../../interfaces/user';
import { useFormatMessage } from '../../../../hooks';
import { setLayoutFooter, setLayoutSubheader } from '../../../../utils/layout';

const StatisticsProductsList: React.FC<
  TPropsFromRedux & { type?: string; userId?: number; smallSendMsgBtn?: boolean }
> = ({
  statistics,
  loadingProducts,
  fetch,
  userRole,
  meLoading,
  fetchMe,
  clearMe,
  me,
  companies,
  fetchCompanies,
  clearCompanies,
}) => {
  const homeClasses = homeStyles();
  const classes = useStyles();
  const intl = useIntl();
  const fm = useFormatMessage();

  setLayoutSubheader({
    title: `${fm('SUBMENU.STATISTIC.PRODUCTS')}`,
  });

  setLayoutFooter({ show: true });

  const [startDate, setStartDate] = React.useState<string>(
    moment(new Date()).format('YYYY.MM.DD HH:mm')
  );
  const [endDate, setEndDate] = React.useState<string>(
    moment(new Date()).format('YYYY.MM.DD HH:mm')
  );
  const [changeRange, setChangeRange] = React.useState<boolean>(false);
  const [sortedItem, setSortedItem] = React.useState<any>([
    { name: 'view_from_catalog', status: true, count: 2, type: 'desc' },
    { name: 'view_from_favorite', status: false, count: 2, type: 'desc' },
    { name: 'add_to_cart', status: false, count: 2, type: 'desc' },
    { name: 'orders_summ', status: false, count: 2, type: 'desc' },
    { name: 'buy_checkout', status: false, count: 2, type: 'desc' },
  ]);

  const isAdmin = useMemo(() => userRole?.[0] === UserRoles.ROLE_ADMIN, [userRole]);
  const locales: { [key: string]: any } = {
    ru: ruLocale,
    en: enLocale,
  };
  const [companyId, setCompanyId] = React.useState('all');

  const handleChange = (event: any) => {
    setCompanyId(event.target.value);
  };
  const handleChangeRange = (start: string | Date, end: string | Date) => {
    const first = moment(start).format('YYYY-MM-DD HH:mm');
    const second = moment(end).format('YYYY-MM-DD HH:mm');
    setChangeRange(true);
    setStartDate(first);
    setEndDate(second);
  };

  const handleChangeSort = (item: string) => {
    setSortedItem((prev: any) => {
      const data = prev.map((el: any) => {
        if (el.name === item) {
          el.status = true;
          el.count = el.count !== 2 ? el.count + 1 : 0;
          if (el.count === 0) {
            el.type = 'desc';
          } else if (el.count === 1) {
            el.type = 'asc';
          } else {
            el.type = '';
          }
        } else {
          el.status = false;
          el.count = 0;
          el.type = 'desc';
        }
        return el;
      });
      data.map((el: any) => {
        if (!isAdmin && me?.company?.id && el.name === item) {
          if (changeRange) {
            return fetch({
              company_id: me?.company?.id,
              date_from: startDate,
              date_to: endDate,
              sort_for: el.name,
              sort_type: el.type,
            });
          }
          if (el.name === item) {
            return fetch({
              company_id: me?.company?.id,
              sort_for: el.name,
              sort_type: el.type,
            });
          }
        }
        if (changeRange && isAdmin && el.name === item && companyId === 'all') {
          return fetch({
            date_from: startDate,
            date_to: endDate,
            sort_for: el.name,
            sort_type: el.type,
          });
        }
        if (changeRange && isAdmin && el.name === item && companyId !== 'all') {
          return fetch({
            company_id: Number(companyId),
            date_from: startDate,
            date_to: endDate,
            sort_for: el.name,
            sort_type: el.type,
          });
        }

        if (el.name === item && isAdmin && companyId === 'all') {
          return fetch({ sort_for: el.name, sort_type: el.type });
        }
        if (el.name === item && isAdmin && companyId !== 'all') {
          return fetch({
            company_id: Number(companyId),
            sort_for: el.name,
            sort_type: el.type,
          });
        }
      });
      return data;
    });
  };

  useEffect(() => {
    if (!isAdmin && me?.company?.id) {
      if (changeRange) {
        fetch({ company_id: me?.company?.id, date_from: startDate, date_to: endDate });
      }
      fetch({ company_id: me?.company?.id, sort_for: 'view_from_catalog', sort_type: 'desc' });
    } else if (changeRange && isAdmin) {
      if (companyId === 'all') {
        fetch({
          date_from: startDate,
          date_to: endDate,
          sort_for: 'view_from_catalog',
          sort_type: 'desc',
        });
      } else {
        fetch({
          company_id: Number(companyId),
          date_from: startDate,
          date_to: endDate,
          sort_for: 'view_from_catalog',
          sort_type: 'desc',
        });
      }
    } else if (!changeRange && isAdmin && companyId !== 'all') {
      fetch({ company_id: Number(companyId) });
    } else {
      fetch({});
    }
  }, [fetch, changeRange, startDate, endDate, companyId]);

  useEffect(() => {
    fetchCompanies({ page: 1, perPage: 1000 });
    return () => {
      clearCompanies();
    };
  }, []);

  useEffect(() => {
    if (!me) fetchMe();
    return () => {
      clearMe();
    };
  }, []);
  if (loadingProducts || meLoading) return <Preloader />;
  return (
    <>
      <div className={classes.container}>
        <div className={classes.filterCol}>
          <Card className={classes.filter}>
            <div className={classes.filterItem}>
              <Typography className={classes.label}>
                {intl.formatMessage({
                  id: 'STATISTIC.ORDER.FILTER.DATE',
                })}
              </Typography>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={locales[intl.locale]}
              >
                <DatePicker
                  // id='received_at'
                  // variant='inline'
                  className={classes.input}
                  // margin='normal'
                  value={startDate ? dayjs(startDate) : null}
                  onChange={date => date && handleChangeRange(dayjs(date).toString(), endDate)}
                  // autoOk
                  // InputProps={{
                  //   classes: { notchedOutline: classes.input },
                  // }}
                />
              </LocalizationProvider>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={locales[intl.locale]}
              >
                <DatePicker
                  // id='received_at'
                  // variant='inline'
                  className={classes.input}
                  // margin='normal'
                  value={endDate ? dayjs(endDate) : null}
                  onChange={date =>
                    date && handleChangeRange(startDate, dayjs(date).toString())
                  }
                  // autoOk
                  // InputProps={{
                  //   classes: { notchedOutline: classes.input },
                  // }}
                />
              </LocalizationProvider>
              {isAdmin && (
                <FormControl className={classes.input} >
                  <InputLabel  id='demo-simple-select-placeholder-label-label'>
                    ВЫБРАТЬ КОМПАНИЮ
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-placeholder-label-label'
                    id='demo-simple-select-placeholder-label'
                    value={companyId}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value='all'>Все</MenuItem>
                    {companies.map(company => {
                      if (company.name) {
                        return (
                          <MenuItem key={company.id} value={company.id}>
                            {company.name}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </Select>
                  <FormHelperText>Компания</FormHelperText>
                </FormControl>
              )}
            </div>
          </Card>
        </div>

        <div className={`${classes.ordersCol}`}>
          {loadingProducts ? (
            <Preloader />
          ) : (
            <>
              {!statistics || !statistics.length ? (
                <Typography className={classes.empty} component='h5' variant='h5'>
                  {intl.formatMessage({ id: 'STATISTICS.PRODUCTS.LIST.EMPTY' })}
                </Typography>
              ) : (
                <div className={classes.card}>
                  <Card className={classes.card}>
                    <CardContent className={homeClasses.classes.tableContainer}>
                      <Table aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <SHeadTableCell>
                              {intl.formatMessage({ id: 'STATISTIC.PRODUCTS.TABLE.NAME' })}
                            </SHeadTableCell>
                            <SHeadTableCell>
                              <p style={{ width: '100%', margin: 0 }}>
                                {intl.formatMessage({ id: 'STATISTIC.PRODUCTS.VIEW' })}
                              </p>
                              <Button
                                style={{
                                  backgroundColor: 'rgba(0, 90, 90, 0.05)',
                                  height: '20px',
                                  width: '20px',
                                }}
                                onClick={() => handleChangeSort('view_from_catalog')}
                              >
                                {sortedItem.map((el: any) => {
                                  if (el.name === 'view_from_catalog' && el.status === true) {
                                    if (el.count === 0)
                                      return (
                                        <FilterListIcon color='primary' fontSize='small' />
                                      );
                                    if (el.count === 1)
                                      return (
                                        <div style={{ transform: 'rotate(180deg)' }}>
                                          <FilterListIcon color='primary' fontSize='small' />
                                        </div>
                                      );
                                    if (el.count === 2) return <></>;
                                  }
                                })}
                              </Button>
                            </SHeadTableCell>

                            <SHeadTableCell>
                              <p style={{ width: '100%', margin: 0 }}>
                                {intl.formatMessage({ id: 'STATISTIC.PRODUCTS.ADD.CART' })}
                              </p>
                              <Button
                                style={{
                                  backgroundColor: 'rgba(0, 90, 90, 0.05)',
                                  height: '20px',
                                  width: '20px',
                                }}
                                onClick={() => handleChangeSort('add_to_cart')}
                              >
                                {sortedItem.map((el: any) => {
                                  if (el.name === 'add_to_cart' && el.status === true) {
                                    if (el.count === 0)
                                      return (
                                        <FilterListIcon color='primary' fontSize='small' />
                                      );
                                    if (el.count === 1)
                                      return (
                                        <div style={{ transform: 'rotate(180deg)' }}>
                                          <FilterListIcon color='primary' fontSize='small' />
                                        </div>
                                      );
                                    if (el.count === 2) return <></>;
                                  }
                                })}
                              </Button>
                            </SHeadTableCell>

                            <SHeadTableCell>
                              <p style={{ width: '100%', margin: 0 }}>
                                {intl.formatMessage({ id: 'STATISTIC.PRODUCTS.PURCHASE' })}
                              </p>
                              <Button
                                style={{
                                  backgroundColor: 'rgba(0, 90, 90, 0.05)',
                                  height: '20px',
                                  width: '20px',
                                }}
                                onClick={() => handleChangeSort('buy_checkout')}
                              >
                                {sortedItem.map((el: any) => {
                                  if (el.name === 'buy_checkout' && el.status === true) {
                                    if (el.count === 0)
                                      return (
                                        <FilterListIcon color='primary' fontSize='small' />
                                      );
                                    if (el.count === 1)
                                      return (
                                        <div style={{ transform: 'rotate(180deg)' }}>
                                          <FilterListIcon color='primary' fontSize='small' />
                                        </div>
                                      );
                                    if (el.count === 2) return <></>;
                                  }
                                })}
                              </Button>
                            </SHeadTableCell>

                            <SHeadTableCell>
                              <p style={{ width: '100%', margin: 0 }}>
                                {intl.formatMessage({ id: 'STATISTIC.PRODUCTS.FAV.FEEDS' })}
                              </p>
                              <Button
                                style={{
                                  backgroundColor: 'rgba(0, 90, 90, 0.05)',
                                  height: '20px',
                                  width: '20px',
                                }}
                                onClick={() => handleChangeSort('view_from_favorite')}
                              >
                                {sortedItem.map((el: any) => {
                                  if (el.name === 'view_from_favorite' && el.status === true) {
                                    if (el.count === 0)
                                      return (
                                        <FilterListIcon color='primary' fontSize='small' />
                                      );
                                    if (el.count === 1)
                                      return (
                                        <div style={{ transform: 'rotate(180deg)' }}>
                                          <FilterListIcon color='primary' fontSize='small' />
                                        </div>
                                      );
                                    if (el.count === 2) return <></>;
                                  }
                                })}
                              </Button>
                            </SHeadTableCell>
                            <SHeadTableCell>
                              <p style={{ width: '100%', margin: 0 }}>
                                {intl.formatMessage({ id: 'STATISTIC.PRODUCTS.SUM' })}
                              </p>
                              <Button
                                style={{
                                  backgroundColor: 'rgba(0, 90, 90, 0.05)',
                                  height: '20px',
                                  width: '20px',
                                }}
                                onClick={() => handleChangeSort('orders_summ')}
                              >
                                {sortedItem.map((el: any) => {
                                  if (el.name === 'orders_summ' && el.status === true) {
                                    if (el.count === 0)
                                      return (
                                        <FilterListIcon color='primary' fontSize='small' />
                                      );
                                    if (el.count === 1)
                                      return (
                                        <div style={{ transform: 'rotate(180deg)' }}>
                                          <FilterListIcon color='primary' fontSize='small' />
                                        </div>
                                      );
                                    if (el.count === 2) return <></>;
                                  }
                                })}
                              </Button>
                            </SHeadTableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {statistics?.map((item, ind) => (
                            <TableRow key={ind}>
                              <TableCell width='25%'>{item.product_name || 0}</TableCell>
                              <TableCell width='15%' align='center'>
                                {item.view_from_catalog || 0}
                              </TableCell>
                              <TableCell width='15%' align='center'>
                                {item.add_to_cart || 0}
                              </TableCell>
                              <TableCell width='15%' align='center'>
                                {item.buy_checkout || 0}
                              </TableCell>
                              <TableCell width='15%' align='center'>
                                {item.view_from_favorite || 0}
                              </TableCell>
                              <TableCell width='15%' align='center'>
                                {item.orders_summ || 0}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        {/* <TablePaginator
                                page={page}
                                realPerPage={orders.length}
                                perPage={perPage}
                                total={total}
                                fetchRows={fetch}
                                label={intl.formatMessage({ id: 'ORDER.TABLE.PERPAGE' })}
                              /> */}
                      </Table>
                    </CardContent>
                  </Card>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => {
    return {
      meLoading: state.profile.loading,
      me: state.profile.me,

      statistics: state.stat.statisticProduct,

      userRole: state.auth.user?.roles,
      loadingProducts: state.stat.loadingProducts,
      companies: state.companies.companies,
    };
  },
  {
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    fetch: statisticsActions.fetchProductsRequest,
    fetchCompanies: companiesActions.fetchRequest,
    clearCompanies: companiesActions.clearCompanies,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StatisticsProductsList);
