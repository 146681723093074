import React, { useCallback } from 'react';
import { Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';

import { ITypeParameter } from '../../../../interfaces/productType';
import { IGroup } from '../../../../interfaces/groups';
import TopTableCell from '../../../../components/other/Table/TopTableCell';
import { ButtonWithLoader } from '../../../../components/other/Buttons';

interface IProps {
  paramsList: ITypeParameter[];
  paramsGroup: ITypeParameter[];
  groups: IGroup[];
  onConnectParam?: (id: number) => {} | any;
  textConnect?: string;
}

const useStyles = makeStyles()(() => {
  return {
    table: {
      marginBottom: 20,
    },
    editBtn: {
      marginRight: 5,
    },
  };
});

const ParamsTable: React.FC<IProps> = ({
  paramsList,
  onConnectParam,
  textConnect,
  paramsGroup,
  groups,
}) => {
  const classes1 = useStyles();
  const { classes } = classes1;
  const intl = useIntl();

  const paramExist = useCallback(
    (id?: number) => {
      return paramsGroup.find(item => item.id === id);
    },
    [paramsGroup]
  );

  const getGroup = useCallback(
    (id?: number) => {
      return groups.find(item => item.id === id);
    },
    [groups]
  );

  return (
    <div className={classes.table}>
      <Table aria-label='CompaniesTable'>
        <TableHead>
          <TableRow>
            <TopTableCell>{intl.formatMessage({ id: 'COMPANY.TABLE.ID' })}</TopTableCell>
            <TopTableCell>{intl.formatMessage({ id: 'COMPANY.TABLE.NAME' })}</TopTableCell>
            <TopTableCell>{intl.formatMessage({ id: 'PARAM.GROUPS' })}</TopTableCell>
            <TopTableCell> </TopTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paramsList.map(param => (
            <TableRow key={param.id} selected={!!paramExist(param.id)}>
              <TableCell>{param.id || '-'}</TableCell>
              <TableCell>{param.name || '-'}</TableCell>
              <TableCell>{getGroup(param.in_group?.id)?.name || '-'}</TableCell>
              <TableCell style={{ paddingRight: 0 }}>
                <div style={{ minWidth: 145, display: 'flex', justifyContent: 'flex-end' }}>
                  {onConnectParam && !paramExist(param.id) && (
                    <ButtonWithLoader onPress={() => onConnectParam(param.id || 0)}>
                      {textConnect || intl.formatMessage({ id: 'COMMON.BUTTON.BIND' })}
                    </ButtonWithLoader>
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ParamsTable;
