import React, { useCallback, useState } from 'react';
import {
  Card,
  Typography,
  Select as SelectMaterial,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Select from 'react-select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import { useIntl } from 'react-intl';

import { useStyles } from '../hooks/useStyles';
import { useFormatMessage } from '../../../../hooks';
import { ICompany } from '../../../../interfaces/companies';
import { useGetCompanies } from '../hooks/useGetCompanies';
import ModalCompanies from './ModalCompanies';
import { IStatistic } from '../../../../interfaces/statistic';
import { boxberry } from '../utils/companyBoxberry';

const selectStyles = {
  container: (provided: any) => ({
    ...provided,
    marginBottom: 16,
    width: '100%',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    zIndex: 999,
    display: state.selectProps.isSearchable ? provided.display : 'none',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? '#369ff7' : '',
    backgroundColor: '#f5f8fa',
    border: 'none !important',
    opacity: 1,
    boxShadow: state.isFocused ? '0 0 0 1px #369ff7 !important' : '',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#369ff7' : '#fff',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#4c4c4c',
    fontFamily: 'Open Sans',
    fontWeight: 600,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: 'Open Sans',
    fontWeight: 600,
  }),
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      display: state.selectProps.isSearchable ? provided.display : 'none',
    };
  },
};

const locales: { [key: string]: any } = {
  ru: ruLocale,
  en: enLocale,
};

type TProps = {
  selectedType: string;
  setSelectedType: (value: string) => void;
  selectedCompany: ICompany | null;
  setSelectedCompany: (value: ICompany | null) => void;
  startDate: string;
  endDate: string;
  handleChangeRange: (start: string | Date, end: string | Date) => void;
  statistics?: IStatistic;
  hideDeliveryCost: boolean;
  setHideDeliveryCost: (value: boolean) => void;
};

const FiltersStatisticsDetailed: React.FC<TProps> = ({
  selectedType,
  setSelectedType,
  selectedCompany,
  setSelectedCompany,
  startDate,
  endDate,
  handleChangeRange,
  statistics,
  hideDeliveryCost,
  setHideDeliveryCost,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const fm = useFormatMessage();
  const [open, setOpen] = useState(false);
  const [getCompanies, loading, companies, pageData] = useGetCompanies();
  const openModal = useCallback(() => {
    setOpen(true);
    getCompanies(1, 20);
  }, []);

  const AccordOrderStates = [
    { lable: fm('ACCORD.TYPE.CANCELED'), value: '-1' },
    { lable: fm('ORDER.TYPE.NEW'), value: '1' },
    { lable: fm('ACCORD.TYPE.ERROR'), value: '2' },
    { lable: fm('ACCORD.TYPE.INPROD'), value: '3' },
    { lable: fm('ACCORD.TYPE.SHIPPED'), value: '4' },
  ];

  return (
    <>
      <div className={classes.filterCol}>
        <Card className={classes.filter}>
          <div className={classes.filterItem}>
            <Typography className={classes.label}>{fm('ORDER.FILTER.TYPE')}</Typography>
            <Select
              placeholder={fm('ORDER.FILTER.PLACEHOLDER')}
              components={{ IndicatorSeparator: () => null }}
              value={{
                value: selectedType,
                label:
                  AccordOrderStates.find(item => item.value === selectedType)?.lable ||
                  fm('ORDER.FILTER.PLACEHOLDER'),
              }}
              styles={selectStyles}
              onChange={item => {
                if (item) {
                  setSelectedType(item.value);
                }
              }}
              options={[
                { value: '', label: fm('ORDER.FILTER.PLACEHOLDER') },
                ...AccordOrderStates.map(item => ({
                  label: item.lable,
                  value: item.value,
                })),
              ]}
            />

            <Typography className={classes.label}>{fm('MENU.COMPANY')}</Typography>
            <SelectMaterial
              style={{ width: '100%', marginBottom: 16 }}
              placeholder={fm('COMPANY.BUTTON.ALL')}
              value={selectedCompany?.id.toString() || '-1'}
              readOnly
              onClick={openModal}
            >
              <MenuItem value={selectedCompany ? selectedCompany.id : '-1'}>
                {selectedCompany?.name || fm('COMPANY.BUTTON.ALL')}
              </MenuItem>
            </SelectMaterial>
            <Typography className={classes.label}>
              {fm('STATISTIC.ORDER.FILTER.DATE')}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={locales[intl.locale]}
              >
                <DatePicker
                  // id='received_at'
                  // variant='inline'
                  className={classes.input}
                  // style={{ padding: 0 }}
                  disablePast
                  // margin='normal'
                  value={startDate ? dayjs(startDate) : null}
                  onChange={date => {
                    date && handleChangeRange(dayjs(date).toString(), endDate);
                  }}
                  // autoOk
                  // InputProps={{
                  //   classes: { notchedOutline: classes.input },
                  // }}
                />
              </LocalizationProvider>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={locales[intl.locale]}
              >
                <DatePicker
                  // id='received_at'
                  // variant='inline'
                  className={classes.input}
                  // style={{ padding: 0 }}
                  // margin='normal'
                  value={endDate ? dayjs(endDate) : null}
                  onChange={date =>
                    date && handleChangeRange(startDate, dayjs(date).toString())
                  }
                  // autoOk
                  // InputProps={{
                  //   classes: { notchedOutline: classes.input },
                  // }}
                />
              </LocalizationProvider>
            </div>
            <Typography className={classes.labelCost}>{fm('ORDERS.TOTAL.COST')}</Typography>
            <TextField
              type='text'
              margin='normal'
              value={statistics?.summ_stat?.delivery_cost || ''}
              variant='outlined'
              inputProps={{
                readOnly: true,
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={hideDeliveryCost}
                  onChange={e => setHideDeliveryCost(e.target.checked)}
                  color='primary'
                />
              }
              label={
                <Typography className={classes.labelCheckbox}>
                  {fm('HIDE.DELIVERY.COST')}
                </Typography>
              }
            />
          </div>
        </Card>
      </div>

      <ModalCompanies
        open={open}
        setOpen={setOpen}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
        getCompanies={getCompanies}
        loading={loading}
        companies={[boxberry, ...companies]}
        pageData={pageData}
      />
    </>
  );
};

export default React.memo(FiltersStatisticsDetailed);
