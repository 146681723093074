import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link as RouterLink, Link, useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Skeleton } from '@mui/lab';
import {
  TextField,
  Button,
  MenuItem,
  Card,
  Tabs,
  Tab,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  DialogProps,
} from '@mui/material';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import Autocomplete from 'react-google-autocomplete';
import { Modal } from '../../../components/other/Modals';
import UsersBuyerTable from '../../../components/tableComponents/Table/UsersBuyerTable';
import { IAppState } from '../../../store/rootDuck';
import { actions as orderActions } from '../../../store/ducks/orders.duck';
import { actions as usersActions } from '../../../store/ducks/users.duck';
import { actions as cartActions } from '../../../store/ducks/cart.duck';
import { IOrder } from '../../../interfaces/order';
import homeStyles from '../../../constants/homeStyles';
import ButtonWithLoader from '../../../components/other/Buttons/ButtonWithLoader';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { getOrderTypeWithTranslates } from './constatns';
import { UserListType } from '../users/constants';
import { useStyles } from './hooks/useStyles';
import AlertDialog from '../../../components/other/Dialog/AlertDialog';
import { TabPanel } from '../../../components/other/Tab/TabPanel';
import { SHeadTableCell } from '../../../components/styledComponents/Tables';
import { API_DOMAIN } from '../../../constants';
import { daysShipping, thousands } from '../../../utils/utils';
import AutocompleteCopy from '../../../components/formComponents/AutocompleteLocationsCopy';
import OrderTable from './components/OrderTalbe';
import { useDefineUserRole } from '../../../hooks';
import { useGetProducts } from './hooks/useGetProducts';

const getInitialValues = (order: IOrder) => {
  const values: any = {
    fio: order.fio,
    email: order.email,
    address: order.address,
    location: order.location,
    delivery_date: order.delivery_date,
    delivery_date_string: order.delivery_date,
    city: order.city || '',
    region: order.region || '',
    street: order.street || '',
    home: order.home || '',
    flat: order.flat || '',
    entrance: order.entrance,
    building: order.building,
    phone: order.phone,
    comment: order.comment,
    status: order.status || 'payed',
    payment_status: order.payment_status || 'not_paid',
    items: [],
    timetable: order.delivery_point?.timetable || '',
    description: order.delivery_point?.description || '',
    code: order.delivery_point?.code || '',
    addressSelf: order.delivery_point?.address || '',
  };
  if (order.items) {
    order.items.forEach(item => {
      values.items.push({ ...item, product_id: item?.product.id! });
      // values[`item${item.id}`] = item;
    });
  }
  return values;
};

export enum ViewMode {
  VIEW = 'view',
  EDIT = 'edit',
}

const OrderPage: React.FC<TPropsFromRedux> = ({
  order,
  loading,
  editLoading,
  fetchById,
  edit,
  editSuccess,
  clear,
  // user,
  // me,
  error,
  editError,
  loadingUsers,
  perPageUsers,
  pageUsers,
  totalUsers,
  users,
  fetchUsers,
  filter,
  addProductToCart,
  // userRole
}) => {
  const { me } = useSelector(({ profile }: any) => profile);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [openUsersDialog, setOpenUsersDialog] = useState(false);
  // const [isOpenUnconnectUserAlert, setIsOpenUnconnectUserAlert] = useState(false);
  const [changeOrderItem, setChangeOrderItem] = useState(false);
  // const [unconnectUserId, setUnconnectUserId] = useState<number | undefined>(-1);
  const [buyerID, setBuyerId] = useState<number | undefined>(order?.user.id);
  const [borderWidth, setBorderWidth] = useState(1);
  const [type, setType] = useState<string>('default');
  const isSelf = useMemo(() => order?.delivery_point, [order]);
  const isVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const [selectedId, setSelectedId] = useState(-1);
  const [fetchProducts, products] = useGetProducts();

  // const isBuyer = useMemo(() => userRole?.[0] === UserRoles.ROLE_BUYER, [userRole]);
  const daysShippingValue = useMemo(() => order && daysShipping(order), [order]);
  const [valueTabs, setValueTabs] = useState(0);
  const outlineBorder: { [key: string]: string } = {
    default: '#c4c4c4',
    error: '#fd397a',
    focus: '#007bff',
  };
  const intl = useIntl();
  const navigate = useNavigate();
  const { id, mode } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const homeClasses = homeStyles();
  const classes1 = useStyles();
  const { classes } = classes1;

  useEffect(() => {
    if (id) {
      fetchById(+id);
    }
  }, [id, fetchById]);

  useEffect(() => {
    if (editSuccess || editError) {
      enqueueSnackbar(
        editSuccess
          ? ` ${intl.formatMessage({
              id: 'ORDER.SNACKBAR.EDIT',
            })}`
          : `${intl.formatMessage({ id: 'PRODUCT.SNACKBAR.ERROR' })} ${editError}`,
        { variant: editSuccess ? 'success' : 'error' }
      );
      if (editSuccess) {
        // history.goBack();
        id && fetchById(+id);
      }
    }
  }, [editSuccess, editError, enqueueSnackbar, id, intl]);

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    isValid,
    setValues,
  } = useFormik({
    initialValues: order ? getInitialValues(order) : {},
    validationSchema: Yup.object().shape({
      phone: Yup.string()
        .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }))
        .nullable(),
      fio: Yup.string()
        .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }))
        .nullable(),
    }),
    onSubmit: submitValues => {
      id && edit({ id: +id, data: { ...submitValues, user_id: buyerID } });
    },
  });

  useEffect(() => {
    if (order) {
      setValues(getInitialValues(order));
    }
  }, [order, setValues]);

  const translates: { [type: string]: string } = {
    cart: intl.formatMessage({ id: 'ORDER.TYPE.CART' }),
    new: intl.formatMessage({ id: 'ORDER.TYPE.NEW' }),
    formation: intl.formatMessage({ id: 'ORDER.TYPE.FORMATION' }),
    ready_to_delivery: intl.formatMessage({ id: 'ORDER.TYPE.DELIVERY' }),
    payed: intl.formatMessage({ id: 'ORDER.TYPE.PAYED' }),
    canceled: intl.formatMessage({ id: 'ORDER.TYPE.CANCELED' }),
    completed: intl.formatMessage({ id: 'ORDER.TYPE.COMPLETED' }),
    in_processing: intl.formatMessage({ id: 'ORDER.TYPE.PROCCESS' }),
  };
  const orderTypes = getOrderTypeWithTranslates(translates, true);
  const disabled = mode === ViewMode.VIEW;

  setLayoutSubheader({
    title:
      mode === ViewMode.VIEW
        ? `${intl.formatMessage({ id: 'ORDER.HEADER.VIEW' })}`
        : `${intl.formatMessage({ id: 'ORDER.HEADER.EDIT' })}`,
    onPress() {
      setChangeOrderItem(!changeOrderItem);
    },
    btnTitle: changeOrderItem
      ? intl.formatMessage({ id: 'CART.SHORT.VIEW' })
      : intl.formatMessage({ id: 'CART.DETAILED.VIEW' }),
    btnAddCart() {
      handleRepeatOrder();
    },
    textBtnAddCart: intl.formatMessage({ id: 'ORDERS.REPEAT' }),
  });
  setLayoutFooter({ show: true });

  const submitCallback = useCallback(() => {
    if (
      order &&
      (order.status === 'payed' ||
        order.status === 'completed' ||
        order.status === 'ready_to_delivery')
    ) {
      setAlertOpen(true);
    } else {
      handleSubmit();
    }
  }, [order, handleSubmit]);

  const handleRepeatOrder = async () => {
    if (order && order.items && order.items.length > 0) {
      try {
        const productsToAdd = [];
        for (const item of order.items) {
          const product = products.find((prod: any) => prod.id === item.product.id);

          if (product) {
            if (typeof item.product.id === 'number') {
              productsToAdd.push({
                product_id: item.product.id,
                count: item.count,
              });
            }
          } else {
            throw new Error(`Продукт с ID ${item.product.id} не найден`);
          }
        }

        await addProductToCart({
          products: productsToAdd,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(
    () => () => {
      clear();
    },
    [clear]
  );

  const handleConnectUser = useCallback(
    (Id: number) => {
      setBuyerId(Id);
      handleSubmit();
      setOpenUsersDialog(false);
    },
    [buyerID]
  );

  const handleTabsChange = (event: any, newValue: number) => {
    setValueTabs(newValue);
  };

  // const handleUnConnectUserDialog = useCallback((id: number) => {
  //   // setIsOpenUnconnectUserAlert(true);
  //   // setUnconnectUserId(id);
  // }, []);

  const selectAction = useCallback(
    (id: number) => {
      setSelectedId(id);
    },
    [setSelectedId]
  );

  // const handleOpenUsersDialog = useCallback(() => {
  //   fetchUsers({ perPage: perPageUsers, page: pageUsers, role: 'BUYER', filter });
  //   setOpenUsersDialog(true);
  // }, [perPageUsers, pageUsers, filter]);

  const setSelectedAddress = useCallback(
    (data: { region: string; city: string; street: string; home: string; flat: string }) => {
      const { region, city, street, home, flat } = data;
      setFieldValue('region', region);
      setFieldValue('city', city);
      setFieldValue('street', street);
      setFieldValue('home', home);
      setFieldValue('flat', flat);
    },
    []
  );

  const alertText = `${intl.formatMessage({ id: 'ORDER.ALERT.EDIT.TEXT1' })} "${
    translates[order?.status || '']
  }". ${intl.formatMessage({ id: 'ORDER.ALERT.EDIT.TEXT2' })}`;

  if (loading) {
    return <></>;
  }

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={alertText}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setAlertOpen(false);
        }}
        handleAgree={() => handleSubmit()}
      />
      <Card className={homeClasses.classes.container}>
        <Tabs
          value={valueTabs}
          onChange={handleTabsChange}
          variant='scrollable'
          indicatorColor='primary'
          textColor='primary'
          aria-label='tabs'
        >
          <Tab label={intl.formatMessage({ id: 'ORDER.VIEW.TABS.FIELDS' })} />
          <Tab label={intl.formatMessage({ id: 'ORDER.VIEW.TABS.ITEMS' })} />
        </Tabs>
        <div className={homeClasses.classes.table}>
          <TabPanel value={valueTabs} index={0}>
            {loading ? (
              <>
                <Skeleton width='100%' height={70} animation='wave' />
                <Skeleton width='100%' height={70} animation='wave' />
                <Skeleton width='100%' height={70} animation='wave' />
                <Skeleton width='100%' height={70} animation='wave' />
                <Skeleton width='100%' height={70} animation='wave' />
                <Skeleton width='100%' height={70} animation='wave' />
                <Skeleton width='100%' height={70} animation='wave' />
              </>
            ) : (
              <>
                <TextField
                  select
                  margin='normal'
                  label={`${intl.formatMessage({ id: 'ORDER.INPUT.STATUS' })}`}
                  value={values.status || ''}
                  onChange={handleChange}
                  name='status'
                  variant='outlined'
                  helperText={touched.status && errors.status?.toString()}
                  error={Boolean(touched.status && errors.status)}
                  disabled={disabled}
                >
                  {orderTypes.map(item => (
                    <MenuItem value={item.type}>{item.translate}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  margin='normal'
                  label={intl.formatMessage({ id: 'ORDER.PAYMENT.STATUS' })}
                  value={order?.payment_status || ''}
                  name='payment_status'
                  variant='outlined'
                  inputProps={{ readOnly: true }}
                >
                  <MenuItem value='paid'>
                    {intl.formatMessage({ id: 'ORDER.PAYMENT.STATUS.PAID' })}
                  </MenuItem>
                  <MenuItem value='not_paid'>
                    {intl.formatMessage({ id: 'ORDER.PAYMENT.STATUS.NOT_PAID' })}
                  </MenuItem>
                </TextField>
                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'ORDER.INPUT.NAME' })}
                  margin='normal'
                  name='fio'
                  value={values.fio}
                  variant='outlined'
                  helperText={touched.fio && errors.fio?.toString()}
                  error={Boolean(touched.fio && errors.fio)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                />
                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'ORDER.PARCEL.ID' })}
                  margin='normal'
                  value={order?.accord_data?.parcel_id || ''}
                  variant='outlined'
                  inputProps={{ readOnly: true }}
                />
                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'CART.DELIVERY_TYPE.SHIPPING' })}
                  margin='normal'
                  value={daysShippingValue || ''}
                  variant='outlined'
                  inputProps={{ readOnly: true }}
                />
                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'ORDER.BARCODE' })}
                  margin='normal'
                  value={order?.accord_data?.barcode || ''}
                  variant='outlined'
                  inputProps={{ readOnly: true }}
                />
                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'ORDER.INPUT.PHONE' })}
                  margin='normal'
                  name='phone'
                  value={values.phone}
                  variant='outlined'
                  helperText={touched.phone && errors.phone?.toString()}
                  error={Boolean(touched.phone && errors.phone)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                />
                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'ORDER.INPUT.EMAIL' })}
                  margin='normal'
                  name='email'
                  value={values.email}
                  variant='outlined'
                  helperText={touched.email && errors.email?.toString()}
                  error={Boolean(touched.email && errors.email)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                />
                {isSelf && (
                  <>
                    <TextField
                      type='text'
                      label={intl.formatMessage({ id: 'ORDER.ADDRESS.SELF' })}
                      margin='normal'
                      name='addressSelf'
                      value={values.addressSelf}
                      variant='outlined'
                      helperText={touched.addressSelf && errors.addressSelf?.toString()}
                      error={Boolean(touched.addressSelf && errors.addressSelf)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      multiline
                      inputProps={{ readOnly: true }}
                    />
                    <TextField
                      type='text'
                      label={intl.formatMessage({ id: 'ORDER.PICK.POINT' })}
                      margin='normal'
                      name='code'
                      value={values.code}
                      variant='outlined'
                      helperText={touched.code && errors.code?.toString()}
                      error={Boolean(touched.code && errors.code)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{ readOnly: true }}
                    />
                    <TextField
                      type='text'
                      label={intl.formatMessage({ id: 'ORDER.TIMETABLE' })}
                      margin='normal'
                      name='timetable'
                      value={values.timetable}
                      variant='outlined'
                      helperText={touched.timetable && errors.timetable?.toString()}
                      error={Boolean(touched.timetable && errors.timetable)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      multiline
                      inputProps={{ readOnly: true }}
                    />
                    <TextField
                      type='text'
                      label={intl.formatMessage({ id: 'ORDER.DESCRIPTION' })}
                      margin='normal'
                      name='description'
                      value={values.description?.replace(/<\/?[^>]+(>|$)/g, '')}
                      variant='outlined'
                      helperText={touched.description && errors.description?.toString()}
                      error={Boolean(touched.description && errors.description)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      multiline
                      inputProps={{ readOnly: true }}
                    />
                  </>
                )}
                {!isSelf && (
                  <>
                    {disabled ? (
                      <AutocompleteCopy
                        error={Boolean(touched.address && errors.address)}
                        value={{ address: values.address }}
                        setSelectedLocation={location => {
                          setFieldValue('address', { address: location.address });
                        }}
                        disabled={disabled}
                      />
                    ) : (
                      <Autocomplete
                        className={classes.input}
                        style={{
                          boxShadow: `inset 0 0 0 ${borderWidth}px ${outlineBorder[type]}`,
                        }}
                        // @ts-ignore
                        placeholder={intl.formatMessage({
                          id: 'STORE.INPUT.LOCATION',
                        })}
                        onFocus={() => {
                          setBorderWidth(2);
                          setType(error ? 'error' : 'focus');
                        }}
                        onBlur={() => {
                          setBorderWidth(1);
                          setType(error ? 'error' : 'default');
                        }}
                        options={{
                          types: ['geocode', 'establishment'],
                          language: intl.locale === 'ru' ? 'ru' : 'en-AU',
                          componentRestrictions: { country: 'ru' },
                        }}
                        language={intl.locale === 'ru' ? 'ru' : 'en-AU'}
                        apiKey='AIzaSyB_rjiR94heML-tlGsexMmxwtpVQ7WlcxI'
                        onPlaceSelected={place => {
                          setFieldValue('address', place.formatted_address);
                          const home = place.address_components?.find((item: any) =>
                            item.types.includes('street_number')
                          )?.long_name;
                          const flat = place.address_components?.find((item: any) =>
                            item.types.includes('subpremise')
                          )?.long_name;
                          const street = place.address_components?.find((item: any) =>
                            item.types.includes('route')
                          )?.long_name;
                          const city = place.address_components?.find((item: any) =>
                            item.types.includes('locality')
                          )?.long_name;
                          const region = place.address_components?.find((item: any) =>
                            item.types.includes('administrative_area_level_1')
                          )?.long_name;
                          setSelectedAddress({ region, city, street, flat, home });
                        }}
                        defaultValue={values.address || ''}
                        onChange={e => {
                          // @ts-ignore
                          if (!e.target.value) {
                            setFieldValue('address', '');
                            setSelectedAddress({
                              region: '',
                              city: '',
                              street: '',
                              flat: '',
                              home: '',
                            });
                          }
                        }}
                      />
                    )}
                  </>
                )}

                {!disabled && (
                  <div className={classes.bottomActions}>
                    <Button
                      onClick={() => navigate(-1)}
                      className={classes.buttons}
                      variant='outlined'
                      color='primary'
                    >
                      {intl.formatMessage({ id: 'CATEGORY.BUTTON.CANCEL' })}
                    </Button>
                    <ButtonWithLoader
                      marginLeft={10}
                      disabled={editLoading || !isValid}
                      loading={editLoading}
                      onPress={submitCallback}
                    >
                      {intl.formatMessage({ id: 'ORDER.BUTTON.EDIT' })}
                    </ButtonWithLoader>
                  </div>
                )}
              </>
              // <>
              //   {id && (
              //     <div className={classes.companyField}>
              //       <TextField
              //         label={intl.formatMessage({ id: 'USER.ROLES.BUYER' })}
              //         margin='normal'
              //         placeholder={intl.formatMessage({ id: 'USER.ROLES.SEARCH.BY_INN' })}
              //         value={order?.user.login}
              //         onChange={handleChange}
              //         variant='outlined'
              //         InputProps={{
              //           endAdornment: (
              //             <IconButton onClick={handleOpenUsersDialog}>
              //               <SearchIcon color='primary' fontSize='small' />
              //             </IconButton>
              //           )
              //         }}
              //       />

              //       <Button
              //         className={classes.allCompaniesBtn}
              //         variant='outlined'
              //         color='primary'
              //         onClick={handleOpenUsersDialog}
              //       >
              //         {intl.formatMessage({ id: 'USER.ROLES.BUTTON.ALL' })}
              //       </Button>
              //     </div>
              //   )}
              //   <TextField
              //     type='text'
              //     label={intl.formatMessage({ id: 'ORDER.INPUT.NAME' })}
              //     margin='normal'
              //     name='fio'
              //     value={values.fio}
              //     variant='outlined'
              //     helperText={touched.fio && errors.fio}
              //     error={Boolean(touched.fio && errors.fio)}
              //     onBlur={handleBlur}
              //     onChange={handleChange}
              //     disabled={disabled}
              //   />
              //   <TextField
              //     type='text'
              //     label={intl.formatMessage({ id: 'ORDER.INPUT.PHONE' })}
              //     margin='normal'
              //     name='phone'
              //     value={values.phone}
              //     variant='outlined'
              //     helperText={touched.phone && errors.phone}
              //     error={Boolean(touched.phone && errors.phone)}
              //     onBlur={handleBlur}
              //     onChange={handleChange}
              //     disabled={disabled}
              //   />
              //   <TextField
              //     type='text'
              //     label={intl.formatMessage({ id: 'ORDER.INPUT.CITY' })}
              //     margin='normal'
              //     name='city'
              //     value={values.city}
              //     variant='outlined'
              //     onBlur={handleBlur}
              //     onChange={handleChange}
              //     disabled={disabled}
              //   />
              //   <TextField
              //     type='text'
              //     label={intl.formatMessage({ id: 'ORDER.INPUT.REGION' })}
              //     margin='normal'
              //     name='region'
              //     value={values.region}
              //     variant='outlined'
              //     onBlur={handleBlur}
              //     onChange={handleChange}
              //     disabled={disabled}
              //   />
              //   <TextField
              //     type='text'
              //     label={intl.formatMessage({ id: 'ORDER.INPUT.STREET' })}
              //     margin='normal'
              //     name='street'
              //     value={values.street}
              //     variant='outlined'
              //     onBlur={handleBlur}
              //     onChange={handleChange}
              //     disabled={disabled}
              //   />

              //   <TextField
              //     type='text'
              //     label={intl.formatMessage({ id: 'ORDER.INPUT.HOME' })}
              //     margin='normal'
              //     name='home'
              //     value={values.home}
              //     variant='outlined'
              //     onBlur={handleBlur}
              //     onChange={handleChange}
              //     disabled={disabled}
              //   />
              //   <TextField
              //     type='text'
              //     label={intl.formatMessage({ id: 'ORDER.INPUT.FLAT' })}
              //     margin='normal'
              //     name='flat'
              //     value={values.flat}
              //     variant='outlined'
              //     onBlur={handleBlur}
              //     onChange={handleChange}
              //     disabled={disabled}
              //   />
              //   <TextField
              //     type='text'
              //     label={intl.formatMessage({ id: 'ORDER.INPUT.BUILDING' })}
              //     margin='normal'
              //     name='building'
              //     value={values.building}
              //     variant='outlined'
              //     onBlur={handleBlur}
              //     onChange={handleChange}
              //     disabled={disabled}
              //   />
              //   <TextField
              //     type='text'
              //     label={intl.formatMessage({ id: 'ORDER.INPUT.ENTRANCE' })}
              //     margin='normal'
              //     name='entrance'
              //     value={values.entrance}
              //     variant='outlined'
              //     onBlur={handleBlur}
              //     onChange={handleChange}
              //     disabled={disabled}
              //   />
              //   <TextField
              //     type='text'
              //     label={intl.formatMessage({ id: 'ORDER.INPUT.COMMENT' })}
              //     margin='normal'
              //     name='comment'
              //     value={values.comment}
              //     variant='outlined'
              //     onBlur={handleBlur}
              //     onChange={handleChange}
              //     disabled={disabled}
              //   />

              //   <TextField
              //     select
              //     margin='normal'
              //     label={`${intl.formatMessage({ id: 'ORDER.INPUT.STATUS' })}`}
              //     value={values.status || ''}
              //     onChange={handleChange}
              //     name='status'
              //     variant='outlined'
              //     helperText={touched.status && errors.status}
              //     error={Boolean(touched.status && errors.status)}
              //     disabled={disabled}
              //   >
              //     {orderTypes.map(item => (
              //       <MenuItem value={item.type}>{item.translate}</MenuItem>
              //     ))}
              //   </TextField>

              //   {!disabled && (
              //     <div className={classes.bottomActions}>
              //       <Button
              //         onClick={() => history.goBack()}
              //         className={classes.buttons}
              //         variant='outlined'
              //         color='primary'
              //       >
              //         {intl.formatMessage({ id: 'CATEGORY.BUTTON.CANCEL' })}
              //       </Button>
              //       <ButtonWithLoader
              //         disabled={editLoading || !isValid}
              //         loading={editLoading}
              //         onPress={submitCallback}
              //       >
              //         {intl.formatMessage({ id: 'ORDER.BUTTON.EDIT' })}
              //       </ButtonWithLoader>
              //     </div>
              //   )}
              // </>
            )}
          </TabPanel>
          <TabPanel value={valueTabs} index={1}>
            {changeOrderItem ? (
              <CardContent className={homeClasses.classes.tableContainer}>
                {values.items ? (
                  <>
                    <Table aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <SHeadTableCell>
                            {intl.formatMessage({ id: 'PRODUCT.TABLE.PREVIEW' })}
                          </SHeadTableCell>
                          <SHeadTableCell>
                            {intl.formatMessage({ id: 'PRODUCT.TABLE.NAME' })}
                          </SHeadTableCell>
                          <SHeadTableCell>
                            {intl.formatMessage({ id: 'PRODUCT.TABLE.PRICE' })}
                          </SHeadTableCell>
                          <SHeadTableCell>
                            {intl.formatMessage({ id: 'PRODUCT.TABLE.COUNT' })}
                          </SHeadTableCell>
                          <SHeadTableCell>
                            {intl.formatMessage({ id: 'MENU.COMPANY' })}
                          </SHeadTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.items.map((item: any) => (
                          <TableRow key={item.product.id}>
                            <TableCell>
                              {item.product?.photos && item.product.photos.length > 0 && (
                                <RouterLink to={`/viewproduct/${item.product.id}`}>
                                  <img
                                    src={`${API_DOMAIN}/${
                                      item.product?.photos?.find((el: any) => el.main)?.path ||
                                      item.product?.photos[0].path
                                    }`}
                                    className={classes.img}
                                    alt={item.product.name}
                                  />
                                </RouterLink>
                              )}
                            </TableCell>
                            <TableCell>
                              <RouterLink to={`/viewproduct/${item.product.id}`}>
                                {item.product.name}
                              </RouterLink>
                            </TableCell>
                            <TableCell>
                              {item.product.price
                                ? thousands(String(item.product.price)) + '₽'
                                : '-'}
                            </TableCell>
                            <TableCell>
                              {item.product.price
                                ? thousands(String(item?.count)) + ' шт'
                                : '-'}
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`/viewcompany/${item.product.company?.id}`}
                                className={classes.buttons}
                              >
                                {item.product.company?.name}
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Typography className={classes.empty} component='h5' variant='h5'>
                      {intl.formatMessage({ id: 'ORDER.TABLE.SUM_ORDER' }) +
                        ': ' +
                        thousands(String(order?.summ)) +
                        '₽'}
                    </Typography>
                  </>
                ) : (
                  ''
                )}
              </CardContent>
            ) : (
              <OrderTable
                orders={order || values}
                isVendor={isVendor}
                selectAction={selectAction}
                selectedId={selectedId}
                // editStatus={editStatus}
                me={me}
                isBuyer={isBuyer}
              />
            )}
          </TabPanel>
        </div>
      </Card>
      <Modal
        DialogProps={{ maxWidth: 'md' } as DialogProps}
        open={openUsersDialog}
        onClose={() => setOpenUsersDialog(false)}
        title={intl.formatMessage({ id: 'COMPANY.EDIT.BUYER_USER_TITLE' })}
        loading={loadingUsers}
        content={
          <UsersBuyerTable
            loading={loadingUsers}
            paginationData={{ page: pageUsers, perPage: perPageUsers, total: totalUsers }}
            onEdit={userId => navigate(`/users/edit/${UserListType.MANAGER}/${userId}`)}
            buyerID={buyerID}
            onConnect={handleConnectUser}
            // onConnectOff={userId => handleUnConnectUserDialog(userId)}
            users={users}
            fetch={({ page, perPage }) =>
              fetchUsers({ per_page: perPage, page, roles: 'ROLE_BUYER', filter })
            }
          />
        }
        actions={[
          {
            title: intl.formatMessage({ id: 'COMMON.BUTTON.CLOSE' }),
            onClick: () => setOpenUsersDialog(false),
          },
        ]}
      />
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    user: state.users.user,
    users: state.users.users,
    loadingUsers: state.users.loading,
    perPageUsers: state.users.per_page,
    pageUsers: state.users.page,
    totalUsers: state.users.total,
    order: state.orders.order,
    loading: state.orders.byIdLoading,
    editError: state.orders.editError,
    editSuccess: state.orders.editSuccess,
    editLoading: state.orders.editLoading,
    filter: state.users.filter,
    error: state.products.error,
    userRole: state.auth.user?.roles,
  }),
  {
    fetchById: orderActions.fetchByIdRequest,
    edit: orderActions.editRequest,
    clear: orderActions.clearEdit,
    fetchUsers: usersActions.fetchRequest,
    addProductToCart: cartActions.multiAddProductRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OrderPage);

// function* addProductSaga({
//   payload,
// }: {
//   payload: { product_id: number; count: number; newCart?: boolean };
// }) {
//   try {
//     // const hash = localStorage.getItem('hash');
//     const hash: string | null = yield call(() => checkHash());
//     if (hash) {
//       if (payload.newCart) {
//         yield call(() => clearCart(hash));
//       }

//       const dataProducts = []

//       const newProduts = []

//       yield call(() => dataProducts.forEach(async (item) => {
//         const { data }: { data: IServerResponse<ICart> } = await addProduct({ product_id: payload.product_id, count: payload.count, hash })

//         newProduts.push(data)
//       }))

//       yield put(actions.addProductSuccess(newProduts));
//       yield put(actions.clearAddProduct());
//     }
//   } catch (e) {
//     yield put(actions.addProductFail(getResponseMessage(e)));
//   }
// }
