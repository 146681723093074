'use strict';

import KTUtil from './util';

const KTOffcanvas = (elementId, options) => {
  const defaultOptions = {
    baseClass: '',
    toggleBy: null,
    closeBy: null,
    overlay: true,
  };

  let element = KTUtil.get(elementId);
  const body = KTUtil.get('body');

  if (!element) return;

  let the = {
    element: element,
    options: KTUtil.deepExtend({}, defaultOptions, options),
    events: [],
    state: KTUtil.hasClass(element, `${options.baseClass}--on`) ? 'shown' : 'hidden',
    overlay: null,
  };

  const eventTrigger = (name, args) => {
    for (const event of the.events) {
      if (event.name === name) {
        if (event.one && !event.fired) {
          event.fired = true;
          event.handler.call(the, the, args);
        } else if (!event.one) {
          event.handler.call(the, the, args);
        }
      }
    }
  };

  const addEvent = (name, handler, one = false) => {
    the.events.push({
      name,
      handler,
      one,
      fired: false,
    });
  };

  const handleToggle = e => {
    e.preventDefault();
    toggle();
  };

  const handleClose = e => {
    e.preventDefault();
    hide();
  };

  const toggle = () => {
    eventTrigger('toggle');
    the.state === 'shown' ? hide() : show();
  };

  const show = () => {
    if (the.state === 'shown') return;

    eventTrigger('beforeShow');
    togglerClass('show');

    KTUtil.addClass(body, `${the.options.baseClass}--on`);
    KTUtil.addClass(the.element, `${the.options.baseClass}--on`);

    the.state = 'shown';

    if (the.options.overlay) {
      the.overlay = document.createElement('DIV');
      the.element.parentNode.insertBefore(the.overlay, the.element.nextSibling);
      KTUtil.addClass(the.overlay, `${the.options.baseClass}-overlay`);
      KTUtil.addEvent(the.overlay, 'click', handleClose);
    }

    eventTrigger('afterShow');
  };

  const hide = () => {
    if (the.state === 'hidden') return;

    eventTrigger('beforeHide');
    togglerClass('hide');

    KTUtil.removeClass(body, `${the.options.baseClass}--on`);
    KTUtil.removeClass(the.element, `${the.options.baseClass}--on`);

    the.state = 'hidden';

    if (the.options.overlay && the.overlay) {
      KTUtil.remove(the.overlay);
    }

    eventTrigger('afterHide');
  };

  const togglerClass = mode => {
    const toggleBy = the.options.toggleBy;
    if (!toggleBy) return;

    if (Array.isArray(toggleBy)) {
      toggleBy.forEach(item => {
        const target = KTUtil.get(item.target || item);
        if (mode === 'show') {
          KTUtil.addClass(target, item.state);
        } else {
          KTUtil.removeClass(target, item.state);
        }
      });
    } else {
      const target = KTUtil.get(toggleBy.target || toggleBy);
      if (mode === 'show') {
        KTUtil.addClass(target, toggleBy.state);
      } else {
        KTUtil.removeClass(target, toggleBy.state);
      }
    }
  };

  const build = () => {
    const { toggleBy, closeBy } = the.options;

    if (toggleBy) {
      if (typeof toggleBy === 'string') {
        KTUtil.addEvent(toggleBy, 'click', handleToggle);
      } else if (Array.isArray(toggleBy)) {
        toggleBy.forEach(item => {
          KTUtil.addEvent(item.target || item, 'click', handleToggle);
        });
      } else {
        KTUtil.addEvent(toggleBy.target, 'click', handleToggle);
      }
    }

    if (closeBy) {
      KTUtil.addEvent(closeBy, 'click', handleClose);
    }
  };

  const construct = options => {
    if (KTUtil.data(the.element).has('offcanvas')) {
      the = KTUtil.data(the.element).get('offcanvas');
    } else {
      build();
      KTUtil.data(the.element).set('offcanvas', the);
    }
  };

  construct(options);

  return {
    setDefaults: options => {
      Object.assign(defaultOptions, options);
    },
    isShown: () => {
      return the.state === 'shown';
    },
    hide: () => {
      hide();
    },
    show: () => {
      show();
    },
    on: (name, handler) => {
      addEvent(name, handler);
    },
    one: (name, handler) => {
      addEvent(name, handler, true);
    },
  };
};

// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = KTOffcanvas;
}

export default KTOffcanvas;
