/* eslint-disable react/react-in-jsx-scope */
import { Route, Navigate, Routes } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_base';
import '../../../../_base/_assets/sass/pages/error/error-1.scss';

function ErrorPage() {
  return (
    <>
      <div className='kt-grid kt-grid--ver kt-grid--root'>
        <div
          className='kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1'
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/error/bg1.jpg')})`,
          }}
        >
          <div className='kt-error-v1__container'>
            <h1 className='kt-error-v1__number'>404</h1>
            <p className='kt-error-v1__desc'>The page you are looking for does not exist.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default function ErrorsPage() {
  return (
    <Routes>
      <Route path='error-v1' element={<ErrorPage />} />
      <Route path='*' element={<Navigate to='/error/error-v1' replace />} />
    </Routes>
  );
}
