import React from 'react';
import { useIntl } from 'react-intl';
import { TextField, Box, IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/Delete';

import { ITypeParameter } from '../../../../interfaces/productType';
import homeStyles from '../../../../constants/homeStyles';
import { useStylesTypeForm } from '../../products/hooks/useStyles';

interface IProps {
  param: ITypeParameter;
  deleteField: (id?: number) => void;
}

const GroupParamForm: React.FC<IProps> = ({ param, deleteField }) => {
  const intl = useIntl();
  const homeClasses = homeStyles();
  const classes1 = useStylesTypeForm();
  const { classes } = classes1;

  return (
    <Box
      className={homeClasses.classes.paramContainer}
      border={1}
      borderColor='#eeeeee'
      borderRadius={5}
    >
      <div>
        <div className={homeClasses.classes.textFieldContainer}>
          <TextField
            color='secondary'
            type='text'
            label={intl.formatMessage({
              id: 'PRODUCT.TYPES.PARAMETER.INPUT.NAME',
            })}
            margin='normal'
            name='name'
            value={param.name}
            variant='outlined'
            inputProps={{ readOnly: true }}
            InputLabelProps={{
              classes: {
                shrink: classes.labelTextFieldShrink,
              },
            }}
          />
          {param.id && (
            <IconButton
              size='small'
              onClick={() => deleteField(param.id)}
              className={homeClasses.classes.leftIcon}
            >
              <DeleteOutlineOutlinedIcon className={classes.icon} color='error' />
            </IconButton>
          )}
        </div>
      </div>
    </Box>
  );
};

export default React.memo(GroupParamForm);
