import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import homeStyles from '../../../constants/homeStyles';
import { useDefineUserRole } from '../../../hooks';
import ProductsEditPricingHeader from './components/ProductsEditPricingHeader';
import { useFormikEditPricing } from './hooks/useFormikEditPricing';
import { IAppState } from '../../../store/rootDuck';
import {
  actions,
  actions as productCatalogActions,
} from '../../../store/ducks/product-catalog.duck';
import { ProductsEditPricingTable } from './components/ProductsEditPricingTable';
import FilterCategoriesTree from './components/FilterCategoriesTree';
import FilterActivity from './components/FilterActivity';
import { SortType } from '../../../interfaces/productType';
import { useStylesEditPricing } from './hooks/useStyles';

const ProductsEditPricing: React.FC = () => {
  const homeClasses = homeStyles();
  const dispatch = useDispatch();
  const classes1 = useStylesEditPricing();
  const { classes } = classes1;
  const formik = useFormikEditPricing();
  const [checkedId, setCheckedId] = useState<any>([]);
  const [checkedIdActive, setCheckedIdActive] = useState<any>([]);
  const filterData: any = {};
  // const [filterData, setFilterData] = useState<any>({});
  const [activity, setActivity] = useState('all');
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const { me } = useSelector(({ profile }: IAppState) => profile);

  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);

  const { catalogCategories } = useSelector(({ categories }: IAppState) => categories);
  const isAuthorized = useSelector(({ auth }: IAppState) => auth.user != null);

  const { page, perPage, products, loading, categoryIdActive } = useSelector(
    ({
      productsCatalog: { products, loading, page, per_page, categoryIdActive },
    }: IAppState) => ({
      products,
      loading,
      page,
      perPage: per_page,
      categoryIdActive,
    }),
    shallowEqual
  );

  const filter = useMemo(() => {
    const data = [];
    // eslint-disable-next-line guard-for-in
    for (const key in filterData) {
      data.push({ parameter: Number(key), ...filterData[key] });
    }
    if (data.length) {
      return { parameters: data, price_from: '', price_to: '' };
    }
    return { parameters: [], price_from: '', price_to: '' };
  }, [filterData]);

  const getProducts = () => {
    dispatch(
      actions.fetchRequest({
        page,
        perPage,
        filter,
        categoryId: selectedCategoryId || categoryIdActive || 0,
        activity: 'all',
      })
    );
  };

  const resetProducts = useCallback(() => {
    dispatch(
      actions.fetchRequest({
        page: 1,
        perPage: 12,
        filter,
        categoryId: selectedCategoryId || categoryIdActive || 0,
        activity,
      })
    );
  }, [catalogCategories, filter, activity]);

  useEffect(() => {
    // fetchProductsForDays({ page, perPage });
    getProducts();
  }, []);

  const handleActivity = useCallback(
    (value: string) => {
      setActivity(value);
      dispatch(
        actions.fetchRequest({
          page,
          perPage,
          filter,
          categoryId: selectedCategoryId || categoryIdActive || 0,
          activity: value,
          sortType: SortType.RATING,
        })
      );
    },
    [page, perPage, filter, selectedCategoryId, categoryIdActive]
  );

  return (
    <>
      <Paper className={homeClasses.classes.container2}>
        <div className={homeClasses.classes.form2}>
          <div className={classes.wrapper}>
            <div className={classes.wrapperFilter}>
              <div>
                <FilterCategoriesTree
                  categories={catalogCategories || []}
                  resetProducts={resetProducts}
                  searchProducts={data => {
                    dispatch(productCatalogActions.fetchRequest({ ...data }));
                  }}
                  filter={filter}
                  loading={loading}
                  setSearch={value => dispatch(productCatalogActions.setSearch(value))}
                  selectedCategoryId={selectedCategoryId}
                  setSelectedCategoryId={setSelectedCategoryId}
                  activity={activity}
                />
                {!isBuyer && isAuthorized && (
                  <FilterActivity
                    margin={false}
                    activity={activity}
                    setActivity={handleActivity}
                  />
                )}
              </div>
            </div>

            <div className={classes.wrapperTable}>
              <ProductsEditPricingHeader
                formik={formik}
                setCheckedId={setCheckedId}
                checkedId={checkedId}
                getProducts={getProducts}
                setCheckedIdActive={setCheckedIdActive}
                checkedIdActive={checkedIdActive}
              />

              <ProductsEditPricingTable
                setCheckedId={setCheckedId}
                checkedId={checkedId}
                setCheckedIdActive={setCheckedIdActive}
                checkedIdActive={checkedIdActive}
                data={products || []}
                page={page}
                perPage={perPage}
                fetch={({ page, perPage }) =>
                  dispatch(
                    actions.fetchRequest({
                      page,
                      perPage,
                      filter,
                      categoryId: 0,
                      activity: 'all',
                    })
                  )
                }
              />
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default ProductsEditPricing;
