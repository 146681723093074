import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
// import { useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import TablePaginator from '../../../../components/tableComponents/TablePaginator/TablePaginator';
import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import AlertDialog from '../../../../components/other/Dialog/AlertDialog';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { IAppState } from '../../../../store/rootDuck';
import { actions as orderActions } from '../../../../store/ducks/orders.duck';
import { actions as messsageActions } from '../../../../store/ducks/messages.duck';
import {
  getOrderTypeWithTranslates,
  ListType,
  getStatusTypeWithTranslates,
} from '../constatns';
import { IUser, UserRoles } from '../../../../interfaces/user';
import MessagesDialog from '../MessagesDialog';
import { IOrder } from '../../../../interfaces/order';
import { actions as profileActions } from '../../../../store/ducks/profile.duck';
import { actions as companiesActions } from '../../../../store/ducks/companies.duck';

import { useStyles } from '../hooks/useStyles';
import { useFormatMessage } from '../../../../hooks';
import TableRowOrderList from './TableRowOrderList';
import FilterOrders from './FilterOrders';
import { useSearchUsers } from '../../users/users/hooks/useSearchUsers';
import { getStatusString } from '../../../../utils/utils';
import { ICompany } from '../../../../interfaces/companies';

// const getStatusString = (type: string, selectedType?: string, me?: IUser): string => {
//   switch (type) {
//     case ListType.FULL:
//       if (me?.roles.includes(UserRoles.ROLE_BUYER)) {
//         return `${
//           selectedType
//             ? `${selectedType}`
//             : orderTypeList.filter(item => item !== 'completed').toString()
//         }`;
//       }
//       return `${selectedType ? `${selectedType}` : orderTypeList.toString()}`;
//     case ListType.ABANDONED:
//       return 'cart';
//     default:
//       return `[]`;
//   }
// };

const OrdersList: React.FC<
  TPropsFromRedux & { type: string; userId?: number; smallSendMsgBtn?: boolean }
> = ({
  orders,
  loading,
  fetch,
  page,
  perPage,
  total,
  delOrder,
  delError,
  clearErrors,
  editStatus,
  userRole,
  clearMessages,
  meLoading,
  fetchMe,
  clearMe,
  me,
  type,
  userId,
  fetchCompanies,
  loadingCompanies,
  companies,
  // smallSendMsgBtn,
}) => {
  const classes1 = useStyles();
  const { classes } = classes1;
  const intl = useIntl();
  const fm = useFormatMessage();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);
  const [showModalMessages, setShowModalMessages] = useState(false);
  const [currentResponse, setCurrentResponse] = useState<IOrder | null>(null);
  // const [createChatFetch, loadingCreated, createChat] = useCreateChat();
  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedPaidId, setSelectedPaidId] = useState(-1);
  const defaultType = { value: '', label: fm('ORDER.FILTER.PLACEHOLDER') };
  const [paymentType, setPaymentType] = useState<string>('');

  const selectedOrderStatuses: string[] = [];
  // const [selectedOrderStatuses, setSelectedOrderStatuses] = useState<string[]>([]);
  const selectedPaidOrderStatuses: string[] = [];
  // const [selectedPaidOrderStatuses, setSelectedPaidOrderStatuses] = useState<string[]>([]);

  // filter
  const [fio, setFio] = useState({} as IUser);
  const [phone, setPhone] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [startDate, setStart] = useState(moment(new Date()).format('YYYY.MM.DD'));
  const [endDate, setEnd] = useState(moment(new Date()).format('YYYY.MM.DD'));
  const [userPhone, setUserPhone] = useState({} as IUser);
  const [companySelect, setCompanySelect] = useState<ICompany | null>(null);

  const {
    fetchUsers,
    loading: searchLoading,
    loadingPhone,
    users: searchUsers,
    setUsers: setUsersSearch,
  } = useSearchUsers();

  useEffect(() => {
    // const statuses = getStatusString(type, selectedOrderStatuses);
    fetch({
      statuses: selectedType,
      page: 1,
      perPage: 20,
      userId,
      user_name: fio.first_name,
      user_email: email,
      user_phone: phone,
      date_from: startDate,
      date_to: endDate,
      company_id: companySelect?.id.toString(),
    });
  }, [
    type,
    userId,
    selectedOrderStatuses,
    me,
    fio,
    email,
    phone,
    startDate,
    endDate,
    companySelect,
    paymentType,
    selectedType,
  ]);

  useLayoutEffect(() => {
    fetchCompanies({ page: 1, perPage: 1000 });
  }, []);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    if (deleteId) {
      delOrder({
        page,
        perPage,
        id: deleteId,
        statuses: getStatusString(type, selectedOrderStatuses),
        // root: me?.is_admin
      });
    }
  }, [deleteId, page, perPage, type, delOrder, me]);

  // const editAction = useCallback(
  //   (item, mode) => {
  //     history.push(`/orders/${item.id}/${mode}`);
  //   },
  //   [history]
  // );

  const selectAction = useCallback(
    (id: number) => {
      setSelectedId(id);
    },
    [setSelectedId]
  );
  useEffect(() => {
    if (delError) {
      enqueueSnackbar(delError, { variant: 'error' });
      setAlertOpen(false);
      clearErrors();
    }
    return () => {
      clearErrors();
    };
  }, [delError, clearErrors, setAlertOpen, enqueueSnackbar]);

  // useEffect(() => {
  //   const statuses = getStatusString(type, selectedOrderStatuses);
  //   fetch({ statuses, page: 1, perPage: 20, userId });
  // }, [type, userId, selectedOrderStatuses]);
  // useEffect(() => {
  //   const statuses = getStatusString(type, selectedOrderStatuses);
  //   if (type === ListType.HISTORY && isBuyer) {
  //     statuses = 'delivered';
  //   }
  //   fetch({
  //     statuses,
  //     page,
  //     perPage,
  //     userId,
  //     accord_order_state: selectedType,
  //     payment_status: paymentType,
  //   });
  // }, [type, selectedType, fetch, userId, paymentType]);

  useEffect(() => {
    const statuses = getStatusString(type, selectedOrderStatuses);
    fetch({ statuses, page: 1, perPage: 20, userId });
  }, [type, userId, selectedOrderStatuses, me]);

  useEffect(() => {
    if (!me) fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  const translates: { [type: string]: string } = {
    cart: intl.formatMessage({ id: 'ORDER.TYPE.CART' }),
    new: intl.formatMessage({ id: 'ORDER.TYPE.NEW' }),
    formation: intl.formatMessage({ id: 'ORDER.TYPE.FORMATION' }),
    ready_to_delivery: intl.formatMessage({ id: 'ORDER.TYPE.DELIVERY' }),
    payed: intl.formatMessage({ id: 'ORDER.TYPE.PAYED' }),
    canceled: intl.formatMessage({ id: 'ORDER.TYPE.CANCELED' }),
    completed: intl.formatMessage({ id: 'ORDER.TYPE.COMPLETED' }),
    in_processing: intl.formatMessage({ id: 'ORDER.TYPE.PROCCESS' }),
  };
  // const AccordOrderStates = [
  //   { lable: intl.formatMessage({ id: 'ACCORD.TYPE.CANCELED' }), value: '-1' },
  //   { lable: intl.formatMessage({ id: 'ORDER.TYPE.NEW' }), value: '1' },
  //   { lable: intl.formatMessage({ id: 'ACCORD.TYPE.ERROR' }), value: '2' },
  //   { lable: intl.formatMessage({ id: 'ACCORD.TYPE.INPROD' }), value: '3' },
  //   { lable: intl.formatMessage({ id: 'ACCORD.TYPE.SHIPPED' }), value: '4' },
  // ];
  // const translatesAccord: { [type: string]: string } = {
  //   '-1': intl.formatMessage({ id: 'ACCORD.TYPE.CANCELED' }),
  //   '1': intl.formatMessage({ id: 'ORDER.TYPE.NEW' }),
  //   '2': intl.formatMessage({ id: 'ACCORD.TYPE.ERROR' }),
  //   '3': intl.formatMessage({ id: 'ACCORD.TYPE.INPROD' }),
  //   '4': intl.formatMessage({ id: 'ACCORD.TYPE.SHIPPED' }),
  // };
  const translatesPayment: { [type: string]: string } = {
    paid: intl.formatMessage({ id: 'ORDER.PAYMENT.STATUS.PAID' }),
    not_paid: intl.formatMessage({ id: 'ORDER.PAYMENT.STATUS.NOT_PAID' }),
  };
  const PaymentOrderStates = [
    { lable: intl.formatMessage({ id: 'ORDER.PAYMENT.STATUS.PAID' }), value: 'paid' },
    { lable: intl.formatMessage({ id: 'ORDER.PAYMENT.STATUS.NOT_PAID' }), value: 'not_paid' },
  ];
  const selectStyles = {
    container: (provided: any) => ({
      ...provided,
      marginBottom: 16,
      width: '100%',
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      zIndex: 999,
      display: state.selectProps.isSearchable ? provided.display : 'none',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: state.isFocused ? '#369ff7' : '',
      backgroundColor: '#f5f8fa',
      border: 'none !important',
      opacity: state.isDisabled ? 0.5 : 1,
      boxShadow: state.isFocused ? '0 0 0 1px #369ff7 !important' : '',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#369ff7' : '#fff',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#4c4c4c',
      fontFamily: 'Open Sans',
      fontWeight: 600,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontFamily: 'Open Sans',
      fontWeight: 600,
    }),
    dropdownIndicator: (provided: any, state: any) => {
      return {
        ...provided,
        display: state.selectProps.isSearchable ? provided.display : 'none',
      };
    },
  };
  const orderTypes = getOrderTypeWithTranslates(translates, type === ListType.ABANDONED);
  const orderStatusTypes = getStatusTypeWithTranslates(translatesPayment, true);

  const isBuyer = useMemo(
    () =>
      userRole?.[0] === UserRoles.ROLE_BUYER || userRole?.[0] === UserRoles.ROLE_BUYER_STAFF,
    [userRole]
  );
  // const isVendor = useMemo(() => userRole?.[0] === UserRoles.ROLE_VENDOR, [userRole]);
  // const isAdmin = useMemo(() => userRole?.[0] === UserRoles.ROLE_ADMIN, [userRole]);

  // const handlerShowMessages = useCallback((item: IOrder) => {
  //   setCurrentResponse(item);
  //   setShowModalMessages(true);
  // }, []);

  const closeMessages = useCallback(() => {
    setShowModalMessages(false);
    setCurrentResponse(null);
    clearMessages();
  }, []);

  // const orderStatusOptions = useMemo(() => {
  //   return orderTypes.map(item => ({
  //     label: item.translate,
  //     value: item.type,
  //   }));
  // }, [orderTypes]);

  const selectActionPaid = useCallback(
    (id: number) => {
      setSelectedPaidId(id);
    },
    [setSelectedPaidId]
  );

  const handleDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const editAction = useCallback(
    (item: any, mode: string) => {
      navigate(`/orders/${item.id}/${mode}`);
    },
    [navigate]
  );

  // const newChat = useCallback((companyId?: string | number, userId?: string | number) => {
  //   if (companyId) {
  //     createChatFetch({ companyId });
  //   }
  //   createChatFetch({ userId });
  // }, []);

  // const newUserChat = useCallback((userId?: string | number) => {
  //   createChatFetch({ userId });
  // }, []);

  // if (meLoading || loadingCreated) return <Preloader />;
  if (meLoading) return <Preloader />;

  return (
    <>
      <div className={classes.container}>
        {type === ListType.FULL && (
          <div className={classes.filterCol}>
            <Card className={classes.filter}>
              {/* <MultiSelect */}
              {/*  label={fm('ORDER.FILTER.TYPE')} */}
              {/*  options={orderStatusOptions} */}
              {/*  emptyValueLabel={fm('ORDER.FILTER.PLACEHOLDER')} */}
              {/*  selectedValues={selectedOrderStatuses} */}
              {/*  setSelectedValues={setSelectedOrderStatuses} */}
              {/* /> */}
              <Typography className={classes.label}>
                {intl.formatMessage({
                  id: 'ORDER.FILTER.TYPE',
                })}
              </Typography>
              <Select
                placeholder={fm('ORDER.FILTER.PLACEHOLDER')}
                components={{ IndicatorSeparator: () => null }}
                value={
                  selectedType
                    ? { label: translates[selectedType], value: selectedType }
                    : undefined
                }
                styles={selectStyles}
                onChange={item => {
                  if (item) {
                    setSelectedType(item.value);
                  }
                }}
                options={
                  orderTypes
                    ? [
                        defaultType,
                        ...orderTypes.map(item => ({
                          label: item.translate,
                          value: item.type,
                        })),
                      ]
                    : [defaultType]
                }
              />
              <Typography className={classes.label}>
                {intl.formatMessage({
                  id: 'ORDER.PAYMENT.STATUS',
                })}
              </Typography>
              <Select
                placeholder={fm('ORDER.FILTER.PLACEHOLDER')}
                components={{ IndicatorSeparator: () => null }}
                value={
                  paymentType
                    ? { label: translatesPayment[paymentType], value: paymentType }
                    : undefined
                }
                styles={selectStyles}
                onChange={item => {
                  if (item) {
                    setPaymentType(item.value);
                  }
                }}
                options={
                  orderTypes
                    ? [
                        defaultType,
                        ...PaymentOrderStates.map(item => ({
                          label: item.lable,
                          value: item.value,
                        })),
                      ]
                    : [defaultType]
                }
              />
              <FilterOrders
                fetchUsers={fetchUsers}
                searchUsers={searchUsers}
                setUsersSearch={setUsersSearch}
                searchUsersLoading={searchLoading}
                fio={fio}
                setFio={setFio}
                setPhone={setPhone}
                startDate={startDate}
                endDate={endDate}
                setStart={setStart}
                setEnd={setEnd}
                loadingPhone={loadingPhone}
                userPhone={userPhone}
                setUserPhone={setUserPhone}
                setEmail={setEmail}
                companySelect={companySelect}
                setCompanySelect={setCompanySelect}
                loadingCompanies={loadingCompanies}
                companies={companies}
                me={me}
              />
            </Card>
          </div>
        )}
        <div className={`${classes.ordersCol} ${type === ListType.ABANDONED ? 'full' : ''}`}>
          {loading ? (
            <Preloader />
          ) : (
            <>
              {!orders || !orders.length ? (
                <Typography className={classes.empty} component='h5' variant='h5'>
                  {intl.formatMessage({ id: 'ORDER.CATALOG.LIST.EMPTY' })}
                </Typography>
              ) : (
                <div className={classes.card}>
                  <Card className={classes.card}>
                    <CardContent className={classes.tableContainer}>
                      <Table aria-label='purchases'>
                        <TableHead>
                          <TableRow>
                            <SHeadTableCell className={classes.cell} align='center'>
                              {intl.formatMessage({ id: 'ORDER.TABLE.ID' })}
                            </SHeadTableCell>
                            <SHeadTableCell className={classes.cell}>
                              {intl.formatMessage({ id: 'ORDER.TABLE.PRODUCT_AMOUNT' })}
                            </SHeadTableCell>
                            <SHeadTableCell className={classes.cell}>
                              {intl.formatMessage({ id: 'USER.ROLES.VENDOR' })}
                            </SHeadTableCell>
                            <SHeadTableCell className={classes.cell}>
                              {intl.formatMessage({ id: 'ORDER.TABLE.DATE' })}
                            </SHeadTableCell>
                            <SHeadTableCell className={classes.cell}>
                              {intl.formatMessage({ id: 'ORDER.TABLE.STATUS' })}
                            </SHeadTableCell>
                            <SHeadTableCell className={classes.cell}>
                              {intl.formatMessage({ id: 'ORDER.TABLE.STATUS.PAYMENT' })}
                            </SHeadTableCell>
                            <SHeadTableCell className={classes.cell}>
                              {intl.formatMessage({ id: 'ORDER.TABLE.SUM_ORDER' })}
                            </SHeadTableCell>
                            <SHeadTableCell className={classes.cell}>
                              {intl.formatMessage({ id: 'ORDER.TABLE.SUM_PAY' })}
                            </SHeadTableCell>
                            <SHeadTableCell className={classes.cell}>
                              {intl.formatMessage({ id: 'ORDER.TABLE.ACTIONS' })}
                            </SHeadTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orders.map(item => (
                            <TableRowOrderList
                              key={item.id.toString()}
                              item={item}
                              me={me}
                              type={type}
                              selectAction={selectAction}
                              selectActionPaid={selectActionPaid}
                              handleDeleteDialog={handleDeleteDialog}
                              editAction={editAction}
                              selectedOrderStatuses={selectedOrderStatuses}
                              selectedPaidOrderStatuses={selectedPaidOrderStatuses}
                              selectedId={selectedId}
                              selectedPaidId={selectedPaidId}
                              isBuyer={isBuyer}
                              translates={translates}
                              translatesStatus={translatesPayment}
                              editStatus={editStatus}
                              page={page}
                              perPage={perPage}
                              userId={userId}
                              orderTypes={orderTypes}
                              orderStatusTypes={orderStatusTypes}
                            />
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePaginator
                              statuses={getStatusString(type, selectedOrderStatuses)}
                              page={page}
                              realPerPage={orders.length}
                              perPage={perPage}
                              total={total}
                              fetchRows={fetch}
                              label={intl.formatMessage({ id: 'ORDER.TABLE.PERPAGE' })}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </CardContent>
                  </Card>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {showModalMessages && (
        <MessagesDialog
          open={showModalMessages}
          handleClose={closeMessages}
          currentResponse={currentResponse}
        />
      )}
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'ORDER.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          clearErrors();
          setAlertOpen(false);
        }}
        handleAgree={() => deleteAction()}
      />
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    meLoading: state.profile.loading,
    orders: state.orders.orders,
    loading: state.orders.loading,
    page: state.orders.page,
    perPage: state.orders.per_page,
    total: state.orders.total,
    delError: state.orders.delError,
    userRole: state.auth.user?.roles,
    me: state.profile.me,

    companies: state.companies.companies,
    loadingCompanies: state.companies.loading,
  }),
  {
    fetch: orderActions.fetchRequest,
    delOrder: orderActions.delRequest,
    clearErrors: orderActions.clearEdit,
    editStatus: orderActions.editStatusRequest,

    clearMessages: messsageActions.clear,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
    fetchCompanies: companiesActions.fetchRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OrdersList);
