import React, { useCallback } from 'react';
import { Button, MenuItem, TextField, InputLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import ButtonWithLoader from '../../../../components/other/Buttons/ButtonWithLoader';
import { ICategoryItem } from '../../categories/interfaces';
import { useFormatMessage } from '../../../../hooks';
import { useStylesSearchPage } from '../hooks/useStyles';
import CkEditorCustom from '../../../../components/formComponents/CkEditorCustom';

interface IProps {
  id: string | undefined;
  formik: any;
  editLoading: boolean;
  categories: ICategoryItem[] | undefined;
  handleDeleteRequest: () => void;
  text: string | undefined;
  setDescEditorState: any;
}

export const RequestForm: React.FC<IProps> = ({
  id,
  formik,
  editLoading,
  categories,
  handleDeleteRequest,
  text,
  setDescEditorState,
}) => {
  const fm = useFormatMessage();
  const navigate = useNavigate();
  const intl = useIntl();
  const classes = useStylesSearchPage();

  const { handleSubmit, values, handleChange, handleBlur, touched, errors } = formik;

  const categoryName = useCallback(
    (category: ICategoryItem) => {
      const { locale } = intl;
      // @ts-ignore
      return locale === 'ru' ? category.name : category.names[`name_${locale}`];
    },
    [intl.locale]
  );

  return (
    <>
      <TextField
        select
        label={fm('PRODUCT.EDIT.CATEGORY')}
        margin='normal'
        name='categoryId'
        value={values.categoryId || ''}
        variant='outlined'
        onBlur={handleBlur('categoryId')}
        onChange={handleChange}
        helperText={touched.categoryId && errors.categoryId}
        error={Boolean(touched.categoryId && errors.categoryId)}
        className={classes.field}
      >
        {categories && categories.length > 0 ? (
          categories.map(item => {
            const nameLang = categoryName(item);
            return (
              <MenuItem
                className={!nameLang ? classes.fontNoLang : classes.labelText}
                key={item.id?.toString()}
                value={item.id?.toString()}
              >
                {nameLang || item.name}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem value=''>{fm('COMMON.NO_CATEGORIES')}</MenuItem>
        )}
      </TextField>

      <TextField
        className={classes.field}
        type='text'
        label={fm('REQUEST.EDIT.NAME')}
        margin='normal'
        name='title'
        value={values.title || ''}
        variant='outlined'
        onBlur={handleBlur('title')}
        onChange={handleChange}
        helperText={touched.title && errors.title}
        error={Boolean(touched.title && errors.title)}
      />

      <InputLabel style={{ marginTop: 10 }} shrink>
        {fm('PRODUCT.TABLE.DESCRIPTION')}
      </InputLabel>
      <CkEditorCustom html={text || ''} setEditorStateCallback={setDescEditorState} />

      <div className={classes.actions}>
        <div>
          <Button
            onClick={() => navigate(-1)}
            className={classes.buttons}
            style={{
              marginRight: 10,
            }}
            variant='outlined'
            color='primary'
          >
            {fm('CATEGORY.BUTTON.CANCEL')}
          </Button>
          <ButtonWithLoader
            disabled={editLoading}
            loading={editLoading}
            onPress={handleSubmit}
            marginRight={10}
          >
            {id ? fm('COMMON.BUTTON.SAVE') : fm('REQUEST.ADD.TITLE')}
          </ButtonWithLoader>
        </div>
        {id && (
          <Button
            onClick={handleDeleteRequest}
            className={classes.deleteBtns}
            variant='outlined'
          >
            {fm('COMMON.BUTTON.DELETE')}
          </Button>
        )}
      </div>
    </>
  );
};
