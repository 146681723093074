import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';
import { Skeleton } from '@mui/lab';
import { Button, Card } from '@mui/material';
import { useSnackbar } from 'notistack';
import { IAppState } from '../../../store/rootDuck';
import { actions as companiesActions } from '../../../store/ducks/companies.duck';
import { actions as productActions } from '../../../store/ducks/product.duck';
import { actions as tendersActions } from '../../../store/ducks/tenders.duck';
import homeStyles from '../../../constants/homeStyles';
import { setLayoutSubheader } from '../../../utils/layout';
import { getTenderTypeWithTranslates } from './constatns';
import { useStyles } from './hooks/useStyles';
import AlertDialog from '../../../components/other/Dialog/AlertDialog';
import ButtonWithLoader from '../../../components/other/Buttons/ButtonWithLoader';
import { useDeleteProductToTender } from './hooks/useDeleteProductToTender';
import { useFormikTenderForm } from './hooks/useFormikTenderPage';
import TenderForm from './components/TenderForm';
import { useCreateTender } from './hooks/useCreateTender';
import { useCreateRiskToTender } from '../products/hooks/useCreateRiskToTender';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { useGenerateReport } from './hooks/useGenerateReport';
import { useDefineUserRole } from '../../../hooks';
import ParticipationTab from './components/ParticipationTab';
import { useAxiosPostCallback } from '../../../hooks/useAxiosGet';
import { useDeleteProduct } from './hooks/useDeleteProduct';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
// import TenderReportForm from './components/TenderReportForm';
import Tabs from '../../../components/formComponents/Tabs';
// import GradientButton from '../../../components/ui/Buttons/GradientButton';
import { useGetTenderSteps } from './hooks/useGetTenderSteps';
import UploadFilesForm from './components/UploadFilesForm';

export enum ViewMode {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create',
}

export interface IFile {
  file: File;
  type: string;
  uploaded: boolean;
  id: number | null;
  path?: string;
}

const TenderPage: React.FC = () => {
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [isAlertOpenDelete, setAlertOpenDelete] = useState(false);
  // const [pdfUrl, setPdfUrl] = useState('#');
  const intl = useIntl();
  const { id, mode } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const homeClasses = homeStyles();
  const dispatch = useDispatch();
  const me = useSelector(({ profile }: IAppState) => profile.me, shallowEqual);
  const { tender, loading, editError, editSuccess, editLoading } = useSelector(
    ({ tenders }: IAppState) => ({
      tender: tenders.tender,
      loading: tenders.byIdLoading,
      editError: tenders.editError,
      editSuccess: tenders.editSuccess,
      editLoading: tenders.editLoading,
    }),
    shallowEqual
  );
  const { categories, categoriesError, categoriesSuccess } = useSelector(
    ({ categories }: IAppState) => ({
      categories: categories.catalogCategories,
      categoriesLoading: categories.categoriesLoading,
      categoriesError: categories.categoriesError,
      categoriesSuccess: categories.categoriesSuccess,
    }),
    shallowEqual
  );

  useCrudSnackbar({
    success: categoriesSuccess,
    error: categoriesError,
    successMessage: intl.formatMessage({ id: 'COMPANY.EDIT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${categoriesError}`,
    afterSuccess: () => {
      dispatch(categoriesActions.fetchFullRequest());
    },
  });

  const menuConfig = useSelector(
    ({ builder }: IAppState) => builder.menuConfig.aside.items,
    shallowEqual
  );

  const breadcrumb = useMemo(() => {
    const menu = menuConfig.find(menu => menu.title === 'MENU.TENDERS');
    return [
      {
        title: menu?.translate || menu?.title ? intl.formatMessage({ id: menu?.translate || menu?.title || '' }) : '',
        page: menu?.page || '',
        root: true,
      },
    ];
  }, [menuConfig, intl]);

  const { page, perPage } = useSelector(
    ({ productsCatalog }: IAppState) => ({
      page: productsCatalog.page,
      perPage: productsCatalog.per_page,
    }),
    shallowEqual
  );
  const classes1 = useStyles();
  const {classes} = classes1
  const [tabValue, setTabValue] = useState(0);
  // const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const {
    // deleteRisk,
    successDelete,
  } = useDeleteProductToTender();
  const { createTenderFetch, createLoading, createId } = useCreateTender();
  const { createRisk, successCreate } = useCreateRiskToTender();
  const { successGenerate } = useGenerateReport();
  const { deleteProductFetch, successDeleteCommonTab } = useDeleteProduct();
  const [getSteps, steps] = useGetTenderSteps();
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);

  const [allFilesUploadingLoading, setAllFilesUploadingLoading] = useState(false);

  const [files, setFiles] = useState<IFile[]>([]);
  const { err: fileUploadErr, makeRequest: uploadFile } = useAxiosPostCallback();

  // useEffect(() => {
  //   state?.nextTab && setTabValue(1);
  // }, [state]);

  useEffect(() => {
    if (tender) {
      setFiles(
        tender.attachments.map(({ name, type, id, path }) => ({
          file: { name } as File,
          type,
          uploaded: true,
          id,
          path,
        }))
      );
    }
  }, [tender]);

  useEffect(() => {
    if (fileUploadErr) {
      enqueueSnackbar(intl.formatMessage({ id: 'ERROR' }), { variant: 'error' });
    }
  }, [fileUploadErr]);

  const { editCompanySuccess, editCompanyError } = useSelector(
    ({ companies }: IAppState) => ({
      editCompanySuccess: companies.editSuccess,
      editCompanyError: companies.editError,
    }),
    shallowEqual
  );

  // For edit actions
  useCrudSnackbar({
    success: editCompanySuccess,
    error: editCompanyError,
    successMessage: intl.formatMessage({ id: 'COMPANY.EDIT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${editCompanyError}`,
    afterSuccess: () => {
      if (id) {
        getSteps(id);
        dispatch(tendersActions.fetchByIdRequest(+id));
      }
    },
  });

  useEffect(() => {
    dispatch(companiesActions.fetchRequest({ page: 1, perPage: 100 }));
    if (id) {
      getSteps(id);
      dispatch(tendersActions.fetchByIdRequest(+id));
    }
    if (id && (successDelete || successCreate || successGenerate || successDeleteCommonTab)) {
      getSteps(id);
      dispatch(tendersActions.fetchByIdRequest(+id));
    }
  }, [id, successDelete, successCreate, successGenerate, successDeleteCommonTab]);

  useEffect(() => {
    createId && navigate(`/tenders/${createId}/edit`, {state: {nextTab: true}})
  }, [createId]);

  const tenderProducts = useMemo(
    () =>
      tender?.items &&
      tender.items.map((item: any) => item.product).filter(item => !item.is_deleted),
    [tender]
  );

  useEffect(() => {
    if (editSuccess || editError) {
      enqueueSnackbar(
        editSuccess
          ? ` ${intl.formatMessage({
              id: 'TENDER.SNACKBAR.EDIT',
            })}`
          : `${intl.formatMessage({ id: 'PRODUCT.SNACKBAR.ERROR' })} ${editError}`,
        { variant: editSuccess ? 'success' : 'error' }
      );
      if (editSuccess) {
        getSteps(id);
        id && dispatch(tendersActions.fetchByIdRequest(+id));
      }
    }
  }, [editSuccess, editError, enqueueSnackbar, id, intl]);

  useEffect(() => {
    (isBuyer || isVendor) && !me?.company && navigate('/companies/list');
  }, []);

  const formik = useFormikTenderForm(id || '', createTenderFetch, tender, me);
  const { handleSubmit, values } = formik;

  const translates: { [type: string]: string } = {
    new: intl.formatMessage({ id: 'TENDER.TYPE.NEW' }),
    canceled: intl.formatMessage({ id: 'TENDER.TYPE.CANCELED' }),
    completed: intl.formatMessage({ id: 'TENDER.TYPE.COMPLETED' }),
    in_processing: intl.formatMessage({ id: 'TENDER.TYPE.PROCCESS' }),
    needs_clarification: intl.formatMessage({ id: 'TENDER.TYPE.NEED_CERF' }),
    report_ready: intl.formatMessage({ id: 'TENDER.TYPE.READY' }),
  };
  const tenderTypes = getTenderTypeWithTranslates(translates, false);
  const disabled = mode === ViewMode.VIEW;

  const tabs = useMemo(() => {
    const tabs: { id: string; isChecked: boolean }[] = [
      { id: 'TENDER.TAB.COMMON', isChecked: steps ? steps[1] : false },
    ];
    if  (id) {
      tabs.push({ id: 'TENDER.TAB.FILES', isChecked: steps ? steps[2] : false });
      !tender?.no_report_required &&
      // tabs.push({ id: 'TENDER.TAB.REPORTS', isChecked: steps ? steps[3] : false });
      tabs.push({ id: 'TENDER.TAB.PARTICIPATION', isChecked: steps ? steps[4] : false });
    }
    return tabs;
  }, [id, isVendor, isBuyer, me, isManager, isAdmin, tender, steps]);

  setLayoutSubheader({
    title:
      // eslint-disable-next-line no-nested-ternary
      mode === ViewMode.VIEW
        ? intl.formatMessage({ id: 'TENDER.HEADER.VIEW' })
        : mode === ViewMode.EDIT
        ? intl.formatMessage({ id: 'TENDER.HEADER.EDIT' })
        : intl.formatMessage({ id: 'TENDER.HEADER.CREATE' }),
    breadcrumb,
  });

  const submitCallback = useCallback(
    // async (disabledNext?: boolean) => {
    async () => {
      // upload files
      if (files.find(f => f.type === '')) return;
      const promises = [];
      const filesToUpload = files.filter(f => !f.uploaded);
      if (filesToUpload.length > 0) {
        setAllFilesUploadingLoading(true);
        for (const file of filesToUpload) {
          const fm = new FormData();
          fm.append('type', file.type);
          fm.append('file', file.file);
          promises.push(uploadFile(`/api/shop/tender/${id}/add_attachment`, fm));
        }
        await Promise.all(promises).finally(() => setAllFilesUploadingLoading(false));
      }

      // create or upadte order
      if (
        tender &&
        (tender.status === 'payed' ||
          tender.status === 'completed' ||
          tender.status === 'ready_to_delivery')
      ) {
        setAlertOpen(true);
      } else {
        handleSubmit();
      }
      // additional condition => && !disabledNext
      if (tabValue !== tabs.length - 1) {
        id && setTabValue(value => value + 1);
      }
    },
    [tender, handleSubmit, values, me, files, tabs, tabValue, id]
  );

  const addRiskToTender = useCallback(
    (data: { data: FormData }) => {
      if (id) {
        // submitCallback(true);
        submitCallback();
        createRisk(data, +id);
      }
    },
    [id]
  );

  const deleteRiskCommonTab = useCallback((id: number) => {
    // submitCallback(true);
    submitCallback();
    deleteProductFetch(id);
  }, []);

  // const deleteRiskToTender = useCallback(
  //   (productId: number) => id && deleteRisk(productId, +id),
  //   [id]
  // );

  useEffect(
    () => () => {
      dispatch(tendersActions.clearEdit());
      dispatch(companiesActions.clearCompanies());
    },
    []
  );

  // const handleDeleteDialog = useCallback((id: number | undefined) => {
  //   setDeleteId(id);
  //   setAlertOpenDelete(true);
  // }, []);

  const deleteAction = useCallback(() => {
    setAlertOpenDelete(false);
    // deleteId && deleteRiskToTender(deleteId);
  }, [perPage, page]);

  // const editAction = useCallback(
  //   item =>
  //     history.push({
  //       pathname: `/products/edit/${item.id}`,
  //       state: { tenderId: tender?.id },
  //     }),
  //   [history, tender]
  // );

  // const viewAction = useCallback(item => history.push(`/viewproduct/${item.id}`), [history]);

  // const changeStatus = useCallback(
  //   () =>
  //     id &&
  //     tender?.client &&
  //     generateReport(id, {
  //       company_id: tender.client.id,
  //       status: 'report_ready',
  //       interested_id: tender.interested?.id,
  //       customer_id: tender.customer?.id,
  //       dop_contractor_id: tender.dop_contractor?.id,
  //     }),
  //   [tender, id]
  // );

  const alertText = useMemo(
    () =>
      `${intl.formatMessage({ id: 'ORDER.ALERT.EDIT.TEXT1' })} "${
        translates[tender?.status || '']
      }". ${intl.formatMessage({ id: 'ORDER.ALERT.EDIT.TEXT2' })}`,
    [translates, tender]
  );

  if (loading && !tender) {
    return <></>;
  }

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={alertText}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setAlertOpen(false);
        }}
        handleAgree={() => handleSubmit()}
      />
      <AlertDialog
        open={isAlertOpenDelete}
        message={intl.formatMessage({ id: 'PRODUCT.DELETE.TEXT.ORDER' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          dispatch(productActions.clearDel());
          setAlertOpenDelete(false);
        }}
        handleAgree={() => deleteAction()}
      />
      <Card
        className={homeClasses.classes.container}
        style={{
          margin: 0,
          height: '100%',
        }}
      >
        <div className={classes.content}>
          <div
            className={homeClasses.classes.form}
            style={{
              maxWidth:
                tabValue === 4 ||
                tabValue === 6 ||
                (tender?.no_report_required && tabValue === 5)
                  ? 1200
                  : 800,
            }}
          >
            {loading && !tender ? (
              <>
                <Skeleton width='100%' height={70} animation='wave' />
                <Skeleton width='100%' height={70} animation='wave' />
                <Skeleton width='100%' height={70} animation='wave' />
                <Skeleton width='100%' height={70} animation='wave' />
                <Skeleton width='100%' height={70} animation='wave' />
                <Skeleton width='100%' height={70} animation='wave' />
                <Skeleton width='100%' height={70} animation='wave' />
              </>
            ) : (
              <>
                <Tabs
                  isNumbers
                  idForMenu='MENU.ORDERS'
                  tabs={tabs}
                  value={tabValue}
                  setTabValue={setTabValue}
                  disabledTabs={!id}
                />
                <div style={{ display: tabValue === 0 ? 'contents' : 'none' }}>
                  <TenderForm
                    formik={formik}
                    disabled={disabled}
                    tenderTypes={tenderTypes}
                    products={tenderProducts || []}
                    tender={tender}
                    me={me}
                    addRiskToTender={addRiskToTender}
                    deleteRiskCommonTab={deleteRiskCommonTab}
                    id={id || ''}
                    showInsurerCompany={!((isVendor || isBuyer) && me?.company && !id)}
                    showInsuredCompany={!((isVendor || isBuyer) && !id)}
                    successCreate={successCreate}
                    categories={categories}
                  />
                </div>
                {id && (
                  <div style={{ display: tabValue === 1 ? 'contents' : 'none' }}>
                    <UploadFilesForm
                      files={files}
                      setFiles={setFiles}
                      isEditFiles={
                        isManager || isAdmin || me?.company?.id === tender?.client?.id
                      }
                    />
                  </div>
                )}
                {/* {id && tender && (
                  <div
                    style={{
                      display: tabValue === (isManager || 2) ? 'contents' : 'none',
                    }}
                  >
                    {!tender?.no_report_required ? (
                      <TenderReportForm
                        me={me}
                        isManager={isManager}
                        tender={tender}
                        loadingReport={loadingReport}
                        changeStatus={changeStatus}
                        id={id}
                        setPdfUrl={setPdfUrl}
                      />
                    ) : (
                      <ParticipationTab tender={tender} formik={formik} />
                    )}
                  </div>
                )} */}
                {id && (
                  <div
                    style={{
                      display: tabValue === (isManager || 2) ? 'contents' : 'none',
                    }}
                  >
                    <ParticipationTab tender={tender} formik={formik} />
                  </div>
                )}
                {!disabled ? (
                  <div className={classes.bottomActions}>
                    <Button
                      onClick={() => navigate('/tenders/list/full')}
                      className={classes.buttons}
                      variant='outlined'
                      color='primary'
                    >
                      {intl.formatMessage({ id: 'COMMON.BUTTON.CLOSE' })}
                    </Button>
                    {tabValue !== 0 && (
                      <Button
                        onClick={() => setTabValue(value => value - 1)}
                        className={classes.buttons}
                        variant='outlined'
                        color='primary'
                      >
                        {intl.formatMessage({ id: 'COMMON.BUTTON.BACK' })}
                      </Button>
                    )}
                    <ButtonWithLoader
                      disabled={editLoading || createLoading || allFilesUploadingLoading}
                      loading={editLoading || createLoading || allFilesUploadingLoading}
                      onPress={submitCallback}
                    >
                      {intl.formatMessage({
                        id:
                          tabValue === tabs.length - 1
                            ? 'COMMON.BUTTON.TENDER.SAVE'
                            : 'AUTH.MAIN.BUTTON',
                      })}
                    </ButtonWithLoader>
                  </div>
                ) : (
                  <Button
                    onClick={() => navigate('/tenders/list/full')}
                    className={classes.buttons}
                    variant='outlined'
                    color='primary'
                  >
                    {intl.formatMessage({ id: 'COMMON.BUTTON.CLOSE' })}
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

export default TenderPage;
