import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  Button,
} from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import HighlightOff from '@mui/icons-material/HighlightOff';
import Cached from '@mui/icons-material/Cached';
import Email from '@mui/icons-material/Email';
import { connect, ConnectedProps } from 'react-redux';
import { injectIntl, useIntl, WrappedComponentProps } from 'react-intl';

import { actions as messagesActions } from '../../../store/ducks/messages.duck';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { IAppState } from '../../../store/rootDuck';
import { API_DOMAIN } from '../../../constants';
import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { getRoleName } from '../users/users/utils';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { IOrder } from '../../../interfaces/order';
import TextFieldCustom from '../../../components/TextFieldCustom';
import { useDefineUserRole } from '../../../hooks';

const useStyles = makeStyles()(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 15,
    },

    useInfo: {
      display: 'flex',
      flexDirection: 'row',
    },

    imageWrapper: {
      width: 35,
      height: 35,
      maxHeight: 35,
      borderRadius: ' 50%',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      backgroundColor: '#efefef',
    },

    image: {
      verticalAlign: 'middle',
      width: '100%',
      height: '100%',
      maxHeight: 40,
      borderRadius: 0,
      objectFit: 'cover',
    },

    marginUserInfo: {
      marginLeft: 10,
    },

    iconSize: {
      width: 30,
      height: 30,
    },

    messagesContainer: {
      width: '100%',
      backgroundColor: '#EBECF0',
      borderRadius: 8,
      padding: 10,
      marginBottom: 10,
      overflow: 'auto',
      minHeight: 200,
    },

    messageContainer: {
      display: 'flex',
      marginBottom: 10,
    },

    wrapperMessage: {
      backgroundColor: 'white',
      padding: 8,
      borderRadius: 4,
    },

    messageFlex: {
      display: 'flex',
    },

    iconMargin: {
      marginRight: 10,
    },

    textStyle: {
      wordBreak: 'break-word',
    },

    dialogActions: {
      justifyContent: 'space-between',
    },

    buttonCheckMessage: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 0,
      paddingBottom: 0,
    },

    empty: {
      justifyContent: 'center',
      alignItems: 'center',
    },

    backdrop: {
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0,0,30,0.4)',
    },
  };
});

interface IAlertDialog extends DialogProps {
  handleClose: () => void;
  currentResponse?: IOrder | null;
}

const MessagesDialog: React.FC<IAlertDialog & TPropsFromRedux & WrappedComponentProps> = ({
  open,
  handleClose,
  currentResponse,

  me,

  messages,
  page,
  perPage,
  getLoading,
  getError,

  createLoading,
  createSuccess,
  createError,

  getMessages,
  createMessage,
}) => {
  const [messageText, setMessageText] = useState('');
  const matches = useMediaQuery('(max-width:1024px)');
  const intl = useIntl();
  const classes1 = useStyles();
  const { classes } = classes1;
  const divRref = useRef<HTMLDivElement>(null);
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);

  useEffect(() => {
    getMessagesHandler();
  }, [currentResponse]);

  const getMessagesHandler = useCallback(() => {
    if (currentResponse) {
      getMessages({ page, perPage, order_id: currentResponse.id });
    }
  }, [currentResponse, getMessages, perPage, page]);

  useCrudSnackbar({
    success: false,
    error: getError,
    successMessage: '',
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${getError}`,
  });

  useCrudSnackbar({
    success: createSuccess,
    error: createError,
    successMessage: intl.formatMessage({ id: 'DIALOGCHAT.SEND.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${createError}`,
  });

  useEffect(() => {
    if (divRref.current) {
      divRref.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }, [messages]);

  const handlerSendMessage = useCallback(() => {
    if (currentResponse && messageText.trim().length > 0 && messages) {
      createMessage({
        data: {
          order_id: currentResponse.id || 0,
          for_user_id: isBuyer ? 1 : currentResponse.user?.id || messages[0].from_user.id,
          text: messageText.trim(),
        },
      });
      setMessageText('');
    }
  }, [messageText, currentResponse, createMessage, messages, isBuyer]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      fullWidth
      maxWidth='md'
      fullScreen={matches}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
    >
      <DialogContent>
        {getLoading || createLoading ? (
          <Preloader />
        ) : (
          <>
            <div className={classes.container}>
              {currentResponse && (
                <div className={classes.useInfo}>
                  {messages && (
                    <>
                      {currentResponse.user && currentResponse.user.avatar ? (
                        <div className={classes.imageWrapper}>
                          <img
                            className={classes.image}
                            alt='Pic'
                            src={`${API_DOMAIN}/${currentResponse.user.avatar}`}
                          />
                        </div>
                      ) : (
                        !currentResponse.user &&
                        Boolean(messages[0].from_user.avatar) && (
                          <div className={classes.imageWrapper}>
                            <img
                              className={classes.image}
                              alt='Pic'
                              src={`${API_DOMAIN}/${messages[0].from_user.avatar}`}
                            />
                          </div>
                        )
                      )}
                    </>
                  )}
                  <div className={classes.marginUserInfo}>
                    {currentResponse.user ? (
                      <>
                        <span className='kt-header__topbar-username'>
                          {((currentResponse.user?.fio || currentResponse.user?.fio) &&
                            `${currentResponse.user.fio}`) ||
                            currentResponse.user.email}
                        </span>
                        <p className='kt-header__topbar-role'>
                          {getRoleName(currentResponse.user.roles, intl)}
                        </p>
                      </>
                    ) : (
                      messages && (
                        <>
                          <span className='kt-header__topbar-username'>
                            {((messages[0].from_user.last_name ||
                              messages[0].from_user.first_name) &&
                              `${messages[0].from_user.last_name || ''} ${
                                messages[0].from_user.first_name || ''
                              }`) ||
                              messages[0].from_user.email}
                          </span>
                          <p className='kt-header__topbar-role'>
                            {getRoleName(
                              messages[0].from_user.roles || [
                                'ROLE_VENDOR',
                                'ROLE_VENDOR_STAFF',
                              ],
                              intl
                            )}
                          </p>
                        </>
                      )
                    )}
                  </div>
                </div>
              )}
              <IconButton onClick={handleClose} size='small'>
                <HighlightOff className={classes.iconSize} />
              </IconButton>
            </div>
            {messages && me && (
              <div
                className={classes.messagesContainer}
                style={{ maxHeight: matches ? '50%' : '50vh', minHeight: '50vh' }}
              >
                {getLoading ? (
                  <Preloader />
                ) : (
                  <>
                    {messages.length < 1 && (
                      <Typography component='h5' variant='h5' className={classes.empty}>
                        {intl.formatMessage({ id: 'DIALOGCHAT.EMPTY' })}
                      </Typography>
                    )}
                    <div ref={divRref}>
                      {messages.map(item => (
                        <div
                          className={classes.messageContainer}
                          style={{
                            justifyContent:
                              item.from_user.id === me.id ? 'flex-end' : 'flex-start',
                            paddingLeft: item.from_user.id === me.id ? '10%' : 0,
                          }}
                        >
                          <div className={classes.wrapperMessage}>
                            <div className={classes.messageFlex}>
                              <Email color='disabled' className={classes.iconMargin} />
                              <Typography color='textSecondary'>
                                {moment(item.datetime).format('DD.MM.YYYY HH:mm')}
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                id='alert-dialog-description'
                                variant='body1'
                                className={classes.textStyle}
                              >
                                {item.text}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            )}
            <div>
              <TextFieldCustom
                onChange={text => setMessageText(text.target.value)}
                value={messageText}
                id='outlined-textarea'
                placeholder={intl.formatMessage({ id: 'DIALOGCHAT.SEND_PLACEHOLDER' })}
                multiline
                variant='outlined'
                fullWidth
                rows={3}
                autoFocus
              />
              <DialogActions className={classes.dialogActions}>
                <Button
                  className={classes.buttonCheckMessage}
                  onClick={getMessagesHandler}
                  startIcon={<Cached className={classes.iconSize} />}
                >
                  <Typography
                    id='alert-dialog-description'
                    color='textSecondary'
                    variant='body1'
                  >
                    {intl.formatMessage({ id: 'DIALOGCHAT.CHECK' })}
                  </Typography>
                </Button>
                <div />
                <ButtonWithLoader
                  disabled={false}
                  loading={false}
                  onPress={handlerSendMessage}
                >
                  {intl.formatMessage({ id: 'DIALOGCHAT.SEND' }).toUpperCase()}
                </ButtonWithLoader>
              </DialogActions>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    messages: state.messages.messages,
    getLoading: state.messages.messagesLoading,
    getError: state.messages.messagesError,
    getSuccess: state.messages.messagesSuccess,

    page: state.messages.page,
    perPage: state.messages.per_page,

    createLoading: state.messages.createMessageLoading,
    createSuccess: state.messages.createMessageSuccess,
    createError: state.messages.createMessageError,
  }),
  {
    getMessages: messagesActions.messagesRequest,
    createMessage: messagesActions.createMessageRequest,
    clear: messagesActions,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(injectIntl(MessagesDialog));
