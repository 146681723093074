import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { actions as blogActions } from '../../../store/ducks/blog.duck';

import { IAppState } from '../../../store/rootDuck';
import { actions as productActions } from '../../../store/ducks/product.duck';
import { actions as productTypeActions } from '../../../store/ducks/productType.duck';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { actions as productCatalogActions } from '../../../store/ducks/product-catalog.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { actions as cartActions } from '../../../store/ducks/cart.duck';
import { actions as productForDaysActions } from '../../../store/ducks/product-for-days.duck';
import { actions as bannersActions } from '../../../store/ducks/banners.duck';

import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { IProduct } from '../../../interfaces/product';
import { ProductsGrid } from './components';
import { useStylesProductCatalog } from './hooks/useStyles';
import { useAxiosGet, useDefineUserRole, useFormatMessage } from '../../../hooks';
import { PublicationsList } from '../blog/components';
import { IPublication } from '../blog/interfaces';
import SlickSlider from '../../../components/ui/Slider';
import { Placement } from '../banners/interfaces';
import { TCollection } from '../collections/types';
import { useGetProductsByCategory } from './hooks/useGetProductsByCategory';

// enum ViewType {
//   table = 'table',
//   grid = 'grid',
// }

const ProductList: React.FC<TPropsFromRedux> = ({
  products,
  productsForDays,
  loading,
  page,
  perPage,
  deleteError,
  me,
  loadingMe,
  addProductLoading,
  addProductSuccess,
  addProductError,
  cartCompanyId,
  isAuthorized,
  companyGuestCart,
  countProductsGuest,
  isAdmin,
  catalogCategories,
  isSearching,
  search,
  sortType,
  productsCount,
  getPublications,
  clearPublications,
  fetch,
  clearProducts,
  delProduct,
  clearDel,
  fetchMe,
  clearMe,
  edit,
  editHide,
  addProductToCart,
  setProductGuestCart,
  fetchCatalogCategories,
  fetchProductsForDays,
  fetchFavorites,
  setSearch,
  fetchType,
  clearAddProduct,
  guestCart,
  clearEdit,
  bannersMap,
  delSuccess,
  editHideSuccess,
  editHideError,
  fetchBanners,
  productsCategory,
  publications,
}) => {
  const [fetchProductsByCategory] = useGetProductsByCategory();
  const classes1 = useStylesProductCatalog();
  const { classes } = classes1;
  const intl = useIntl();
  const navigate = useNavigate();
  const fm = useFormatMessage();
  const { isSearch } = useParams();
  const location: any = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [hideId, setHideId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertStockOver, setAlertStockOver] = useState(false);
  const [isAlertStock, setAlertStock] = useState(false);
  const [isHideProduct, setIsHideProduct] = useState(false);
  // const [cartProductId, setCartProductId] = useState<IProduct | null>(null);
  // const [cartProductId, setCartProductId] = useState<IProduct | null>(null);
  const [collections, loadingCollections] = useAxiosGet(`/api/shop/collections?slot3=true`);
  // Для  Галереи с выбором коллекции
  // const [fetchProducts, productsCollection, loadingProducts] = useGetProductsCollection();
  // const [selectCollection, setSelectCollection] = useState<TCollection | null>(null);
  //  const [selected, setSelected] = useState<{ label: string; value: string | number }>({
  //   label: intl.formatMessage({ id: 'CHOOSE.COLLECTION' }),
  //   value: '',
  // });
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  // const [filterData, setFilterData] = useState<any>({});
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const isVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  // const [viewType, setViewType] = useState<ViewType>(ViewType.grid);
  const [width, setWidth] = useState<number | undefined>();
  // const [selectedOption, setSelectedOption] = useState(
  //   (sortType && sortType) || 'salesVolume'
  // );
  const selectedOption = (sortType && sortType) || 'salesVolume';
  const handleDeleteDialogBlog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  // const options = [
  //   { label: 'Популярные', value: 'salesVolume' },
  //   { label: 'Сначала дешевые', value: 'priceUp' },
  //   { label: 'Сначала дорогие', value: 'priceDown' },
  //   { label: 'Высокий рейтинг', value: 'rating' },
  // ];
  const updateWidth = () => setWidth(window.innerWidth);

  const [favoritePublicatoins, setFavoritePublications] = useState<IPublication[] | null>(
    null
  );

  useEffect(() => {
    if (catalogCategories) {
      fetchProductsByCategory(catalogCategories);
    }
  }, [catalogCategories]);

  // const selectStyles = {
  //   container: (provided: any, state: any) => ({
  //     ...provided,
  //     marginRight: 10,
  //     width: '200px',
  //   }),
  //   menu: (provided: any, state: any) => ({
  //     ...provided,
  //     zIndex: 999,
  //     display: state.selectProps.isSearchable ? provided.display : 'none',
  //   }),
  //   control: (provided: any, state: any) => ({
  //     ...provided,
  //     borderColor: state.isFocused ? 'rgba(120,220,120,1)' : '',
  //     backgroundColor: 'white',
  //     // border: 'none !important',
  //     border: '1px solid #f5f8fa',
  //     opacity: state.isDisabled ? 0.5 : 1,
  //   }),
  //   option: (provided: any, state: any) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? 'rgba(54, 159, 247,0.5)' : '#fff',
  //   }),
  //   singleValue: (provided: any, state: any) => ({
  //     ...provided,
  //     // color: '#4c4c4c',
  //     color: '#4c4c4c',
  //     fontWeight: 600,
  //   }),
  //   placeholder: (provided: any, state: any) => ({
  //     ...provided,
  //     fontWeight: 600,
  //   }),
  //   dropdownIndicator: (provided: any, state: any) => {
  //     return {
  //       ...provided,
  //       display: state.selectProps.isSearchable ? provided.display : 'none',
  //     };
  //   },
  // };

  // useEffect(() => {
  // const storeViewType = localStorage.getItem('VIEW_TYPE') as ViewType;
  // if (storeViewType) {
  //   setViewType(storeViewType);
  // }
  // }, []);

  // const handleViewType = (type: ViewType) => {
  //   localStorage.setItem('VIEW_TYPE', type);
  //   setViewType(type);
  // };

  const filter = useMemo(() => {
    const data = [];
    const filterData: any = {};
    // eslint-disable-next-line guard-for-in
    for (const key in filterData) {
      data.push({ parameter: Number(key), ...filterData[key] });
    }
    if (data.length) {
      return { parameters: data };
    }
    return { parameters: [] };
  }, []);

  const changeHide = useCallback(() => {
    if (hideId) {
      const data = new FormData();
      data.append('active', '0');
      editHide({ id: hideId, data });
    }
  }, [hideId]);

  const handleDeleteDialog = useCallback((item: IProduct) => {
    if (item?.in_stock) {
      setHideId(item.id);
      setAlertStock(true);
    } else {
      setDeleteId(item.id);
      setAlertOpen(true);
      setAlertStock(false);
    }
  }, []);

  useEffect(() => {
    getPublications({ page: 1, perPage: 12 });
    setFavoritePublications(publications);
    updateWidth();
    fetchMe();
    window.addEventListener('resize', updateWidth);
    fetchMe();
    fetchBanners({ page: 1, perPage: 20 });
    fetchFavorites();
    fetchCatalogCategories({});
    return () => {
      clearPublications();
      clearAddProduct();
      clearMe();
      clearProducts();
      clearDel();
      setSearch(false);
    };
  }, []);

  useEffect(() => {
    selectedCategoryId !== null && search !== '' && setSelectedCategoryId(null);
  }, [search]);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    delProduct({ id: deleteId, page, perPage, filter });
  }, [deleteId, delProduct, perPage, page, filter]);

  const editAction = useCallback(
    (item: any) => {
      navigate(`/products/edit/${item.id}`);
    },
    [navigate]
  );

  const viewAction = useCallback(
    (item: any, type: any) => {
      if (item.url) {
        window.open(item.url, '_blank');
      } else {
        navigate(`/viewproduct/${item.product.id}`, { state: { type } });
      }
    },
    [navigate]
  );

  const viewActionProduct = useCallback(
    (item: any, type?: string) => {
      navigate(`/viewproduct/${item.id}`, { state: { type } });
    },
    [navigate]
  );

  const handleCartDialog = useCallback(
    (item: any) => {
      const stock = isBuyer || !me ? item.stock_info?.available_quantity : item.stock_quantity;
      if (isAuthorized && (!stock || stock < 0)) {
        enqueueSnackbar(intl.formatMessage({ id: 'CART.PRODUCTS.STOCK.ERROR' }), {
          variant: 'error',
        });
      } else if (isAuthorized && stock !== null) {
        addProductToCart({
          product_id: item.id ? item?.id : 0,
          count: 1,
        });
      } else if (!isAuthorized) {
        const countProduct = guestCart?.items.find(i => i.product.id === item.id)?.count || 0;
        if (!stock || countProduct === stock || countProduct > stock) {
          setAlertStockOver(true);
        } else {
          setProductGuestCart({ data: item, type: countProductsGuest === 0 ? 'new' : 'cart' });
        }
      }
    },
    [
      addProductToCart,
      cartCompanyId,
      companyGuestCart,
      setProductGuestCart,
      guestCart,
      me,
      isBuyer,
    ]
  );
  // Для  Галереи с выбором коллекции
  // useEffect(() => {
  //   if (selectCollection) {
  //     // setSearch(true);
  //     // clearParams();
  //     const params: any = {};
  //     const paramsArray: any = [];
  //     selectCollection.parameters.forEach(param => {
  //       if (param.filter_type) {
  //         params[param.parameter] = { values: param.values };
  //         paramsArray.push({
  //           parameter: param.parameter,
  //           values: param.values,
  //         });
  //       }
  //     });
  //     // setPriceTo(selectCollection.max_price?.toString() || '');
  //     // // setPriceFrom(selectCollection.min_price?.toString() || '');
  //     // setFilterData(params);
  //     // setNewType(selectCollection.product_type_id);
  //     fetchProducts({
  //       parameters: paramsArray,
  //       price_from: selectCollection.min_price?.toString() || '',
  //       price_to: selectCollection.max_price?.toString() || '',
  //     });
  //   }
  // }, [selectCollection]);
  //
  // const mapList = (item: any, locale: string) => ({
  //   value: item.id,
  //   label: item.names?.[locale] || item.name,
  //   item,
  // });
  useCrudSnackbar({
    success: addProductSuccess,
    error: addProductError,
    successMessage: intl.formatMessage({ id: 'CART.ADD_PRODUCT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${addProductError}`,
    afterSuccessOrError: () => {
      // setCartAlertOpen(false);
      // setCartProductId(null);
    },
  });

  useCrudSnackbar({
    success: editHideSuccess,
    error: editHideError,
    successMessage: intl.formatMessage({ id: 'PRODUCT.HIDE' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${addProductError}`,
    afterSuccessOrError: () => {
      fetch({
        page,
        perPage,
        filter,
        categoryId: selectedCategoryId || 0,
        sort_type: selectedOption,
      });
      clearEdit();
    },
  });

  useCrudSnackbar({
    success: delSuccess,
    error: deleteError,
    successMessage: intl.formatMessage({ id: 'PRODUCT.DELETED' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${addProductError}`,
    afterSuccessOrError: () => {
      setAlertOpen(false);
      clearDel();
      fetch({ page, perPage, filter, categoryId: selectedCategoryId || 0, sort_type: 'new' });
      fetchProductsForDays({ page, perPage });
    },
  });

  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(deleteError, { variant: 'error' });
      setAlertOpen(false);
      clearDel();
    }
    return () => {
      clearDel();
    };
  }, [deleteError]);

  useEffect(() => {
    if (!isSearch) {
      if (location?.state?.categoryId) {
        setSearch(true);
        setSelectedCategoryId(location.state.categoryId);
        fetch({ page, perPage, filter, categoryId: location.state.categoryId });
        navigate(location.pathname, {});
        // history.replace({ ...location, state: undefined });
      } else {
        fetch({
          page,
          perPage,
          filter,
          search: search || '',
          categoryId: selectedCategoryId || 0,
          sort_type: 'salesVolume',
        });
      }
    }
    fetchProductsForDays({ page, perPage });
    fetchType();
  }, [filter]);

  if (isAdmin) {
    setLayoutSubheader({
      title: '',
      show: true,
    });
  }

  setLayoutFooter({ show: true });

  // const filterClear = useCallback(() => {
  //   clearFilter();
  // }, [clearFilter]);

  // const [onChangeParam] = useCustomParams();
  // const resetProducts = useCallback(() => {
  //   fetch({ page: 1, perPage: 12, filter, categoryId: 0, sort_type: sortType });
  // }, [catalogCategories, filter, sortType]);

  // const resetProductTypes = useCallback(() => {
  //   clearParams();
  //   fetch({ page: 1, perPage: 12, filter, sort_type: sortType });
  // }, [productTypes, filter]);

  // const sortingProducts = useCallback(
  //   (value: string) => {
  //     fetch({
  //       page: 1,
  //       perPage,
  //       filter,
  //       search: search || '',
  //       categoryId: selectedCategoryId || 0,
  //       sort_type: value,
  //     });
  //   },
  //   [search]
  // );

  // let activeCount: number | undefined = 0;
  // if (isSearching) {
  //   activeCount = products?.reduce((count, el) => {
  //     if (Number(el.stock_info?.available_quantity) > 0) {
  //       // eslint-disable-next-line no-return-assign
  //       return count + 1;
  //     }
  //     return count;
  //   }, 0);
  // }
  // if (!isSearching) {
  //   activeCount = products?.reduce((count, el) => {
  //     if (Number(el.stock_info?.available_quantity) > 0) {
  //       // eslint-disable-next-line no-return-assign
  //       return count + 1;
  //     }
  //     return count;
  //   }, 0);
  // }

  // let paginationCount: any = productsCount;
  // if (isSearching) {
  //   if (isAdmin) {
  //     paginationCount = productsCount;
  //   } else if (activeCount !== undefined) {
  //     paginationCount = activeCount;
  //   }
  // }
  // if (!isSearching) {
  //   paginationCount = activeCount;
  // }

  if (loadingMe) {
    return <Preloader />;
  }
  return (
    <div className='main_catalog'>
      <AlertDialog
        open={alertStockOver}
        message={intl.formatMessage({ id: 'CART.PRODUCTS.STOCK.OVER' })}
        okText=''
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        handleClose={() => {
          setAlertStockOver(false);
        }}
        handleAgree={() => {
          setAlertStockOver(false);
        }}
      />
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'PRODUCT.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          clearDel();
          setAlertOpen(false);
        }}
        handleAgree={() => deleteAction()}
      />
      <AlertDialog
        open={isAlertStock}
        message={intl.formatMessage({ id: 'PRODUCT.DELETE.STOCK' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.INACTIVE' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setIsHideProduct(false);
          setAlertStock(false);
        }}
        handleAgree={() => {
          setIsHideProduct(true);
          changeHide();
          setAlertStock(false);
        }}
      />
      <div className={classes.container}>
        {/* <div className={`${classes.category_content} ${classes.container_wrap}`}> */}
        {/*   <div className={classes.category_card}> */}
        {/*     <div className={classes.count}>Категории товаров</div> */}
        {/*     <div> */}
        {/*       <FilterCategoriesTree */}
        {/*         categories={catalogCategories} */}
        {/*         resetProducts={resetProducts} */}
        {/*         searchProducts={fetch} */}
        {/*         filter={filter} */}
        {/*         loading={loading} */}
        {/*         setSearch={setSearch} */}
        {/*         selectedCategoryId={selectedCategoryId} */}
        {/*         setSelectedCategoryId={setSelectedCategoryId} */}
        {/*         sortType={sortType} */}
        {/*       /> */}
        {/*     </div> */}
        {/*   </div> */}
        {/* </div> */}

        <div
          className={clsx(classes.wrapContent, {
            [classes.revers]: isSearching,
            [classes.container_wrap]: true,
          })}
        >
          <div>
            {bannersMap && (
              <SlickSlider
                me={me}
                data={bannersMap.get(Placement.TOP) || []}
                viewAction={viewAction}
                isAdmin={!isAdmin}
              />
            )}
          </div>
          {/* <div */}
          {/*   className={clsx(classes.productsCol, { */}
          {/*     [classes.revers]: isSearching, */}
          {/*   })} */}
          {/* > */}
          {/* <div
                className={clsx(classes.productsCol, {
                  [classes.revers]: isSearching,
                 })}>
                 {loading ? (
                  <Preloader />
                 ) : (
                  <>
                    {!products || !products.length ? (
                      <Typography className={classes.empty} component='h5' variant='h5'>
                        {intl.formatMessage({ id: 'PRODUCT.CATALOG.ITEMSLIST.EMPTY' })}
                      </Typography>
                    ) : (
                      <div className={classes.card}>
                        <div className={classes.productColHeader} >
                          <div className={classes.productColHeaderItem}>
                            {isAdmin || me?.roles.includes(UserRoles.ROLE_VENDOR) ? (
                              <Typography variant='h6' className={classes.count}>
                                {isSearching
                                  ? `Товаров найдено: ${productsCount}`
                                  : 'Популярные'}
                              </Typography>
                            ) : (
                              <Typography variant='h6' className={classes.count}>
                                {isSearching
                                  ? `Товаров найдено: ${productsCount}`
                                  : 'Популярные'}
                              </Typography>
                            )}
                          </div>
                         </div>

                        <ProductsGrid
                          isSearching={isSearching}
                          addProductLoading={addProductLoading}
                          isAuthorized={isAuthorized}
                          isAdmin={!!isAdmin}
                          me={me}
                          handleCartDialog={handleCartDialog}
                          viewAction={viewAction}
                          editAction={editAction}
                          handleDeleteDialog={handleDeleteDialog}
                          edit={edit}
                          isHideProduct={isHideProduct}
                          data={products}
                          page={page}
                          perPage={perPage}
                          total={productsCount}
                          categoryId=''
                          companyId=''
                          fetch={({ page, perPage }) =>
                            fetch({
                              page,
                              perPage,
                              filter,
                              categoryId: selectedCategoryId || 0,
                            })
                          }
                        />
                      </div>
                    )}
                  </>
                )}
              </div> */}
          {/* </div> */}
        </div>

        <div className={`${classes.wrap_content} ${classes.container_wrap}`}>
          <div
            className={clsx(classes.productsCol, {
              [classes.revers]: isSearching,
            })}
          >
            {loading ? (
              <Preloader />
            ) : (
              <>
                {!products || !products.length ? (
                  // <Typography className={classes.empty} component='h5' variant='h5'>
                  //   {intl.formatMessage({ id: 'PRODUCT.CATALOG.ITEMSLIST.EMPTY' })}
                  // </Typography>
                  <></>
                ) : (
                  <div
                    className={clsx(classes.card, {
                      [classes.card1elm]: true,
                    })}
                  >
                    <div className={classes.productColHeader}>
                      <div className={classes.productColHeaderItem}>
                        {isAdmin || isVendor ? (
                          <Typography
                            variant='h6'
                            // sx={{
                            //   fontStyle: 'bold',
                            //   fontWeight: 700,
                            //   color: '#212328',
                            //   marginRight: 10,
                            //   fontSize: 32,
                            //   marginBottom: 5,
                            // }}

                            className={classes.count}
                          >
                            {isSearching
                              ? `${fm('ITEMS.FOUND')} ${productsCount}`
                              : fm('POPULAR')}
                          </Typography>
                        ) : (
                          <Typography
                            variant='h6'
                            // sx={{
                            //   fontStyle: 'bold',
                            //   fontWeight: 700,
                            //   color: '#212328',
                            //   marginRight: 10,
                            //   fontSize: 32,
                            //   marginBottom: 5,
                            // }}
                            className={classes.count}
                          >
                            {isSearching
                              ? `${fm('ITEMS.FOUND')} ${productsCount}`
                              : fm('POPULAR')}
                          </Typography>
                        )}
                      </div>
                    </div>
                    <ProductsGrid
                      isSearching={isSearching}
                      addProductLoading={addProductLoading}
                      isAuthorized={isAuthorized}
                      isAdmin={!!isAdmin}
                      me={me}
                      handleCartDialog={handleCartDialog}
                      viewAction={viewActionProduct}
                      editAction={editAction}
                      handleDeleteDialog={handleDeleteDialog}
                      edit={edit}
                      isHideProduct={isHideProduct}
                      data={products}
                      page={page}
                      perPage={perPage}
                      total={productsCount}
                      categoryId=''
                      companyId=''
                      fetch={({ page, perPage }) =>
                        fetch({
                          page,
                          perPage,
                          filter,
                          categoryId: selectedCategoryId || 0,
                        })
                      }
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {!productsForDays ||
          (productsForDays.length !== 0 && (
            <div className={classes.wrap_content_bottom}>
              <div
                className={clsx(classes.productsCol, {
                  [classes.revers]: isSearching,
                  [classes.container_wrap]: true,
                })}
              >
                {loading ? (
                  <Preloader />
                ) : (
                  <>
                    {!productsForDays ||
                      (productsForDays.length !== 0 && (
                        <div
                          className={clsx(classes.card, {
                            [classes.card1elm]: true,
                          })}
                        >
                          <div className={classes.productColHeader}>
                            <div className={classes.productColHeaderItem}>
                              {isAdmin || isVendor ? (
                                <Typography
                                  variant='h6'
                                  sx={{
                                    fontStyle: 'bold',
                                    fontWeight: 700,
                                    color: '#212328',
                                    marginRight: 10,
                                    fontSize: 32,
                                    marginBottom: 5,
                                  }}
                                >
                                  {isSearching
                                    ? `${fm('ITEMS.FOUND')} ${productsCount}`
                                    : fm('NOVELTY')}
                                </Typography>
                              ) : (
                                <Typography
                                  variant='h6'
                                  sx={{
                                    fontStyle: 'bold',
                                    fontWeight: 700,
                                    color: '#212328',
                                    marginRight: 10,
                                    fontSize: 32,
                                    marginBottom: 5,
                                  }}
                                >
                                  {isSearching
                                    ? `${fm('ITEMS.FOUND')} ${productsCount}`
                                    : fm('NOVELTY')}
                                </Typography>
                              )}
                            </div>
                          </div>
                          <ProductsGrid
                            isSearching={isSearching}
                            addProductLoading={addProductLoading}
                            isAuthorized={isAuthorized}
                            isAdmin={!!isAdmin}
                            me={me}
                            handleCartDialog={handleCartDialog}
                            viewAction={viewAction}
                            editAction={editAction}
                            handleDeleteDialog={handleDeleteDialog}
                            edit={edit}
                            isHideProduct={isHideProduct}
                            data={productsForDays}
                            page={page}
                            perPage={perPage}
                            total={productsCount}
                            categoryId=''
                            companyId=''
                            fetch={({ page, perPage }) =>
                              fetch({
                                page,
                                perPage,
                                filter,
                                categoryId: selectedCategoryId || 0,
                              })
                            }
                          />
                        </div>
                      ))}
                  </>
                )}
              </div>
            </div>
          ))}
        <div
          className={`${classes.wrap_content} ${classes.container_wrap}`}
          style={{
            marginTop: 32,
          }}
        >
          <div>
            {bannersMap && (
              <SlickSlider
                me={me}
                data={bannersMap.get(Placement.MID) || []}
                viewAction={viewAction}
                isAdmin={!isAdmin}
              />
            )}
          </div>
        </div>

        <div className={`${classes.wrap_content} ${classes.container_wrap}`}>
          <div
            className={clsx(classes.productsCol, {
              [classes.revers]: isSearching,
            })}
          >
            {loading ? (
              <Preloader />
            ) : (
              <>
                {!products || !products.length ? (
                  // <Typography className={classes.empty} component='h5' variant='h5'>
                  //   {intl.formatMessage({ id: 'PRODUCT.CATALOG.ITEMSLIST.EMPTY' })}
                  // </Typography>
                  <></>
                ) : (
                  <div
                    className={clsx(classes.card, {
                      [classes.card1elm]: true,
                    })}
                  >
                    {productsCategory.map(
                      ({ category, products: productsCategoryData }, index) => {
                        return (
                          <div style={{ marginBottom: 30 }} key={index.toString()}>
                            <div className={classes.productColHeader}>
                              <div className={classes.productColHeaderItem}>
                                <Typography
                                  variant='h6'
                                  sx={{
                                    fontStyle: 'bold',
                                    fontWeight: 700,
                                    color: '#212328',
                                    marginRight: 10,
                                    fontSize: 32,
                                    marginBottom: 5,
                                  }}
                                >
                                  {category.name}
                                </Typography>
                              </div>
                            </div>
                            <ProductsGrid
                              isSearching={isSearching}
                              addProductLoading={addProductLoading}
                              isAuthorized={isAuthorized}
                              isAdmin={!!isAdmin}
                              me={me}
                              handleCartDialog={handleCartDialog}
                              viewAction={viewActionProduct}
                              editAction={editAction}
                              handleDeleteDialog={handleDeleteDialog}
                              edit={edit}
                              isHideProduct={isHideProduct}
                              data={productsCategoryData}
                              page={page}
                              perPage={perPage}
                              total={productsCount}
                              categoryId=''
                              companyId=''
                              fetch={({ page, perPage }) =>
                                fetch({
                                  page,
                                  perPage,
                                  filter,
                                  categoryId: selectedCategoryId || 0,
                                })
                              }
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className={`${classes.wrap_content} ${classes.container_wrap}`}>
          <div
            className={clsx(classes.productsCol, {
              [classes.revers]: isSearching,
            })}
          >
            {loadingCollections ? (
              <Preloader />
            ) : (
              <>
                {!collections || !collections.length ? (
                  // <Typography className={classes.empty} component='h5' variant='h5'>
                  //   {intl.formatMessage({ id: 'PRODUCT.CATALOG.ITEMSLIST.EMPTY' })}
                  // </Typography>
                  <></>
                ) : (
                  <div
                    className={clsx(classes.card, {
                      [classes.card1elm]: true,
                    })}
                  >
                    {collections.map((collection: TCollection) => {
                      return collection.products.length ? (
                        <div style={{ marginBottom: 30 }} key={collection.id}>
                          <div className={classes.productColHeader}>
                            <div className={classes.productColHeaderItem}>
                              <Typography
                                variant='h6'
                                sx={{
                                  fontStyle: 'bold',
                                  fontWeight: 700,
                                  color: '#212328',
                                  marginRight: 10,
                                  fontSize: 32,
                                  marginBottom: 5,
                                }}
                              >
                                {collection.name}
                              </Typography>
                            </div>
                          </div>
                          <ProductsGrid
                            isSearching={isSearching}
                            addProductLoading={addProductLoading}
                            isAuthorized={isAuthorized}
                            isAdmin={!!isAdmin}
                            me={me}
                            handleCartDialog={handleCartDialog}
                            viewAction={viewActionProduct}
                            editAction={editAction}
                            handleDeleteDialog={handleDeleteDialog}
                            edit={edit}
                            isHideProduct={isHideProduct}
                            data={collection.products}
                            page={page}
                            perPage={perPage}
                            total={productsCount}
                            categoryId=''
                            companyId=''
                          />
                        </div>
                      ) : null;
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* Галерея с выбором коллекции  */}
        {/* <div className={`${classes.wrap_content} ${classes.container_wrap}`}> */}
        {/*  <div */}
        {/*    className={clsx(classes.productsCol, { */}
        {/*      [classes.revers]: isSearching, */}
        {/*    })} */}
        {/*  > */}
        {/*    {loadingCollections ? ( */}
        {/*      <Preloader /> */}
        {/*    ) : ( */}
        {/*      <> */}
        {/*        {!collections || !collections.length ? ( */}
        {/*          <></> */}
        {/*        ) : ( */}
        {/*          <div */}
        {/*            className={clsx(classes.card, { */}
        {/*              [classes.card1elm]: true, */}
        {/*            })} */}
        {/*          > */}
        {/*            <div style={{ marginBottom: 30 }}> */}
        {/*              <div className={classes.productColHeader}> */}
        {/*                <div className={classes.productColHeaderItem}> */}
        {/*                  <Typography variant='h6' className={classes.count}> */}
        {/*                    {intl.formatMessage({ id: 'COLLECTIONS' })} */}
        {/*                  </Typography> */}
        {/*                </div> */}
        {/*                <div className={classes.productSelectItem}> */}
        {/*                  <Select */}
        {/*                    placeholder={intl.formatMessage({ id: 'COLLECTIONS' })} */}
        {/*                    styles={selectStyles} */}
        {/*                    value={{ ...selected }} */}
        {/*                    onChange={event => { */}
        {/*                      if (event) { */}
        {/*                        setSelected({ value: event.value, label: event.label }); */}
        {/*                        // @ts-ignore */}
        {/*                        setSelectCollection(event.item as TCollection); */}
        {/*                      } */}
        {/*                    }} */}
        {/*                    options={ */}
        {/*                      collections */}
        {/*                        ? collections.map((item: any) => mapList(item, intl.locale)) */}
        {/*                        : [] */}
        {/*                    } */}
        {/*                    noOptionsMessage={() => 'Коллекции не найдены'} */}
        {/*                  /> */}
        {/*                </div> */}
        {/*              </div> */}
        {/*              {loadingProducts ? ( */}
        {/*                <Preloader /> */}
        {/*              ) : ( */}
        {/*                <> */}
        {/*                  {selected.value !== '' && !productsCollection.length ? ( */}
        {/*                    <Typography className={classes.empty} component='h5' variant='h5'> */}
        {/*                      {intl.formatMessage({ id: 'PRODUCT.CATALOG.LIST.EMPTY' })} */}
        {/*                    </Typography> */}
        {/*                  ) : ( */}
        {/*                    <ProductsGrid */}
        {/*                      isSearching={isSearching} */}
        {/*                      addProductLoading={addProductLoading} */}
        {/*                      isAuthorized={isAuthorized} */}
        {/*                      isAdmin={!!isAdmin} */}
        {/*                      me={me} */}
        {/*                      handleCartDialog={handleCartDialog} */}
        {/*                      viewAction={viewActionProduct} */}
        {/*                      editAction={editAction} */}
        {/*                      handleDeleteDialog={handleDeleteDialog} */}
        {/*                      edit={edit} */}
        {/*                      isHideProduct={isHideProduct} */}
        {/*                      data={productsCollection} */}
        {/*                      page={page} */}
        {/*                      perPage={perPage} */}
        {/*                      total={productsCount} */}
        {/*                      categoryId='' */}
        {/*                      companyId='' */}
        {/*                    /> */}
        {/*                  )} */}
        {/*                </> */}
        {/*              )} */}
        {/*            </div> */}
        {/*          </div> */}
        {/*        )} */}
        {/*      </> */}
        {/*    )} */}
        {/*  </div> */}
        {/* </div> */}

        <div className={`${classes.wrap_blog_content} ${classes.container_wrap}`}>
          <div className={classes.blog_card}>
            <Typography
              variant='h6'
              sx={{
                fontStyle: 'bold',
                fontWeight: 700,
                color: '#212328',
                marginRight: 10,
                fontSize: 32,
                marginBottom: 5,
              }}
            >
              {fm('READ.BLOG')}
            </Typography>

            <PublicationsList
              deletePost={handleDeleteDialogBlog}
              meId={me?.id || 0}
              companyId={me?.company?.id || undefined}
              publications={favoritePublicatoins || []}
              isAdmin={isAdmin}
              width={width}
              btnPub={false}
            />
          </div>
        </div>
        <div
          className={`${classes.wrap_content} ${classes.container_wrap}`}
          style={{
            marginTop: 32,
          }}
        >
          <div>
            {bannersMap && (
              <SlickSlider
                me={me}
                data={bannersMap.get(Placement.BOTTOM) || []}
                viewAction={viewAction}
                isAdmin={!isAdmin}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const connector = connect(
  (state: IAppState) => {
    return {
      products: state.productsCatalog.products,
      productsForDays: state.productForDays.productsForDays,
      loading: state.productsCatalog.loading,
      page: state.productsCatalog.page,
      perPage: state.productsCatalog.per_page,
      deleteError: state.productsCatalog.delError,
      productsCategory: state.categories.productsCategory,
      me: state.profile.me,
      loadingMe: state.profile.loading,
      addProductLoading: state.cart.addProductLoading,
      addProductSuccess: state.cart.addProductSuccess,
      addProductError: state.cart.addProductError,
      cartCompanyId: state.cart.companyId,
      isAuthorized: state.auth.user != null,
      companyGuestCart: state.cart.guestCart?.cartCompanyId,
      countProductsGuest: state.cart.guestCart?.items.length,
      guestCart: state.cart.guestCart,
      isAdmin: state.auth.user?.is_admin,
      catalogCategories: state.categories.catalogCategories,
      isSearching: state.productsCatalog.isSearching,
      search: state.productsCatalog.search,
      sortType: state.productsCatalog.sortType,
      productsCount: state.productsCatalog.total,
      productTypes: state.productTypes.productTypes,
      delSuccess: state.productsCatalog.delSuccess,
      editHideSuccess: state.products.editHideSuccess,
      editHideError: state.products.editHideError,
      publications: state.blog.publications,

      bannersMap: state.banners.bannersMap,
    };
  },
  {
    fetch: productCatalogActions.fetchRequest,
    clearProducts: productCatalogActions.clearProducts,
    clearFilter: productCatalogActions.clearFilter,
    delProduct: productCatalogActions.delRequest,
    clearDel: productCatalogActions.clearDel,
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
    edit: productActions.editRequest,
    editHide: productActions.editHideRequest,
    addProductToCart: cartActions.addProductRequest,
    setProductGuestCart: cartActions.setProductGuestCart,
    fetchCatalogCategories: categoriesActions.fetchCatalogCategories,
    fetchProductsForDays: productForDaysActions.fetchRequest,
    fetchFavorites: productCatalogActions.fetchProductsFavorites,
    setSearch: productCatalogActions.setSearch,
    fetchTypeById: productTypeActions.fetchParamsByIdRequest,
    fetchType: productTypeActions.fetchRequest,
    clearParams: productTypeActions.clearParams,
    clearAddProduct: cartActions.clearAddProduct,
    clearEdit: productActions.clearEdit,

    getPublications: blogActions.publicationsRequest,
    clearPublications: blogActions.clearFetchPublications,

    fetchBanners: bannersActions.bannersRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductList);
