import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Typography, Button, Card, CardContent } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import AlertDialog from '../../../components/other/Dialog/AlertDialog';
import Preloader from '../../../components/other/Preloader/Preloader';
import StoriesTable from '../../../components/tableComponents/Table/StoresTable';

import { IAppState } from '../../../store/rootDuck';
import { actions as storeActions } from '../../../store/ducks/store.duck';
import homeStyles from '../../../constants/homeStyles';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';

import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { ICompany } from '../../../interfaces/companies';
import CompanyBind from '../companies/CompanyBind';
import { useDefineUserRole } from '../../../hooks';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    card: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      width: 50,
      height: 50,
    },
  })
);

const StoreListPage: React.FC<TPropsFromRedux> = ({
  stores,
  fetch,
  page,
  perPage,
  total,
  deleteError,
  clearDel,
  delStore,
  loading,
  setEmpty,
  clearStores,

  me,
  fetchMe,
  loadingMe,
  successMe,
  clearMe,
}) => {
  const homeClasses = homeStyles();
  const classes = useStyles();
  const intl = useIntl();
  const navigate = useNavigate();
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const { enqueueSnackbar } = useSnackbar();
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [userCompany, setUserCompany] = useState<undefined | null | ICompany>(undefined);

  const handleDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    if (deleteId) {
      delStore({ page, perPage, id: deleteId });
    }
  }, [deleteId, perPage, page, delStore]);

  useEffect(() => {
    fetchMe();
    return () => {
      clearMe();
      clearStores();
    };
  }, []);

  useEffect(() => {
    if (me && !userCompany) {
      setUserCompany(me.company);
    }
  }, [successMe]);

  useEffect(() => {
    if (userCompany !== undefined && me) {
      if (me.is_admin) {
        fetch({ page, perPage });
      } else if (userCompany) {
        const companyId = userCompany.id;
        fetch({ page, perPage, companyId });
      } else {
        setEmpty();
      }
    }
  }, [fetch, perPage, page, userCompany, me]);

  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(deleteError, { variant: 'error' });
      setAlertOpen(false);
      clearDel();
    }
    return () => {
      clearDel();
    };
  }, [deleteError, clearDel, enqueueSnackbar]);

  setLayoutSubheader({
    title: intl.formatMessage({ id: 'MENU.CATALOG.STORES' }),
  });
  setLayoutFooter({ show: true });

  if (!me || loadingMe || !stores || loading) return <Preloader />;

  if (userCompany === null && !me.is_admin && !isManager) {
    return <CompanyBind />;
  }

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'STORE.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          clearDel();
          setAlertOpen(false);
        }}
        handleAgree={() => deleteAction()}
      />
      <Button color='primary' variant='contained' onClick={() => navigate('/stores/create')}>
        {intl.formatMessage({ id: 'STORE.BUTTON.ADD' })}
      </Button>
      <Row>
        <Col>
          {!stores || !stores.length ? (
            <Typography className={classes.empty} component='h5' variant='h5'>
              {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
            </Typography>
          ) : (
            <>
              <Card className={classes.card}>
                <CardContent className={homeClasses.classes.tableContainer}>
                  <StoriesTable
                    stores={stores}
                    loading={loading}
                    paginationData={{ page, perPage, total }}
                    onEdit={storeId => navigate(`/stores/edit/${storeId}`)}
                    onDelete={storeId => handleDeleteDialog(storeId)}
                    isAdmin={!!me.is_admin || !!isManager || false}
                    fetch={({ page, perPage }) =>
                      fetch({
                        page,
                        perPage,
                        companyId: me.is_admin ? undefined : userCompany?.id,
                      })
                    }
                  />
                </CardContent>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    stores: state.stores.stores,
    loading: state.stores.loading,
    page: state.stores.page,
    perPage: state.stores.per_page,
    total: state.stores.total,
    deleteError: state.stores.delError,

    me: state.profile.me,
    loadingMe: state.profile.loading,
    successMe: state.profile.success,
  }),
  {
    fetch: storeActions.fetchRequest,
    delStore: storeActions.delRequest,
    clearDel: storeActions.clearDel,
    setEmpty: storeActions.setEmpty,
    clearStores: storeActions.clearStores,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StoreListPage);
