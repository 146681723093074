/* eslint-disable no-plusplus, prefer-const */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Typography, Tooltip, Card } from '@mui/material';
import clsx from 'clsx';
import { Formik } from 'formik';
import { ImageGallery } from './components';
import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { actions as productActions } from '../../../store/ducks/product.duck';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { actions as cartTypesActions } from '../../../store/ducks/cart.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { actions as companiesActions } from '../../../store/ducks/companies.duck';
import { IProduct } from '../../../interfaces/product';
import { formatAsThousands } from '../../../utils/utils';
import { useStylesProducView } from './hooks/useStylesProductView';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import { useDefineUserRole, useFormatMessage } from '../../../hooks';
import { useCreateChat } from '../chats/chatsPage/hooks/useCreateChat';
import ReviewsTextComponent from './components/ReviewsTextComponent';
// import ReviewComponent from './components/ReviewComponent';
import { ILocation } from '../../../interfaces/locations';
import { ITypeParameter } from '../../../interfaces/productType';
import ProductIcons from '../../../components/ui/ProductIcons';
import { ProductsGrid } from '../main/components';
import { useStylesProductCatalog } from '../main/hooks/useStyles';
import { useGetSimilar } from './hooks/useGetSimilar';
import { useChangeFavorite } from './hooks/useChangeMyFavorite';
import ReviewComponent from './components/ReviewComponent';

const ProductView: React.FC<TPropsFromRedux> = ({
  product,
  loading,
  isAuthorized,
  // guestCart,
  // cart,
  // isAdmin,
  me,
  loadingMe,
  // editLoading,
  // cartCompanyId,
  // companyGuestCart,
  // countProductsGuest,
  addProductLoading,
  fetch,
  clearProduct,
  // setCountGuestCart,
  // setProductCount,
  // setProductCountLoading,
  fetchMe,
  clearMe,
  addProductToCart,
  setProductGuestCart,
  meAdd,
  companies,
  pageCompany,
  perPageCompany,
  fetchCompanies,
}) => {
  const intl = useIntl();
  const classes = useStylesProducView();
  const classesCatalog1 = useStylesProductCatalog();
  const classesCatalog = classesCatalog1.classes;
  const fm = useFormatMessage();
  const navigate = useNavigate();
  const { id } = useParams();
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  // const isVendor = useMemo(() => me && me.roles.includes(UserRoles.ROLE_VENDOR), [me]);
  const [isCartAlertOpen, setCartAlertOpen] = useState<boolean>(false);
  // const [files, setFiles] = useState<IFile[] | undefined>([]);
  // const [changeFavorite, loadingChange, successChange] = useChangeFavorite();
  const { loadingCreated } = useCreateChat();
  const [fetchSimilar, loadingSimilar, products] = useGetSimilar();
  const [alertStockOver, setAlertStockOver] = useState(false);
  const [changeFavorite, loadingChange] = useChangeFavorite();
  const groupsParams = useMemo(() => {
    const groups: { id: number; name: string; params: ITypeParameter[] }[] = [];
    if (product && product.parameter_values) {
      product.parameter_values.forEach(item => {
        if (item.group_id) {
          const isExist = groups.find(i => i.id === item.group_id);
          if (isExist) {
            const index = groups.findIndex(i => i.id === item.group_id);
            groups[index].params.push(item);
          } else {
            groups.push({
              id: item.group_id,
              name: item.group_name || '',
              params: [item],
            });
          }
        }
      });
    }
    return groups;
  }, [product]);

  const companyProduct = useMemo(
    () => companies.find(company => company.id === product?.company?.id),
    [companies, product]
  );
  const viewActionProduct = useCallback(
    (item: any, type?: string) => {
      navigate(`/viewproduct/${item.id}`, { state: { type } });
    },
    [navigate]
  );
  const location = useMemo<ILocation | undefined | null>(() => {
    const localLocation = localStorage.getItem('location');
    const parseLocalLocation = localLocation && JSON.parse(localLocation);
    return parseLocalLocation as ILocation;
  }, []);
  setLayoutSubheader({
    title: product?.name || '',
    breadcrumb: [
      {
        title: fm('MENU.PRODUCTS.CATALOG'),
        root: true,
        page: 'products/catalog',
        translate: 'MENU.PRODUCTS.CATALOG',
      },
    ],
    back: true,
  });
  setLayoutFooter({ show: true });

  useEffect(() => {
    fetch({ id: Number(id) });
    return () => {
      clearProduct();
    };
  }, [id]);
  useEffect(() => {
    fetchMe();
    fetchCompanies({
      page: pageCompany,
      perPage: perPageCompany,
      lng: location?.lng,
      lat: location?.lat,
    });
    return () => {
      clearMe();
    };
  }, []);
  // useLayoutEffect(() => {
  //   successChange && fetch(Number(id));
  // }, [successChange]);

  // handle add to cart
  const cartProductId: IProduct | null = null;
  // const [cartProductId, setCartProductId] = useState<IProduct | null>(null);

  useEffect(() => {
    fetchMe();
    return () => {
      clearMe();
    };
  }, []);
  useEffect(() => {
    fetchSimilar(id);
  }, [id]);
  const getTranslateParamValue = useCallback(
    (item: ITypeParameter) => {
      if (product && item.value) {
        if (Array.isArray(item.value)) {
          if (item.parameter_enum_values && item.parameter_enum_values.find(i => i.image)) {
            const values: { title: string; image: string }[] = [];
            item.value.forEach(i => {
              const paramEnum = item.parameter_enum_values?.find(
                paramEnum => paramEnum.value === i
              );
              const image = paramEnum?.image || '';
              if (paramEnum) {
                values.push({
                  title: paramEnum?.translations?.[intl.locale] || i,
                  image,
                });
              } else {
                values.push({ title: i, image });
              }
            });
            return values;
          }
          const values: string[] = [];
          item.value.forEach(i => {
            const paramEnum = item.parameter_enum_values?.find(
              paramEnum => paramEnum.value === i
            );
            if (paramEnum) {
              values.push(paramEnum?.translations?.[intl.locale] || i);
            } else {
              values.push(i);
            }
          });
          return values;
        }
        const paramEnum = item.parameter_enum_values?.find(
          paramEnum => paramEnum.value === item.value
        );
        return paramEnum?.translations?.[intl.locale] || item.value;
      }
      return item.value;
    },
    [product, intl.locale]
  );

  const characteristics = useMemo(() => {
    if (product) {
      const result: { [key: string]: any } = {
        [intl.formatMessage({ id: 'PRODUCT.VIEW.CATEGORY' })]:
          intl.locale === 'ru'
            ? product.category?.name || intl.formatMessage({ id: 'COMMON.NO_DATA' })
            : // @ts-ignore
              product.category?.names?.[`name_${intl.locale}`] ||
              product.category?.name ||
              intl.formatMessage({ id: 'COMMON.NO_DATA' }),
      };

      product.parameter_values?.forEach(item => {
        const name = item.names?.[intl.locale] || item.parameter_name;
        if (item.parameter_id && name && !item.group_id) {
          // запрос не возвращает название параметра
          result[name] = getTranslateParamValue(item);
        }
      });
      return result;
    }
    return {};
  }, [product, getTranslateParamValue]);

  const isCompanyInfo = useMemo(() => {
    return (
      product?.company?.brand ||
      // product.company?.address ||
      product?.company?.phone_number ||
      product?.company?.site
      // || product.company?.working_hours
    );
  }, [product?.company]);

  const companyInfo: { [key: string]: any } | null = isCompanyInfo
    ? {
        [intl.formatMessage({ id: 'PRODUCT.VIEW.COMPANY' })]: product?.company?.brand || null,
        // Адрес: product.company?.address || null,
        [intl.formatMessage({ id: 'PRODUCT.VIEW.PHONE_NUMBER' })]:
          product?.company?.phone_number.replace(/[()]/g, '') || null,
        [intl.formatMessage({ id: 'PRODUCT.VIEW.SITE' })]: product?.company?.site || null,
        [intl.formatMessage({ id: 'PRODUCT.VIEW.DISTANCE' })]:
          companyProduct?.nearest_store &&
          typeof companyProduct?.nearest_store?.distantion === 'number' &&
          companyProduct?.nearest_store?.distantion >= 0
            ? `${(companyProduct?.nearest_store?.distantion).toFixed(2)} км`
            : null,
        // 'Часы работы': product.company?.working_hours
        //   ? product.company?.working_hours.replace(/(\r\n|\n|\r)/gm, '<br>')
        //   : null,
      }
    : null;

  // let productCount = useMemo(() => {
  //   if (!isAuthorized && product && guestCart) {
  //     const item = guestCart.items.find(item => item.product.id === product.id);
  //     if (item) {
  //       return item.count;
  //     }
  //   } else if (Boolean(isAuthorized) && product && cart) {
  //     const item = cart.items.find(item => item.product.id === product.id);
  //     if (item) {
  //       return item.count;
  //     }
  //   }
  //   return null;
  // }, [isAuthorized, product, guestCart?.goods_num, cart?.goods_num, guestCart, cart]);

  // const handleCartDialog = useCallback(
  //   item => {
  //     // const stock = isBuyer || !me ? item.stock_info?.available_quantity : item.stock_quantity;
  //     if (isAuthorized) {
  //       const newCart = cartCompanyId ? item?.company?.id !== cartCompanyId : false;
  //       if (!newCart) {
  //         addProductToCart({
  //           product_id: item.id ? item?.id : 0,
  //           count: 1,
  //         });
  //       } else {
  //         setCartProductId(item);
  //         setCartAlertOpen(true);
  //       }
  //     } else {
  //       // const countProduct = guestCart?.items.find(i => i.product.id === item.id)?.count || 0;
  //       const newCart = companyGuestCart ? item?.company?.id !== companyGuestCart : false;
  //       // if (!stock || countProduct === stock || countProduct > stock) {
  //       //   setAlertStockOver(true);
  //       // } else
  //       if (!newCart) {
  //         setProductGuestCart({ data: item, type: 'cart' });
  //       } else if (countProductsGuest === 0) {
  //         setProductGuestCart({ data: item, type: 'new' });
  //       } else {
  //         setCartProductId(item);
  //         setCartAlertOpen(true);
  //       }
  //     }
  //   },
  //   [
  //     addProductToCart,
  //     cartCompanyId,
  //     companyGuestCart,
  //     setProductGuestCart,
  //     guestCart,
  //     isBuyer,
  //     me,
  //   ]
  // );

  const addCartProductAction = useCallback(() => {
    if (!addProductLoading && cartProductId) {
      addProductToCart({
        // @ts-ignore
        product_id: cartProductId.id,
        count: 1,
        newCart: true,
      });
      setCartAlertOpen(false);
    }
  }, [cartProductId, addProductToCart]);

  const addProductGuestCart = useCallback(() => {
    if (cartProductId) {
      setProductGuestCart({ data: cartProductId, type: 'new' });
      setCartAlertOpen(false);
    }
  }, [cartProductId, setProductGuestCart]);

  // const newChat = useCallback((userId?: string | number) => {
  //   createChatFetch({ userId });
  // }, []);

  // useEffect(() => {
  //   if (product) {
  //     setFiles(
  //       product?.attachments?.map(({ id, path, title, size }) => ({
  //         id,
  //         path,
  //         title,
  //         size,
  //       }))
  //     );
  //   }
  // }, [product]);

  // const [uploadFile, deleteFile] = handleProductFiles(product);

  if (loading || loadingMe || !product || loadingCreated) return <Preloader />;
  return (
    <>
      <AlertDialog
        open={alertStockOver}
        message={intl.formatMessage({ id: 'CART.PRODUCTS.STOCK.OVER' })}
        okText=''
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        handleClose={() => {
          setAlertStockOver(false);
        }}
        handleAgree={() => {
          setAlertStockOver(false);
        }}
      />
      <AlertDialog
        open={isCartAlertOpen}
        message={intl.formatMessage({ id: 'CART.ALERT.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setCartAlertOpen(false);
        }}
        handleAgree={() => (isAuthorized ? addCartProductAction() : addProductGuestCart())}
      />
      <div className={classes.container}>
        <Card className={classes.card}>
          <div className={classes.leftCol}>
            <ImageGallery product={product} />

            <div style={{ marginTop: '50px' }}>
              {(product.translations?.[intl.locale]?.description || product.description) && (
                <p
                  className={classes.description}
                  dangerouslySetInnerHTML={{
                    __html:
                      product.translations?.[intl.locale]?.description || product.description,
                  }}
                />
              )}
            </div>
            <div style={{}} />
          </div>

          <div className={classes.rightCol}>
            <div className={classes.header}>
              <div>
                <p className={classes.name}>
                  <b>{product.translations?.[intl.locale]?.name || product.name || '-'}</b>
                </p>

                <p className={classes.price}>{`${formatAsThousands(product.price)} ${fm(
                  'RUBL'
                )}`}</p>
              </div>

              <Formik
                initialValues={{ test: 'test' }}
                // onSubmit={() => handleCartDialog(product)}
                onSubmit={() => {}}
              >
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    {isBuyer && (
                      <>
                        {/* <ButtonWithLoader */}
                        {/*  style={{ margin: '0 10px' }} */}
                        {/*  disabled={loadingChange} */}
                        {/*  loading={loadingChange} */}
                        {/*  onPress={() => changeFavorite(product.id, product.favorite)} */}
                        {/* > */}
                        {/*  {intl.formatMessage({ */}
                        {/*    id: product.favorite */}
                        {/*      ? 'PRODUCT.DELETE.FAVORITE' */}
                        {/*      : 'PRODUCT.ADD.FAVORITE', */}
                        {/*  })} */}
                        {/* </ButtonWithLoader> */}

                        {product?.favorite ? (
                          <div
                            onClick={() => {
                              !loadingChange && changeFavorite(product.id, true);
                              //  setActiveSubscribes(!activeSubscribes);
                            }}
                          >
                            <Tooltip
                              title={intl.formatMessage({
                                id: 'PRODUCT.DELETE.FAVORITE',
                              })}
                            >
                              <img
                                alt=''
                                src='/images/heartActive.svg'
                                style={{
                                  marginLeft: 15,
                                }}
                              />
                            </Tooltip>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              !loadingChange && changeFavorite(product.id, false);
                              // setActiveSubscribes(!activeSubscribes);
                            }}
                          >
                            <Tooltip
                              title={intl.formatMessage({
                                id: 'PRODUCT.ADD.FAVORITE',
                              })}
                            >
                              <img
                                alt=''
                                src='/images/heart.svg'
                                style={{
                                  marginLeft: 15,
                                }}
                              />
                            </Tooltip>
                          </div>
                        )}
                      </>
                    )}
                    {/*      {!productCount */}
                    {/*        ? !isAdmin && */}
                    {/*        !isVendor && ( */}
                    {/*          <ButtonWithLoader disabled={editLoading} loading={editLoading}> */}
                    {/*            {intl.formatMessage({ id: 'PRODUCT.BUTTON.ADD_CART' })} */}
                    {/*          </ButtonWithLoader> */}
                    {/*        ) */}
                    {/*        : product && */}
                    {/*        Boolean(productCount) && ( */}
                    {/*          <ButtonGroup */}
                    {/*            color='primary' */}
                    {/*            aria-label='outlined primary button group' */}
                    {/*            variant='contained' */}
                    {/*          > */}
                    {/*            <Button */}
                    {/*              disabled={setProductCountLoading} */}
                    {/*              onClick={() => */}
                    {/*                isAuthorized */}
                    {/*                  ? setProductCount({ */}
                    {/*                    product_id: product.id || 0, */}
                    {/*                    count: --productCount!, */}
                    {/*                  }) */}
                    {/*                  : setCountGuestCart({ */}
                    {/*                    product_id: product.id || 0, */}
                    {/*                    count: --productCount!, */}
                    {/*                    type: 'dec', */}
                    {/*                  }) */}
                    {/*              } */}
                    {/*            > */}
                    {/*              <RemoveIcon fontSize='small' /> */}
                    {/*            </Button> */}
                    {/*            <Button */}
                    {/*              style={{ */}
                    {/*                pointerEvents: 'none', */}
                    {/*              }} */}
                    {/*            > */}
                    {/*              {productCount} */}
                    {/*            </Button> */}
                    {/*            <Button */}
                    {/*              disabled={setProductCountLoading} */}
                    {/*              onClick={() => */}
                    {/*                isAuthorized */}
                    {/*                  ? setProductCount({ */}
                    {/*                    product_id: product.id || 0, */}
                    {/*                    count: ++productCount!, */}
                    {/*                  }) */}
                    {/*                  : setCountGuestCart({ */}
                    {/*                    product_id: product.id || 0, */}
                    {/*                    count: ++productCount!, */}
                    {/*                    type: 'inc', */}
                    {/*                  }) */}
                    {/*              } */}
                    {/*            > */}
                    {/*              <AddIcon fontSize='small' /> */}
                    {/*            </Button> */}
                    {/*          </ButtonGroup> */}
                    {/*        )} */}
                  </form>
                )}
              </Formik>
            </div>
            {Object.keys(characteristics).map(key => (
              <>
                {characteristics[key] && characteristics[key] !== 'null' && (
                  <>
                    {typeof characteristics[key]?.[0] === 'object' ? (
                      <div key={key} className={classes.listItem}>
                        <div className={classes.containerIcons}>
                          {characteristics[key].map((el: any) => (
                            <div className={classes.containerIcon}>
                              <ProductIcons src={el.image} nameView title={el.title} />
                            </div>
                          ))}
                        </div>
                        <p className={classes.listKey}>{key}</p>
                      </div>
                    ) : (
                      <div key={key} className={classes.listItem}>
                        <p className={classes.listValue}>{characteristics[key].toString()}</p>
                        <p className={classes.listKey}>{key}</p>
                      </div>
                    )}
                  </>
                )}
              </>
            ))}

            {companyInfo && (
              <div className={classes.companyCard}>
                {Object.keys(companyInfo).map(key => {
                  return (
                    <>
                      {companyInfo[key] && (
                        <div key={key} className={classes.listItem}>
                          {key === 'Номер телефона' ? (
                            <a
                              href={`tel:${companyInfo[key]}`}
                              className={
                                key === 'Номер телефона'
                                  ? classes.listValueImportant
                                  : classes.listValue
                              }
                              dangerouslySetInnerHTML={{ __html: companyInfo[key] }}
                            />
                          ) : (
                            <p
                              className={
                                key === 'Сайт' ? classes.listValueImportant : classes.listValue
                              }
                              dangerouslySetInnerHTML={{ __html: companyInfo[key] }}
                            />
                          )}
                          <p className={classes.listKey}>{key}</p>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            )}

            {/* {product.company && product.company.phone_number && ( */}
            {/*  <a */}
            {/*    rel='noreferrer' */}
            {/*    target='_blank' */}
            {/*    href={`https://wa.me/${cleanPhone(product.company.phone_number)}`} */}
            {/*    className={classes.whatsAppButton} */}
            {/*  > */}
            {/*    {intl.formatMessage({ id: 'PRODUCT.VIEW.WHATSAPP' })} */}
            {/*  </a> */}
            {/* )} */}
          </div>
          {groupsParams.length > 0 && (
            <div className={classes.leftCol}>
              <div className={classes.containerGroups}>
                {groupsParams.map(i => (
                  <div className={classes.containerGroups} key={i.id.toString()}>
                    <div className={classes.groupTitle}>{i.name}</div>
                    <div className={classes.groupParams}>
                      {i.params.map(param => (
                        <>
                          {typeof getTranslateParamValue(param)?.[0] === 'object' ? (
                            <div className={classes.listItem}>
                              <div className={classes.containerIcons}>
                                {getTranslateParamValue(param).map((el: any) => (
                                  <div className={classes.containerIcon}>
                                    <ProductIcons src={el.image} nameView title={el.title} />
                                  </div>
                                ))}
                              </div>
                              <p className={classes.listKey}>
                                {param.names?.[intl.locale] || param.parameter_name}
                              </p>
                            </div>
                          ) : (
                            <div className={classes.listItemGroup}>
                              <p className={classes.listValue}>
                                {getTranslateParamValue(param).toString()}
                              </p>
                              <p className={classes.listKey}>
                                {param.names?.[intl.locale] || param.parameter_name}
                              </p>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className={classes.reviewsContent}>
            <div className={classes.leftCol}>
              <ReviewsTextComponent product={product} />
            </div>

            <div className={classes.rightCol}>
              <ReviewComponent product={product} meAdd={meAdd} />
            </div>
          </div>
        </Card>
        {loadingSimilar ? (
          <Preloader />
        ) : (
          <>
            {!products || !products.length ? (
              <></>
            ) : (
              <div
                className={clsx(classesCatalog.card, {
                  [classesCatalog.card1elm]: true,
                })}
              >
                <div className={classesCatalog.productColHeader}>
                  <div className={classesCatalog.productColHeaderItem}>
                    <Typography variant='h6' className={classesCatalog.count}>
                      {fm('PRODUCT.SIMILAR')}
                    </Typography>
                  </div>
                </div>
                <ProductsGrid
                  addProductLoading={addProductLoading}
                  isAuthorized={isAuthorized}
                  isAdmin={false}
                  me={me}
                  viewAction={viewActionProduct}
                  data={products}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    product: state.products.product,
    loading: state.products.byIdLoading,
    companies: state.companies.companies,
    pageCompany: state.companies.page,
    perPageCompany: state.companies.per_page,
    meAdd: state.products.reviewsProduct?.meAdd || false,
    isAuthorized: state.auth.user != null,
    guestCart: state.cart.guestCart,
    cart: state.cart.cart,
    setProductCountLoading: state.cart.setProductCountLoading,
    isAdmin: state.auth.user?.is_admin,
    me: state.profile.me,
    loadingMe: state.profile.loading,
    editLoading: state.products.editLoading,
    cartCompanyId: state.cart.companyId,
    companyGuestCart: state.cart.guestCart?.cartCompanyId,
    countProductsGuest: state.cart.guestCart?.items.length,
    addProductLoading: state.cart.addProductLoading,
    byIdSuccess: state.products.byIdSuccess,
  }),
  {
    fetchCompanies: companiesActions.fetchRequest,
    fetch: productActions.fetchByIdRequest,
    clearProduct: productActions.clearProduct,
    setProductGuestCart: cartTypesActions.setProductGuestCart,
    setCountGuestCart: cartTypesActions.setCountGuestCart,
    setProductCount: cartTypesActions.setProductCountRequest,
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
    addProductToCart: cartTypesActions.addProductRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductView);
