import { useFormik } from 'formik';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { countLettersInString } from '../../../utils/utils';

export const useFormikCheckInSystem = (
  initEmail: string,
  initPhone: string,
  makeRequest: any,
  tab: number,
  countryData: any
) => {
  const intl = useIntl();
  const validPhoneLength = useMemo(() => {
    if (!countryData) return null;
    return countLettersInString(countryData.mask, '*');
  }, [countryData]);

  const validationSchema = useMemo(() => {
    let shape = null;
    if (tab === 0) {
      shape = {
        email: Yup.string().required(
          intl.formatMessage({ id: 'PROFILE.VALIDATION.REQUIRED_FIELD' })
        ),
      };
    } else {
      shape = {
        phone: Yup.string()
          .test(
            'check if phone length valid',
            intl.formatMessage({ id: 'NOT_VALID_PHONE' }),
            value => {
              if (!value) return false;
              if (value && validPhoneLength && value.length !== validPhoneLength) {
                return false;
              }
              return true;
            }
          )
          .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })),
      };
    }
    return Yup.object().shape(shape);
  }, [tab, validPhoneLength, intl]);

  const formik = useFormik({
    enableReinitialize: true,
    // validateOnChange: false,
    // validateOnBlur: false,
    initialValues: {
      email: initEmail,
      phone: initPhone,
    },
    validationSchema,
    onSubmit: submitValues => {
      makeRequest({ ...submitValues, phone: countryData.code + submitValues.phone }, tab);
    },
  });

  // clear phone value when country changed
  // useEffect(() => {
  //   formik.setFieldValue('phone', '');
  // }, [countryData]);

  return formik;
};
