import { makeStyles } from 'tss-react/mui';
import { darken } from '@mui/material';

export const useStylesSearch = makeStyles()(theme => {
  return {
    textFieldWrapper: {
      width: '100%',
      // maxWidth: 'calc(100% - 326px - 30px)',
      minWidth: 'calc(326px)',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        minWidth: '100%',
        marginTop: 15,
        marginBottom: 15,
      },
    },
    textFieldWrapperSubHeader: {
      width: '100%',
      maxWidth: 'calc(100%)',
      display: 'flex',
      alignItems: 'center',
      margin: '0 20px',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        marginTop: 15,
        marginBottom: 15,
        margin: '0 20px',
      },
    },
    textField: {
      width: '100%',
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 0,
        color: '#002626',
        fontWeight: 500,
      },
      '& .MuiButton-root': {
        borderRadius: '0 4px 4px 0',
      },
      '& .MuiButton-containedSizeLarge': {
        padding: '7px 22px',
      },
      '& .MuiButton-contained': {
        boxShadow: 'none',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: darken(theme.palette.primary.main, 0.3),
      },
    },
    clear: {
      // padding: '0 10px 0 10px',
      fontWeight: 'bold',
      color: '#b5b5c3',
      cursor: 'pointer',
    },
    home: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: 20,
      cursor: 'pointer',
    },
    homeText: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    wrapperSearch: {
      display: 'flex',
      width: '100%',
      '& .MuiAutocomplete-hasPopupIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root':
        {
          paddingRight: 30,
        },
    },
    isSubheader: {
      height: 34,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    isNoneSubheader: {
      maxWidth: 42,
      minWidth: 42,
      margin: 0,
      padding: 0,
      height: 34,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  };
});
