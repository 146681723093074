import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ClearIcon from '@mui/icons-material/Clear';

import homeStyles from '../../../../constants/homeStyles';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import { ButtonWithLoader } from '../../../../components/ui/Buttons';
import { UploadImages } from '../../../../components/ui/UploadImages';
import { ICategoryItem, ICategoryParent } from '../interfaces';
import { UploadImagesOffline } from '../../../../components/ui/UploadImagesOffline';
import { IUser } from '../../../../interfaces/user';
import { categoryLanguages } from '../utils/categoryLanguages';
import { useStylesCategoryForm } from '../hooks/useStyles';
import { IProductType } from '../../../../interfaces/productType';
// import { homepage } from '../../../../../../package.json';

interface IProps {
  formik: any;
  parents: ICategoryParent[];
  id: string;
  category?: ICategoryItem;
  handleUpload: (file: File, categoryId?: number) => void;
  images: any[];
  setImages: any;
  editLoading: boolean;
  categories?: ICategoryItem[];
  me?: IUser;
  categoryLanguage: string;
  setLanguage: (value: string) => void;
  types: IProductType[];
}

const levelsSubCategories = [1, 2, 3, 4, 5];

export const CategoryForm: React.FC<IProps> = ({
  formik,
  // parents,
  id,
  category,
  images,
  setImages,
  editLoading,
  handleUpload,
  categories,
  me,
  categoryLanguage,
  setLanguage,
  types,
}) => {
  const fm = useFormatMessage();
  const navigate = useNavigate();
  const classes1 = useStylesCategoryForm();
  const { classes } = classes1;
  const homeClasses = homeStyles();
  const [subCategories, setSubCategories] = useState<ICategoryItem[][]>([]);
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const intl = useIntl();

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } =
    formik;

  const getSubCategories = useCallback(
    (level: number) => {
      return subCategories[level];
    },
    [subCategories]
  );

  useEffect(() => {
    if (values.mainCategoryId && categories) {
      const newCategories = categories.find(
        item => item.id?.toString() === values.mainCategoryId.toString()
      );
      if (values.mainCategoryId === -1) {
        setFieldValue(`categoryId_1`, '');
        setFieldValue(`categoryId_2`, '');
        setFieldValue(`categoryId_3`, '');
        setFieldValue(`categoryId_4`, '');
        setFieldValue(`categoryId_5`, '');
      }
      if (newCategories && newCategories.children) {
        if (
          subCategories[1] &&
          subCategories[1].length > 0 &&
          subCategories[1][0]?.id !== newCategories.children[0]?.id
        ) {
          levelsSubCategories.forEach(level => setFieldValue(`categoryId_${level}`, ''));
        }
        const subCategoriesLvl1 = newCategories.children;
        const subCategoriesLvl2: ICategoryItem | undefined =
          values.categoryId_1 &&
          subCategoriesLvl1?.length &&
          newCategories.children.find(
            item => item.id?.toString() === values.categoryId_1?.toString()
          );
        const subCategoriesLvl3: ICategoryItem | undefined =
          values.categoryId_2 &&
          subCategoriesLvl2?.children?.length &&
          subCategoriesLvl2.children.find(
            item => item.id?.toString() === values.categoryId_2?.toString()
          );
        const subCategoriesLvl4: ICategoryItem | undefined =
          values.categoryId_3 &&
          subCategoriesLvl3?.children?.length &&
          subCategoriesLvl3.children.find(
            item => item.id?.toString() === values.categoryId_3?.toString()
          );
        const subCategoriesLvl5: ICategoryItem | undefined =
          values.categoryId_4 &&
          subCategoriesLvl4?.children?.length &&
          subCategoriesLvl4.children.find(
            item => item.id?.toString() === values.categoryId_4?.toString()
          );
        if (subCategoriesLvl1.length === 0) {
          setFieldValue(`categoryId_1`, '');
          setFieldValue(`categoryId_2`, '');
          setFieldValue(`categoryId_3`, '');
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl2?.children?.length === 0) {
          setFieldValue(`categoryId_2`, '');
          setFieldValue(`categoryId_3`, '');
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl3?.children?.length === 0) {
          setFieldValue(`categoryId_3`, '');
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl4?.children?.length === 0) {
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl5?.children?.length === 0) {
          setFieldValue(`categoryId_5`, '');
        }
        setSubCategories(state => {
          const newArr = [...state];
          newArr[1] = subCategoriesLvl1.length ? subCategoriesLvl1 : [];
          newArr[2] = subCategoriesLvl2?.children?.length ? subCategoriesLvl2?.children : [];
          newArr[3] = subCategoriesLvl3?.children?.length ? subCategoriesLvl3?.children : [];
          newArr[4] = subCategoriesLvl4?.children?.length ? subCategoriesLvl4?.children : [];
          newArr[5] = subCategoriesLvl5?.children?.length ? subCategoriesLvl5?.children : [];
          return newArr;
        });
      } else {
        setSubCategories([]);
      }
    } else {
      setSubCategories([]);
    }
  }, [
    categories,
    values.mainCategoryId,
    values.categoryId_1,
    values.categoryId_2,
    values.categoryId_3,
    values.categoryId_4,
    values.categoryId_5,
  ]);

  return (
    <>
      {category && (
        <div className={classes.categoryLink}>
          <Typography>{`${fm('LINK.CATEGORY')}`}</Typography>
          &nbsp;
          <Link to={`/categories/view/${category.id}`}>
            {`/categories/view/${category.id}`}
          </Link>
        </div>
      )}
      <div className={classes.formEdit}>
        <div className={classes.cardEdit}>
          <TextField
            type='text'
            label={`${fm('CATEGORY.EDIT.NAME')} (RU)`}
            margin='normal'
            name='name'
            color='secondary'
            value={values.name}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.name && errors.name}
            error={Boolean(touched.name && errors.name)}
            disabled={isManager || false}
          />

          <TextField
            select
            label={fm('CATEGORY.EDIT.PARENT')}
            margin='normal'
            color='secondary'
            name='mainCategoryId'
            value={values.mainCategoryId || ''}
            variant='outlined'
            onBlur={handleBlur('mainCategoryId')}
            onChange={handleChange}
            disabled={isManager || false}
            helperText={touched.mainCategoryId && errors.mainCategoryId}
            error={Boolean(touched.mainCategoryId && errors.mainCategoryId)}
          >
            <MenuItem color='secondary' key='-1' value={-1}>
              {fm('CATEGORY.EDIT.ROOT')}
            </MenuItem>
            {categories &&
              categories.map(item => (
                <MenuItem
                  color='secondary'
                  key={item.id?.toString()}
                  value={item.id?.toString()}
                >
                  {item.name}
                </MenuItem>
              ))}
          </TextField>

          {levelsSubCategories.map(level => {
            const subCategories = getSubCategories(level);
            if (subCategories && subCategories?.length > 0) {
              return (
                <TextField
                  select
                  label={`${fm('PRODUCT.EDIT.SUBCATEGORY')} ${level}`}
                  margin='normal'
                  color='secondary'
                  name={`categoryId_${level}`}
                  value={values[`categoryId_${level}`] || ''}
                  variant='outlined'
                  onBlur={handleBlur(`categoryId_${level}`)}
                  onChange={handleChange}
                  disabled={!values.mainCategoryId || isManager || false}
                  helperText={touched[`categoryId_${level}`] && errors.categoryId}
                  error={Boolean(touched[`categoryId_${level}`] && errors.categoryId)}
                >
                  {subCategories.length > 0 &&
                    subCategories.map(item => (
                      <MenuItem key={item.id?.toString()} value={item.id?.toString()}>
                        {item.name}
                      </MenuItem>
                    ))}
                </TextField>
              );
            }
            return <></>;
          })}

          {/* <TextField
          select
          margin='normal'
          label={fm('CATEGORY.EDIT.PARENT')}
          value={values.parent}
          onChange={handleChange}
          name='parent'
          variant='outlined'
        >
          {parents.map(option => (
            <MenuItem key={option.id?.toString()} value={option.id?.toString()}>
              {option.name}
            </MenuItem>
          ))}
        </TextField> */}

          <TextField
            type='text'
            label={`${fm('CATEGORY.EDIT.DESCRIPTION')} (RU)`}
            margin='normal'
            name='description'
            color='secondary'
            value={values.description?.replace(/<\/?[^>]+(>|$)/g, '')}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            multiline
            rows={4}
          />

          <FormControl className={classes.formControl}>
            <Typography>{fm('CATEGORY.TYPE')}</Typography>
            <Select
              style={{ width: '100%', marginBottom: 16 }}
              value={values.types}
              multiple
              onChange={(e: any) => setFieldValue('types', e.target.value)}
              IconComponent={
                values.types.length > 0
                  ? () => (
                      <IconButton
                        aria-label='delete'
                        size='small'
                        onClick={e => {
                          e.stopPropagation();
                          setFieldValue('types', []);
                        }}
                      >
                        <ClearIcon fontSize='inherit' />
                      </IconButton>
                    )
                  : undefined
              }
              renderValue={(selected: any) => (
                <div className={classes.chips}>
                  {selected.map((item: any) => (
                    <Chip
                      label={item.names?.[intl.locale] || item.name}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
            >
              {types.map(type => (
                // @ts-ignore
                <MenuItem key={type.id} value={type}>
                  {type.names?.[intl.locale] || type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <FormControlLabel
              color='secondary'
              control={
                <Switch
                  className={homeClasses.classes.switch}
                  size='small'
                  name='active'
                  checked={values.active || false}
                  onChange={handleChange}
                  color='secondary'
                />
              }
              label={fm('CATEGORY.EDIT.ACTIVE')}
            />

            <FormControlLabel
              color='secondary'
              control={
                <Switch
                  className={homeClasses.classes.switch}
                  size='small'
                  name='favorite'
                  checked={values.favorite || false}
                  onChange={handleChange}
                  color='secondary'
                />
              }
              label={fm('FAVORITE')}
            />
          </Box>

          {id ? (
            <UploadImages
              images={category?.avatar ? [{ ...category.avatar, main: true }] : undefined}
              uploadImages={files => handleUpload(files[0])}
            />
          ) : (
            <UploadImagesOffline once images={images} setImages={setImages} />
          )}

          <div className={classes.actions}>
            <Button
              onClick={() => navigate(-1)}
              className={classes.buttons}
              variant='outlined'
              color='primary'
            >
              {fm('CATEGORY.BUTTON.CANCEL')}
            </Button>

            <ButtonWithLoader
              disabled={editLoading}
              loading={editLoading}
              onPress={handleSubmit}
            >
              {+id ? fm('CATEGORY.BUTTON.EDIT') : fm('CATEGORY.BUTTON.ADD')}
            </ButtonWithLoader>
          </div>
        </div>
        <div className={classes.cardEdit}>
          <TextField
            select
            margin='normal'
            label={fm('CATEGORY.TITLES.LANGUAGE')}
            name='language'
            value={categoryLanguage}
            onChange={e => setLanguage(e.target.value)}
            variant='outlined'
          >
            {categoryLanguages
              .filter(lang => lang.code !== 'ru')
              .map(lang => (
                <MenuItem key={lang.code} value={lang.code}>
                  {fm(`CATEGORY.${lang.code.toUpperCase()}.LANGUAGE`)}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            type='text'
            label={fm('CATEGORY.EDIT.NAME')}
            margin='normal'
            name={`name_${categoryLanguage}`}
            value={values.names[`name_${categoryLanguage}`] || ''}
            variant='outlined'
            onChange={e => setFieldValue(`names.name_${categoryLanguage}`, e.target.value)}
          />

          <TextField
            type='text'
            label={fm('CATEGORY.EDIT.DESCRIPTION')}
            margin='normal'
            name={`description${categoryLanguage}`}
            value={values.names[`description_${categoryLanguage}`] || ''}
            onChange={e =>
              setFieldValue(`names.description_${categoryLanguage}`, e.target.value)
            }
            variant='outlined'
            multiline
            rows={4}
          />
        </div>
      </div>
    </>
  );
};
