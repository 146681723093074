import { makeStyles } from 'tss-react/mui';

export const useStylesProductPage = makeStyles()(theme => {
  return {
    card: {
      marginTop: theme.spacing(3),
    },
    actions: {
      marginTop: theme.spacing(3),
    },
    buttons: {
      marginRight: theme.spacing(2),
    },
    switch: {
      marginLeft: theme.spacing(1),
    },
    companyField: {
      display: 'flex',
      alignItems: 'center',
    },
    companySearch: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: 24,
      maxWidth: 800,
      paddingBottom: 0,
    },
    allCompaniesBtn: {
      marginTop: 8,
      marginLeft: 10,
      height: 52,
    },
    isLoading: {
      paddingRight: '3.5rem!important',
    },
    containerName: {
      display: 'flex',
      flexDirection: 'row',
    },
    fieldName: {
      width: '50%',
    },
    containerNameLang: {
      marginLeft: 8,
      width: '50%',
    },
    containerNameMainLang: {
      width: '50%',
    },
    labelDesc: {
      marginTop: 26,
    },
    containerStock: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      maxWidth: 800,
      justifyContent: 'space-between',
    },
  };
});
