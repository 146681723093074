import React from 'react';
import { Card } from '@mui/material';
import { companyInfoStyles } from '../hooks/companyInfoStyles';

interface ICompanyInfo {
  onPress: () => void;
  compName: string | undefined;
  compBrand: string | undefined;
  rightTitle: string;
}

const CompanyInfo: React.FC<ICompanyInfo> = ({ onPress, compBrand, compName, rightTitle }) => {
  const classes1 = companyInfoStyles();
  const { classes } = classes1;

  return (
    <Card
      className={classes.card}
      style={{
        paddingLeft: 0,
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        marginBottom: 10,
      }}
      onClick={onPress}
    >
      <div
        style={{
          padding: 20,
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', width: '80%' }}>
          <div style={{ paddingLeft: 0 }}>
            <p className={classes.name}>
              <b>{compBrand}</b>
            </p>

            <p className={classes.listKey}>{compName}</p>
          </div>
        </div>

        <div
          style={{
            width: '30%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <p className={classes.listKey}>{rightTitle}</p>
        </div>
      </div>
    </Card>
  );
};

export default CompanyInfo;
