import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useIntl } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Button,
  Card,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as Yup from 'yup';
import { Col, Row } from 'react-bootstrap';
import ButtonWithLoader from '../../../components/ui/Buttons/ButtonWithLoader';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { IAppState } from '../../../store/rootDuck';
import { actions as storeActions } from '../../../store/ducks/store.duck';
import { actions as locationActions } from '../../../store/ducks/yaLocations.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { storeBreadCrumbs } from './utils/createBreadCrumbs';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { IEditWorkingTime, IStore } from '../../../interfaces/store';
import homeStyles from '../homeStyles';
import Shedule from './components/Shedule';
import AutocompleteLocations from '../../../components/AutocompleteLocations';
import { PHONE_MASK } from '../../../constants';
import { IMaskPhoneInput } from '../../auth/components/CheckInSystem';
import AlertDialog from '../../../components/other/Dialog/AlertDialog';
import { useDefineUserRole } from '../../../hooks';

const useStyles = makeStyles()(theme => {
  return {
    card: {
      marginTop: theme.spacing(3),
    },
    actions: {
      marginTop: theme.spacing(3),
    },
    buttons: {
      marginRight: theme.spacing(2),
    },
    autoLoc: {
      width: '100%',
    },
    editor: {
      minHeight: 305,
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
  };
});

const getInitialValues = (store?: IStore, companyId?: number, id?: string) => ({
  location: {
    address: store?.location?.address || '',
    lng: store?.location?.lng || 0,
    lat: store?.location?.lat || 0,
    city: '',
    country: '',
    house: '',
    province: '',
    street: '',
    pickup_point: !id ? 'true' : Boolean(store?.location?.pickup_point).toString(),
    shipping: !id ? 'true' : Boolean(store?.location?.shipping).toString(),
  },
  phone_number: store?.phone_number || '',
  name: store?.name || '',
  working_hours: store?.working_hours || '',
  companyId,
});

interface IField<T> {
  title: string;
  field: T;
  rows?: number;
}

const StoreEditPage: React.FC<TPropsFromRedux> = ({
  store,
  loading,
  fetchById,
  create,
  edit,
  editLoading,
  clearEdit,
  editError,
  editSuccess,
  me,
  loadingMe,
  fetchMe,
  clearMe,
  locations,
  loadingLocations,
  setWorkingTimeLoading,
  setWorkingTimeError,
  setWorkingTimeSuccess,
  setWorkingTime,
  clearSetWorkingTime,
  fetchLocations,
  clearLocations,
  delStore,
  delStoreError,
  delStoreLoding,
  delStoreSuccess,
  clearDelStore,
}) => {
  const intl = useIntl();
  const {
    id,
    companyId: companyIdFromParams,
    companyName: companyNameFromParams,
  } = useParams();
  const navigate = useNavigate();
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const homeClasses = homeStyles();
  const classes1 = useStyles();
  const { classes } = classes1;
  const companyId = Number(companyIdFromParams) || (me?.company?.id as number);
  // const [shippingLocation, setShippingLocation] = useState<ILocation | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const [deleteStoreId, setDeleteStoreId] = useState<number | undefined>(-1);
  const [isOpenDeleteStoreAlert, setOpenDeleteStoreAlert] = useState(false);
  // const [editorState, setEditorState] = useState('');

  useEffect(() => {
    fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  // For edit actions
  useCrudSnackbar({
    success: editSuccess,
    error: editError,
    clear: clearEdit,
    successMessage: id
      ? intl.formatMessage({ id: 'STORE.EDIT.SUCCESS' })
      : intl.formatMessage({ id: 'STORE.CREATE.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${editError}`,
    afterSuccess: () => navigate(-1),
  });

  useCrudSnackbar({
    success: setWorkingTimeSuccess,
    error: setWorkingTimeError,
    clear: clearSetWorkingTime,
    successMessage: intl.formatMessage({ id: 'STORE.EDIT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${setWorkingTimeError}`,
  });

  const handleChangeTabValue = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  // For delete stories
  useCrudSnackbar({
    success: delStoreSuccess,
    error: delStoreError,
    clear: clearDelStore,
    successMessage: intl.formatMessage({ id: 'STORE.DELETE.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${delStoreError}`,
    afterSuccess: () => {
      navigate(-1);
    },
    afterSuccessOrError: () => {
      clearDelStore();
    },
  });

  const handleDeleteDialog = useCallback((id: number) => {
    setDeleteStoreId(id);
    setOpenDeleteStoreAlert(true);
  }, []);

  const deleteStoreAction = useCallback(() => {
    setOpenDeleteStoreAlert(false);
    if (deleteStoreId) {
      delStore({ id: deleteStoreId, page: 1, perPage: 10 });
    }
  }, [deleteStoreId, delStore]);

  // const localLocation = useMemo(() => {
  //   const localLocation = localStorage.getItem('location');
  //   const parseLocalLocation = localLocation && JSON.parse(localLocation);
  //   return parseLocalLocation;
  // }, []);

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: getInitialValues(store, companyId, id),
    onSubmit: (values, { setSubmitting }) => {
      values.working_hours = '';

      if (!store) {
        create({
          data: {
            ...values,
            location: {
              ...values.location,
              pickup_point: values.location.pickup_point === 'true',
              shipping: values.location.shipping === 'true',
            },
            phone_number: values.phone_number.replace(/ /g, '').replace(/[{()}]/g, ''),
          } as IStore,
        });
      } else {
        store.id &&
          edit({
            id: store.id,
            data: {
              ...values,
              location: {
                ...values.location,
                pickup_point: values.location.pickup_point === 'true',
                shipping: values.location.shipping === 'true',
              },
              phone_number: values.phone_number.replace(/ /g, '').replace(/[{()}]/g, ''),
            } as IStore,
          });
      }
      setSubmitting(true);
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }))
        .nullable(),
      phone_number: Yup.string(),
      location: Yup.object({
        address: Yup.string().required(
          intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })
        ),
      }),
    }),
  });

  useEffect(() => {
    id && fetchById(+id);
    !id && resetForm();

    return () => {
      clearEdit();
    };
  }, [id, fetchById, clearEdit, resetForm]);

  useEffect(() => {
    if (me && me.location) {
      setFieldValue('location', me.location);
      // setShippingLocation(me.location);
    } else {
      // setShippingLocation(localLocation);
    }
  }, [me]);

  // set values after fetch store
  useEffect(() => {
    store && setValues(getInitialValues(store, companyId, id));
  }, [store, setValues]);

  setLayoutSubheader({
    title: !id
      ? `${intl.formatMessage({ id: 'STORE.CREATE.TITLE' })}`
      : `${intl.formatMessage({ id: 'STORE.EDIT.TITLE' })}`,
    breadcrumb: storeBreadCrumbs(intl),
  });
  setLayoutFooter({ show: true });

  const FIELDS: IField<keyof typeof values>[] = [
    { title: intl.formatMessage({ id: 'STORE.INPUT.NAME' }), field: 'name' },
  ];

  if (!me || loadingMe || loading || setWorkingTimeLoading) return <Preloader />;

  return (
    <>
      <AlertDialog
        open={isOpenDeleteStoreAlert}
        message={intl.formatMessage({ id: 'STORE.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          clearDelStore();
          setOpenDeleteStoreAlert(false);
        }}
        handleAgree={deleteStoreAction}
      />

      <Row>
        <Col>
          <Card className={homeClasses.classes.container}>
            <>
              <Tabs
                value={tabValue}
                onChange={handleChangeTabValue}
                indicatorColor='primary'
                textColor='primary'
              >
                <Tab label={intl.formatMessage({ id: 'STORES.TABS.COMMON' })} />
                {id && <Tab label={intl.formatMessage({ id: 'STORES.TABS.WORKING_TIME' })} />}
              </Tabs>

              {tabValue === 0 ? (
                <form className={homeClasses.classes.form} onSubmit={handleSubmit}>
                  {companyNameFromParams && (
                    <TextField
                      type='text'
                      label={intl.formatMessage({ id: 'COMPANY.FORM.COMPANY' })}
                      margin='normal'
                      name='companyName'
                      value={companyNameFromParams}
                      variant='outlined'
                      disabled
                    />
                  )}

                  {FIELDS.map(item => (
                    <TextField
                      key={item.field}
                      type='text'
                      label={item.title}
                      margin='normal'
                      name={item.field}
                      value={values[item.field]}
                      variant='outlined'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched[item.field] && errors[item.field]?.toString()}
                      error={Boolean(touched[item.field] && errors[item.field])}
                      rows={item.rows ? item.rows : 1}
                      multiline={!!(item.rows && item.rows > 1)}
                    />
                  ))}

                  <IMaskPhoneInput
                    mask={PHONE_MASK}
                    onBlur={handleBlur}
                    value={values.phone_number}
                    onChange={(e: any) => {
                      setFieldValue('phone_number', e.target.value || '');
                    }}
                    key='phone_number'
                    type='tel'
                    label={intl.formatMessage({
                      id: 'STORE.INPUT.PHONE',
                    })}
                    margin='normal'
                    variant='outlined'
                    className='kt-width-full'
                    name='phone_number'
                    helperText={touched.phone_number && errors.phone_number}
                    error={Boolean(touched.phone_number && errors.phone_number)}
                  />

                  {/* <AutocompleteCopy
                  helperText={touched.location && errors.location && errors.location.address}
                  error={Boolean(
                    touched.location && errors.location && errors.location.address
                  )}
                  value={values.location}
                  setSelectedLocation={location => {
                    setFieldValue('location.address', location.address);
                  }}
                /> */}
                  <AutocompleteLocations
                    options={locations || []}
                    loading={loadingLocations}
                    inputValue={values.location}
                    editable
                    label={intl.formatMessage({
                      id: 'STORE.INPUT.LOCATION',
                    })}
                    inputClassName={classes.autoLoc}
                    inputError={Boolean(
                      touched.location && errors.location && errors.location.address
                    )}
                    inputHelperText={
                      touched.location && errors.location && errors.location.address
                    }
                    fetchLocations={fetchLocations}
                    clearLocations={clearLocations}
                    setSelectedLocation={location => {
                      setFieldValue('location.address', location.address);
                    }}
                    handleBlur={handleBlur}
                    disable={false}
                  />

                  <FormLabel component='legend' style={{ marginTop: 25 }}>
                    {intl.formatMessage({ id: 'STORE.FORMCONTROL.PICKUP' })}
                  </FormLabel>
                  <RadioGroup
                    name='location.pickup_point'
                    value={values.location.pickup_point}
                    onChange={handleChange}
                    style={{ flexDirection: 'row' }}
                  >
                    <FormControlLabel
                      value='true'
                      control={<Radio />}
                      label={intl.formatMessage({ id: 'STORE.FORMCONTROL.YES' })}
                    />

                    <FormControlLabel
                      value='false'
                      control={<Radio />}
                      label={intl.formatMessage({ id: 'STORE.FORMCONTROL.NO' })}
                    />
                  </RadioGroup>

                  <FormLabel component='legend' style={{ marginTop: 10 }}>
                    {intl.formatMessage({ id: 'STORE.FORMCONTROL.WAREHOUSE' })}
                  </FormLabel>
                  <RadioGroup
                    name='location.shipping'
                    value={values.location.shipping}
                    onChange={handleChange}
                    style={{ flexDirection: 'row' }}
                  >
                    <FormControlLabel
                      value='true'
                      control={<Radio />}
                      label={intl.formatMessage({ id: 'STORE.FORMCONTROL.YES' })}
                    />

                    <FormControlLabel
                      value='false'
                      control={<Radio />}
                      label={intl.formatMessage({ id: 'STORE.FORMCONTROL.NO' })}
                    />
                  </RadioGroup>

                  <div className={classes.actions}>
                    <Button
                      onClick={() => navigate(-1)}
                      className={classes.buttons}
                      variant='outlined'
                      color='primary'
                    >
                      {intl.formatMessage({ id: 'CATEGORY.BUTTON.CANCEL' })}
                    </Button>

                    <ButtonWithLoader disabled={editLoading} loading={editLoading}>
                      {intl.formatMessage({ id: 'COMMON.BUTTON.SAVE' })}
                    </ButtonWithLoader>

                    {(me?.is_admin || isManager) && id && (
                      <Button
                        onClick={() => handleDeleteDialog(+id)}
                        variant='outlined'
                        color='secondary'
                        disabled={delStoreLoding}
                      >
                        {intl.formatMessage({ id: 'COMMON.BUTTON.DELETE' })}
                      </Button>
                    )}
                  </div>
                </form>
              ) : (
                id && (
                  <Shedule
                    dates={store?.location?.working_times || []}
                    loading={setWorkingTimeLoading}
                    submit={(data: IEditWorkingTime) => setWorkingTime({ id: +id, data })}
                  />
                )
              )}
            </>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    store: state.stores.store,
    loading: state.stores.byIdLoading,
    editLoading: state.stores.editLoading,
    loadingLocations: state.yaLocations.loading,
    locations: state.yaLocations.yaLocations,
    editError: state.stores.editError,
    editSuccess: state.stores.editSuccess,

    me: state.profile.me,
    loadingMe: state.profile.loading,

    setWorkingTimeLoading: state.stores.setWorkingTimeLoading,
    setWorkingTimeError: state.stores.setWorkingTimeError,
    setWorkingTimeSuccess: state.stores.setWorkingTimeSuccess,

    delStoreSuccess: state.stores.delSuccess,
    delStoreError: state.stores.delError,
    delStoreLoding: state.stores.delLoading,
  }),
  {
    fetchById: storeActions.fetchByIdRequest,
    create: storeActions.addRequest,
    edit: storeActions.editRequest,
    clearEdit: storeActions.clearEdit,
    fetchLocations: locationActions.fetchRequest,
    clearLocations: locationActions.clear,

    delStore: storeActions.delRequest,
    clearDelStore: storeActions.clearDel,

    setWorkingTime: storeActions.setWorkingTimeRequest,
    clearSetWorkingTime: storeActions.clearSetWorkingTime,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StoreEditPage);
