import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useIntl } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Skeleton } from '@mui/lab';
import {
  TextField,
  Card,
  Tabs,
  Tab,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { IAppState } from '../../../store/rootDuck';
import { actions as orderActions } from '../../../store/ducks/orders.duck';
import { actions as locationActions } from '../../../store/ducks/yaLocations.duck';
import { actions as deliveryActions } from '../../../store/ducks/delivery.duck';
import homeStyles from '../../../constants/homeStyles';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
// import { getOrderTypeWithTranslates } from './constatns';
import { useStyles } from './hooks/useStyles';
import AlertDialog from '../../../components/other/Dialog/AlertDialog';
import { TabPanel } from '../../../components/other/Tab/TabPanel';
import { SHeadTableCell } from '../../../components/styledComponents/Tables';
import { getInitialValuesDelivery } from './lib/helpers';
import { API_DOMAIN } from '../../../constants';

export enum ViewMode {
  VIEW = 'view',
  EDIT = 'edit',
}

const OrderPage: React.FC<TPropsFromRedux> = ({
  order,
  loading,
  fetchById,
  editError,
  editSuccess,
  clear,
  deliveries,
}) => {
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [valueTabs, setValueTabs] = useState(0);
  const intl = useIntl();
  const navigate = useNavigate();
  const { id, mode } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const homeClasses = homeStyles();
  const classes = useStyles();

  useEffect(() => {
    if (id) {
      fetchById({ id: Number(id) });
    }
  }, [id, fetchById]);

  useEffect(() => {
    if (editSuccess || editError) {
      enqueueSnackbar(
        editSuccess
          ? ` ${intl.formatMessage({
              id: 'ORDER.SNACKBAR.EDIT',
            })}`
          : `${intl.formatMessage({ id: 'PRODUCT.SNACKBAR.ERROR' })} ${editError}`,
        { variant: editSuccess ? 'success' : 'error' }
      );
      if (editSuccess) {
        navigate(-1);
      }
    }
  }, [editSuccess, editError, enqueueSnackbar, id, intl, navigate]);

  const { values, handleBlur, handleChange, errors, touched, handleSubmit, setValues } =
    useFormik({
      enableReinitialize: true,
      initialValues: getInitialValuesDelivery(deliveries),
      onSubmit: () => {
        // edit({ id: Number(id), data: submitValues });
      },
    });

  useEffect(() => {
    if (deliveries) {
      setValues(getInitialValuesDelivery(deliveries));
    }
  }, [deliveries, setValues]);

  const translates: { [type: string]: string } = {
    cart: intl.formatMessage({ id: 'ORDER.TYPE.CART' }),
    new: intl.formatMessage({ id: 'ORDER.TYPE.NEW' }),
    formation: intl.formatMessage({ id: 'ORDER.TYPE.FORMATION' }),
    ready_to_delivery: intl.formatMessage({ id: 'ORDER.TYPE.DELIVERY' }),
    payed: intl.formatMessage({ id: 'ORDER.TYPE.PAYED' }),
    canceled: intl.formatMessage({ id: 'ORDER.TYPE.CANCELED' }),
    completed: intl.formatMessage({ id: 'ORDER.TYPE.COMPLETED' }),
    in_processing: intl.formatMessage({ id: 'ORDER.TYPE.PROCCESS' }),
  };
  // const orderTypes = getOrderTypeWithTranslates(translates, true);
  // const disabled = mode === ViewMode.VIEW;

  setLayoutSubheader({
    title:
      mode === ViewMode.VIEW
        ? `${intl.formatMessage({ id: 'DELIVERY.HEADER.VIEW' })}`
        : `${intl.formatMessage({ id: 'DELIVERY.HEADER.EDIT' })}`,
  });
  setLayoutFooter({ show: true });

  const handleTabsChange = (event: any, newValue: number) => {
    setValueTabs(newValue);
  };

  useEffect(
    () => () => {
      clear();
    },
    [clear]
  );

  const alertText = `${intl.formatMessage({ id: 'ORDER.ALERT.EDIT.TEXT1' })} "${
    translates[order?.status || '']
  }". ${intl.formatMessage({ id: 'ORDER.ALERT.EDIT.TEXT2' })}`;

  if (loading) {
    return <></>;
  }
  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={alertText}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setAlertOpen(false);
        }}
        handleAgree={() => handleSubmit()}
      />
      <Card className={homeClasses.classes.container}>
        <Tabs
          value={valueTabs}
          onChange={handleTabsChange}
          variant='scrollable'
          indicatorColor='primary'
          textColor='primary'
          aria-label='tabs'
        >
          <Tab label={intl.formatMessage({ id: 'ORDER.VIEW.TABS.ITEMS' })} />
          <Tab label={intl.formatMessage({ id: 'DELIVERY.TAB.MAIN' })} />
        </Tabs>
        <div className={homeClasses.classes.form}>
          <TabPanel value={valueTabs} index={1}>
            {loading ? (
              <>
                <Skeleton width='100%' height={70} animation='wave' />
                <Skeleton width='100%' height={70} animation='wave' />
                <Skeleton width='100%' height={70} animation='wave' />
                <Skeleton width='100%' height={70} animation='wave' />
              </>
            ) : (
              <>
                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'DELIVERY.EXTERNAL.ID' })}
                  margin='normal'
                  name='external_id'
                  value={values.external_id}
                  variant='outlined'
                  helperText={touched.external_id && errors.external_id?.toString()}
                  error={Boolean(touched.external_id && errors.external_id)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ readOnly: true }}
                />
                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'DELIVERY.EXTERNAL.CREATED.ID' })}
                  margin='normal'
                  name='external_created_at'
                  value={
                    values.external_created_at
                      ? moment(values.external_created_at).format('YYYY-MM-DD HH:mm')
                      : ''
                  }
                  variant='outlined'
                  helperText={
                    touched.external_created_at && errors.external_created_at?.toString()
                  }
                  error={Boolean(touched.external_created_at && errors.external_created_at)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ readOnly: true }}
                />
                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'DELIVERY.EXPORTED.AT' })}
                  margin='normal'
                  name='exported_at'
                  value={
                    values.exported_at
                      ? moment(values.exported_at).format('YYYY-MM-DD HH:mm')
                      : ''
                  }
                  variant='outlined'
                  helperText={touched.exported_at && errors.exported_at?.toString()}
                  error={Boolean(touched.exported_at && errors.exported_at)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ readOnly: true }}
                />
                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'DELIVERY.COMMENT' })}
                  margin='normal'
                  name='comment'
                  value={values.comment}
                  variant='outlined'
                  helperText={touched.comment && errors.comment?.toString()}
                  error={Boolean(touched.comment && errors.comment)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  rows={3}
                  multiline
                  inputProps={{ readOnly: true }}
                />
              </>
            )}
          </TabPanel>
          <TabPanel value={valueTabs} index={0}>
            <CardContent className={homeClasses.classes.tableContainer}>
              {deliveries?.document_products ? (
                <>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.PREVIEW' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.NAME' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.PRICE' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.PLAN' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.FACT' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'PRODUCT.TABLE.DEFECT' })}
                        </SHeadTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {deliveries?.document_products.map((item: any) => (
                        <TableRow>
                          <TableCell>
                            {item?.product?.photos && item.product.photos[0] && (
                              <img
                                alt='img'
                                src={`${API_DOMAIN}/${
                                  item?.product?.photos?.find((el: any) => el.main)?.path ||
                                  item.product.photos[0].path
                                }`}
                                className={classes.img}
                              />
                            )}
                          </TableCell>
                          <TableCell>{item.product.name ? item.product.name : '-'}</TableCell>
                          <TableCell>{item.product.price || 0}</TableCell>
                          <TableCell>{item.amount || '-'}</TableCell>
                          <TableCell>{item.actual_amount || ''}</TableCell>
                          <TableCell>{item.defective_amount || '-'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              ) : (
                ''
              )}
            </CardContent>
          </TabPanel>
        </div>
      </Card>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    order: state.orders.order,
    loading: state.orders.byIdLoading,
    loadingLocations: state.yaLocations.loading,
    editError: state.orders.editError,
    editSuccess: state.orders.editSuccess,
    editLoading: state.orders.editLoading,
    locations: state.yaLocations.yaLocations,
    byCoordsLoading: state.yaLocations.getLoacationByCoordsLoading,
    error: state.products.error,
    deliveries: state.delivery.deliveryById,
  }),
  {
    edit: orderActions.editRequest,
    clear: orderActions.clearEdit,
    fetchLocations: locationActions.fetchRequest,
    clearLocations: locationActions.clear,
    fetchById: deliveryActions.fetchRequestById,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OrderPage);
