import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IProduct } from '../../../../interfaces/product';
import { makeAxiosDeleteRequest, makeAxiosPostRequest } from '../../../../utils/utils';
import { actions } from '../../../../store/ducks/product.duck';
import { IPhoto } from '../../../../interfaces/photo';

export const handleProductImages = (product: IProduct | undefined) => {
  const dispatch = useDispatch();
  const [res, setRes] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);

  const uploadImages = useCallback(
    (files: File[]) => {
      const url = `/api/shop/product/${product?.id}`;
      const formData = new FormData();
      files.forEach(file => formData.append('photo[]', file));
      if (product?.product_type?.id) {
        formData.append('product_type_id', product?.product_type?.id.toString());
      }
      makeAxiosPostRequest(url, formData, setRes, setLoading, setErr, {
        headers: { 'content-type': 'multipart/form-data' },
      });
    },
    [product?.id]
  );

  const deleteImage = useCallback((id: IPhoto['id']) => {
    const url = `/api/shop/product_photo/${id}`;
    makeAxiosDeleteRequest(url, setRes, setLoading, setErr);
  }, []);

  const setMainImage = useCallback((id: IPhoto['id']) => {
    const url = `/api/shop/product_photo/${id}/set_main`;
    makeAxiosPostRequest(url, undefined, setRes, setLoading, setErr);
  }, []);

  useEffect(() => {
    if (res) dispatch(actions.fetchByIdSuccess(res.data));
  }, [res]);

  // cleanup
  useEffect(() => {
    setRes(null);
    setErr(null);
  }, [res, err]);

  return [err, uploadImages, deleteImage, setMainImage, loading];
};
