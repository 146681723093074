'use strict';

/**
 * Polyfills
 */

// Element.matches() polyfill
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

// Element.closest() polyfill
if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    let el = this;
    if (!document.documentElement.contains(el)) return null;
    do {
      if (el.matches(s)) return el;
      el = el.parentElement;
    } while (el !== null);
    return null;
  };
}

// ChildNode.remove() polyfill
['Element', 'CharacterData', 'DocumentType'].forEach(item => {
  if (window[item] && !window[item].prototype.remove) {
    window[item].prototype.remove = function () {
      this.parentNode.removeChild(this);
    };
  }
});

// requestAnimationFrame polyfill
(function () {
  const vendors = ['webkit', 'moz'];
  let lastTime = 0;

  for (let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
    window.cancelAnimationFrame =
      window[vendors[x] + 'CancelAnimationFrame'] ||
      window[vendors[x] + 'CancelRequestAnimationFrame'];
  }

  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = function (callback) {
      const currTime = new Date().getTime();
      const timeToCall = Math.max(0, 16 - (currTime - lastTime));
      const id = window.setTimeout(() => callback(currTime + timeToCall), timeToCall);
      lastTime = currTime + timeToCall;
      return id;
    };
  }

  if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = function (id) {
      clearTimeout(id);
    };
  }
})();

// prepend() polyfill
[Element.prototype, Document.prototype, DocumentFragment.prototype].forEach(item => {
  if (!item.hasOwnProperty('prepend')) {
    Object.defineProperty(item, 'prepend', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function prepend(...args) {
        const docFrag = document.createDocumentFragment();
        args.forEach(argItem => {
          docFrag.appendChild(
            argItem instanceof Node ? argItem : document.createTextNode(String(argItem))
          );
        });
        this.insertBefore(docFrag, this.firstChild);
      },
    });
  }
});

/**
 * Global variables
 */
const KTUtilElementDataStore = {};
let KTUtilElementDataStoreID = 0;
const KTUtilDelegatedEventHandlers = {};

/**
 * Helper Functions
 */
const getViewPort = () => {
  const e = window;
  let a = 'inner';
  if (!('innerWidth' in window)) {
    a = 'client';
    e = document.documentElement || document.body;
  }
  return { width: e[a + 'Width'], height: e[a + 'Height'] };
};

const isMobileDevice = () => getViewPort().width < KTUtil.getBreakpoint('lg');
const isDesktopDevice = () => !isMobileDevice();

const getBreakpoint = mode => {
  const breakpoints = {
    sm: 544,
    md: 768,
    lg: 1024,
    xl: 1200,
  };
  return breakpoints[mode];
};

/**
 * Main KTUtil Class
 */
const KTUtil = (() => {
  const resizeHandlers = [];

  const _runResizeHandlers = () => {
    resizeHandlers.forEach(handler => handler());
  };

  const _windowResizeHandler = () => {
    let timeout;
    const delay = 250;

    window.addEventListener('resize', () => {
      clearTimeout(timeout);
      timeout = setTimeout(_runResizeHandlers, delay);
    });
  };

  const _getElement = query => {
    if (!query) return null;
    if (query.nodeType === 1) return query;
    if (typeof query === 'string') {
      return document.getElementById(query) || document.querySelector(query);
    }
    return null;
  };

  return {
    init: function (options) {
      if (options && options.breakpoints) {
        breakpoints = options.breakpoints;
      }
      _windowResizeHandler();
    },
    addResizeHandler: function (callback) {
      resizeHandlers.push(callback);
    },
    removeResizeHandler: function (callback) {
      const index = resizeHandlers.indexOf(callback);
      if (index > -1) resizeHandlers.splice(index, 1);
    },
    runResizeHandlers: function () {
      _runResizeHandlers();
    },
    resize: function () {
      if (typeof Event === 'function') {
        window.dispatchEvent(new Event('resize'));
      } else {
        const evt = window.document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
      }
    },
    getURLParam: function (paramName) {
      const params = window.location.search.substring(1).split('&');
      for (let i = 0; i < params.length; i++) {
        const val = params[i].split('=');
        if (val[0] === paramName) return unescape(val[1]);
      }
      return null;
    },
    isMobileDevice: isMobileDevice,
    isDesktopDevice: isDesktopDevice,
    getViewPort: getViewPort,
    isInResponsiveRange: function (mode) {
      const breakpoint = getViewPort().width;
      if (mode === 'general') return true;
      if (mode === 'desktop' && breakpoint >= getBreakpoint('lg') + 1) return true;
      if (
        mode === 'tablet' &&
        breakpoint >= getBreakpoint('md') + 1 &&
        breakpoint < getBreakpoint('lg')
      )
        return true;
      if (mode === 'mobile' && breakpoint <= getBreakpoint('md')) return true;
      if (mode === 'desktop-and-tablet' && breakpoint >= getBreakpoint('md') + 1) return true;
      if (mode === 'tablet-and-mobile' && breakpoint <= getBreakpoint('lg')) return true;
      if (mode === 'minimal-desktop-and-below' && breakpoint <= getBreakpoint('xl'))
        return true;
      return false;
    },
    getUniqueID: function (prefix) {
      return prefix + Math.floor(Math.random() * new Date().getTime());
    },
    getBreakpoint: getBreakpoint,
    isset: function (obj, keys) {
      if (keys.indexOf('[') !== -1) throw new Error('Unsupported object path notation.');
      keys = keys.split('.');
      for (let key of keys) {
        if (!obj.hasOwnProperty(key)) return false;
        obj = obj[key];
      }
      return true;
    },
    getHighestZindex: function (el) {
      let elem = _getElement(el);
      while (elem && elem !== document) {
        const position = KTUtil.css(elem, 'position');
        if (['absolute', 'relative', 'fixed'].includes(position)) {
          const value = parseInt(KTUtil.css(elem, 'z-index'));
          if (!isNaN(value) && value !== 0) return value;
        }
        elem = elem.parentNode;
      }
      return null;
    },
    hasFixedPositionedParent: function (el) {
      el = _getElement(el);
      while (el && el !== document) {
        if (KTUtil.css(el, 'position') === 'fixed') return true;
        el = el.parentNode;
      }
      return false;
    },
    sleep: function (milliseconds) {
      const start = new Date().getTime();
      while (new Date().getTime() - start < milliseconds) {}
    },
    getRandomInt: function (min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    isAngularVersion: function () {
      return window.Zone !== undefined;
    },
    deepExtend: function (out, ...args) {
      out = out || {};
      for (let obj of args) {
        if (!obj) continue;
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === 'object') {
              out[key] = KTUtil.deepExtend(out[key], obj[key]);
            } else {
              out[key] = obj[key];
            }
          }
        }
      }
      return out;
    },
    extend: function (out, ...args) {
      out = out || {};
      for (let obj of args) {
        if (!obj) continue;
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            out[key] = obj[key];
          }
        }
      }
      return out;
    },
    get: _getElement,
    getByID: function (query) {
      return document.getElementById(query) || null;
    },
    getByTag: function (query) {
      const el = document.getElementsByTagName(query);
      return el.length > 0 ? el[0] : null;
    },
    getByClass: function (query) {
      const el = document.getElementsByClassName(query);
      return el.length > 0 ? el[0] : null;
    },
    hasClasses: function (el, classes) {
      el = _getElement(el);
      if (!el) return false;
      return classes.split(' ').every(cls => KTUtil.hasClass(el, KTUtil.trim(cls)));
    },
    hasClass: function (el, className) {
      el = _getElement(el);
      if (!el) return false;
      return el.classList
        ? el.classList.contains(className)
        : new RegExp('\\b' + className + '\\b').test(el.className);
    },
    addClass: function (el, className) {
      el = _getElement(el);
      if (!el || typeof className === 'undefined') return;
      const classNames = className.split(' ');
      if (el.classList) {
        classNames.forEach(cls => {
          if (cls && cls.length > 0) el.classList.add(KTUtil.trim(cls));
        });
      } else if (!KTUtil.hasClass(el, className)) {
        classNames.forEach(cls => (el.className += ' ' + KTUtil.trim(cls)));
      }
    },
    removeClass: function (el, className) {
      el = _getElement(el);
      if (!el || typeof className === 'undefined') return;
      const classNames = className.split(' ');
      if (el.classList) {
        classNames.forEach(cls => el.classList.remove(KTUtil.trim(cls)));
      } else if (KTUtil.hasClass(el, className)) {
        classNames.forEach(
          cls =>
            (el.className = el.className.replace(
              new RegExp('\\b' + KTUtil.trim(cls) + '\\b', 'g'),
              ''
            ))
        );
      }
    },
    triggerCustomEvent: function (el, eventName, data) {
      el = _getElement(el);
      if (!el) return;
      const event = window.CustomEvent
        ? new CustomEvent(eventName, { detail: data })
        : document.createEvent('CustomEvent');
      event.initCustomEvent(eventName, true, true, data);
      el.dispatchEvent(event);
    },
    triggerEvent: function (node, eventName) {
      node = _getElement(node);
      if (!node) return;
      const doc = node.ownerDocument || node;
      if (node.dispatchEvent) {
        const eventClass =
          {
            click: 'MouseEvents',
            mouseenter: 'MouseEvents',
            mouseleave: 'MouseEvents',
            mousedown: 'MouseEvents',
            mouseup: 'MouseEvents',
            focus: 'HTMLEvents',
            change: 'HTMLEvents',
            blur: 'HTMLEvents',
            select: 'HTMLEvents',
          }[eventName] || 'HTMLEvents';
        const event = doc.createEvent(eventClass);
        event.initEvent(eventName, eventName !== 'change', true);
        event.synthetic = true;
        node.dispatchEvent(event, true);
      } else if (node.fireEvent) {
        const event = doc.createEventObject();
        event.synthetic = true;
        node.fireEvent('on' + eventName, event);
      }
    },
    index: function (elm) {
      elm = _getElement(elm);
      if (!elm) return -1;
      return Array.prototype.indexOf.call(elm.parentNode.children, elm);
    },
    trim: function (string) {
      return string.trim();
    },
    eventTriggered: function (e) {
      if (e.currentTarget.dataset.triggered) return true;
      e.currentTarget.dataset.triggered = true;
      return false;
    },
    remove: function (el) {
      el = _getElement(el);
      if (el && el.parentNode) el.parentNode.removeChild(el);
    },
    find: function (parent, query) {
      parent = _getElement(parent);
      return parent ? parent.querySelector(query) : null;
    },
    findAll: function (parent, query) {
      parent = _getElement(parent);
      return parent ? parent.querySelectorAll(query) : [];
    },
    insertAfter: function (el, referenceNode) {
      el = _getElement(el);
      referenceNode = _getElement(referenceNode);
      if (el && referenceNode && referenceNode.parentNode) {
        referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
      }
    },
    parents: function (elem, selector) {
      elem = _getElement(elem);
      const parents = [];
      while (elem && elem !== document) {
        if (selector) {
          if (elem.matches(selector)) parents.push(elem);
        } else {
          parents.push(elem);
        }
        elem = elem.parentNode;
      }
      return parents;
    },
    children: function (el, selector) {
      el = _getElement(el);
      if (!el || !el.childNodes) return [];
      return Array.from(el.childNodes).filter(
        child => child.nodeType == 1 && KTUtil.matches(child, selector)
      );
    },
    child: function (el, selector) {
      const children = KTUtil.children(el, selector);
      return children.length ? children[0] : null;
    },
    matches: function (el, selector) {
      const p = Element.prototype;
      const f =
        p.matches ||
        p.webkitMatchesSelector ||
        p.mozMatchesSelector ||
        p.msMatchesSelector ||
        (s => [].indexOf.call(document.querySelectorAll(s), this) !== -1);
      return el && el.tagName ? f.call(el, selector) : false;
    },
    data: function (element) {
      element = _getElement(element);
      return {
        set: function (name, data) {
          if (!element) return;
          if (element.customDataTag === undefined) {
            KTUtilElementDataStoreID++;
            element.customDataTag = KTUtilElementDataStoreID;
          }
          if (!KTUtilElementDataStore[element.customDataTag]) {
            KTUtilElementDataStore[element.customDataTag] = {};
          }
          KTUtilElementDataStore[element.customDataTag][name] = data;
        },
        get: function (name) {
          if (!element) return null;
          if (element.customDataTag === undefined) return null;
          return this.has(name) ? KTUtilElementDataStore[element.customDataTag][name] : null;
        },
        has: function (name) {
          if (!element) return false;
          if (element.customDataTag === undefined) return false;
          return KTUtilElementDataStore[element.customDataTag] &&
            KTUtilElementDataStore[element.customDataTag][name]
            ? true
            : false;
        },
        remove: function (name) {
          if (element && this.has(name)) {
            delete KTUtilElementDataStore[element.customDataTag][name];
          }
        },
      };
    },
    outerWidth: function (el, margin) {
      el = _getElement(el);
      if (!el) return 0;
      let width = el.offsetWidth;
      if (margin) {
        width +=
          parseFloat(KTUtil.css(el, 'margin-left')) +
          parseFloat(KTUtil.css(el, 'margin-right'));
      }
      return width;
    },
    offset: function (elem) {
      elem = _getElement(elem);
      if (!elem) return { top: 0, left: 0 };
      const rect = elem.getBoundingClientRect();
      const win = elem.ownerDocument.defaultView;
      return {
        top: rect.top + win.pageYOffset,
        left: rect.left + win.pageXOffset,
      };
    },
    height: function (el) {
      el = _getElement(el);
      return el ? KTUtil.css(el, 'height') : 0;
    },
    visible: function (el) {
      el = _getElement(el);
      return el ? !(el.offsetWidth === 0 && el.offsetHeight === 0) : false;
    },
    attr: function (el, name, value) {
      el = _getElement(el);
      if (!el) return;
      if (value !== undefined) {
        el.setAttribute(name, value);
      } else {
        return el.getAttribute(name);
      }
    },
    hasAttr: function (el, name) {
      el = _getElement(el);
      return el ? !!el.getAttribute(name) : false;
    },
    removeAttr: function (el, name) {
      el = _getElement(el);
      if (el) el.removeAttribute(name);
    },
    animate: function (from, to, duration, update, easing = 'linear', done) {
      const easings = { linear: (t, b, c, d) => (c * t) / d + b };
      const easingFunc = easings[easing];

      if (
        typeof from !== 'number' ||
        typeof to !== 'number' ||
        typeof duration !== 'number' ||
        typeof update !== 'function'
      )
        return;
      if (typeof done !== 'function') done = function () {};

      const rAF =
        window.requestAnimationFrame || (callback => window.setTimeout(callback, 1000 / 50));

      let canceled = false;
      const change = to - from;
      let start =
        window.performance && window.performance.now ? window.performance.now() : +new Date();

      const loop = timestamp => {
        const time = (timestamp || +new Date()) - start;
        if (time >= 0) update(easingFunc(time, from, change, duration));
        if (time >= 0 && time >= duration) {
          update(to);
          done();
        } else {
          rAF(loop);
        }
      };

      update(from);
      rAF(loop);
    },
    actualCss: function (el, prop, cache) {
      el = _getElement(el);
      if (el instanceof HTMLElement === false) return 0;

      if (!el.getAttribute('kt-hidden-' + prop) || cache === false) {
        let value;
        const css = el.style.cssText;
        el.style.cssText = 'position: absolute; visibility: hidden; display: block;';
        if (prop === 'width') value = el.offsetWidth;
        else if (prop === 'height') value = el.offsetHeight;
        el.style.cssText = css;
        el.setAttribute('kt-hidden-' + prop, value);
        return parseFloat(value);
      } else {
        return parseFloat(el.getAttribute('kt-hidden-' + prop));
      }
    },
    actualHeight: function (el, cache) {
      return KTUtil.actualCss(el, 'height', cache);
    },
    actualWidth: function (el, cache) {
      return KTUtil.actualCss(el, 'width', cache);
    },
    getScroll: function (element, method) {
      element = _getElement(element);
      method = 'scroll' + method;
      return element === window || element === document
        ? self[method === 'scrollTop' ? 'pageYOffset' : 'pageXOffset'] ||
            document.documentElement[method] ||
            document.body[method]
        : element[method];
    },
    css: function (el, styleProp, value) {
      el = _getElement(el);
      if (!el) return;

      if (value !== undefined) {
        el.style[styleProp] = value;
      } else {
        const defaultView = (el.ownerDocument || document).defaultView;
        if (defaultView && defaultView.getComputedStyle) {
          styleProp = styleProp.replace(/([A-Z])/g, '-$1').toLowerCase();
          return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
        } else if (el.currentStyle) {
          styleProp = styleProp.replace(/\-(\w)/g, (str, letter) => letter.toUpperCase());
          let val = el.currentStyle[styleProp];
          if (/^\d+(em|pt|%|ex)?$/i.test(val)) {
            return (function (value) {
              const oldLeft = el.style.left,
                oldRsLeft = el.runtimeStyle.left;
              el.runtimeStyle.left = el.currentStyle.left;
              el.style.left = value || 0;
              value = el.style.pixelLeft + 'px';
              el.style.left = oldLeft;
              el.runtimeStyle.left = oldRsLeft;
              return value;
            })(val);
          }
          return val;
        }
      }
    },
    slide: function (el, dir, speed = 600, callback, recalcMaxHeight) {
      el = _getElement(el);
      if (
        !el ||
        (dir === 'up' && !KTUtil.visible(el)) ||
        (dir === 'down' && KTUtil.visible(el))
      )
        return;

      const calcHeight = KTUtil.actualHeight(el);
      let calcPaddingTop = KTUtil.data(el).get('slide-padding-top');
      let calcPaddingBottom = KTUtil.data(el).get('slide-padding-bottom');

      if (!calcPaddingTop && KTUtil.css(el, 'padding-top')) {
        KTUtil.data(el).set('slide-padding-top', KTUtil.css(el, 'padding-top'));
        calcPaddingTop = parseInt(KTUtil.data(el).get('slide-padding-top'));
      }

      if (!calcPaddingBottom && KTUtil.css(el, 'padding-bottom')) {
        KTUtil.data(el).set('slide-padding-bottom', KTUtil.css(el, 'padding-bottom'));
        calcPaddingBottom = parseInt(KTUtil.data(el).get('slide-padding-bottom'));
      }

      if (dir === 'up') {
        el.style.cssText = 'display: block; overflow: hidden;';
        KTUtil.animate(
          0,
          calcPaddingTop,
          speed,
          value => (el.style.paddingTop = calcPaddingTop - value + 'px')
        );
        KTUtil.animate(
          0,
          calcPaddingBottom,
          speed,
          value => (el.style.paddingBottom = calcPaddingBottom - value + 'px')
        );
        KTUtil.animate(
          0,
          calcHeight,
          speed,
          value => (el.style.height = calcHeight - value + 'px'),
          'linear',
          () => {
            if (callback) callback();
            el.style.height = '';
            el.style.display = 'none';
          }
        );
      } else if (dir === 'down') {
        el.style.cssText = 'display: block; overflow: hidden;';
        KTUtil.animate(
          0,
          calcPaddingTop,
          speed,
          value => (el.style.paddingTop = value + 'px'),
          'linear',
          () => (el.style.paddingTop = '')
        );
        KTUtil.animate(
          0,
          calcPaddingBottom,
          speed,
          value => (el.style.paddingBottom = value + 'px'),
          'linear',
          () => (el.style.paddingBottom = '')
        );
        KTUtil.animate(
          0,
          calcHeight,
          speed,
          value => (el.style.height = value + 'px'),
          'linear',
          () => {
            if (callback) callback();
            el.style.height = '';
            el.style.display = '';
            el.style.overflow = '';
          }
        );
      }
    },
    slideUp: function (el, speed, callback) {
      KTUtil.slide(el, 'up', speed, callback);
    },
    slideDown: function (el, speed, callback) {
      KTUtil.slide(el, 'down', speed, callback);
    },
    show: function (el, display = 'block') {
      el = _getElement(el);
      if (el) el.style.display = display;
    },
    hide: function (el) {
      el = _getElement(el);
      if (el) el.style.display = 'none';
    },
    addEvent: function (el, type, handler) {
      el = _getElement(el);
      if (el) el.addEventListener(type, handler);
    },
    removeEvent: function (el, type, handler) {
      el = _getElement(el);
      if (el) el.removeEventListener(type, handler);
    },
    on: function (element, selector, event, handler) {
      element = _getElement(element);
      if (!element || !selector) return;
      const eventId = KTUtil.getUniqueID('event');
      KTUtilDelegatedEventHandlers[eventId] = e => {
        const targets = element.querySelectorAll(selector);
        let target = e.target;
        while (target && target !== element) {
          for (let i = 0, j = targets.length; i < j; i++) {
            if (target === targets[i]) handler.call(target, e);
          }
          target = target.parentNode;
        }
      };
      KTUtil.addEvent(element, event, KTUtilDelegatedEventHandlers[eventId]);
      return eventId;
    },
    off: function (element, event, eventId) {
      element = _getElement(element);
      if (!element || !KTUtilDelegatedEventHandlers[eventId]) return;
      KTUtil.removeEvent(element, event, KTUtilDelegatedEventHandlers[eventId]);
      delete KTUtilDelegatedEventHandlers[eventId];
    },
    one: function (el, type, callback) {
      el = _getElement(el);
      if (!el) return;
      el.addEventListener(type, function callee(e) {
        e.target.removeEventListener(e.type, callee);
        return callback(e);
      });
    },
    hash: function (str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        const chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
      }
      return hash;
    },
    animateClass: function (el, animationName, callback) {
      el = _getElement(el);
      if (!el) return;
      const animations = {
        animation: 'animationend',
        OAnimation: 'oAnimationEnd',
        MozAnimation: 'mozAnimationEnd',
        WebkitAnimation: 'webkitAnimationEnd',
        msAnimation: 'msAnimationEnd',
      };
      let animation;
      for (let t in animations) {
        if (el.style[t] !== undefined) {
          animation = animations[t];
          break;
        }
      }
      KTUtil.addClass(el, 'animated ' + animationName);
      KTUtil.one(el, animation, () => KTUtil.removeClass(el, 'animated ' + animationName));
      if (callback) KTUtil.one(el, animation, callback);
    },
    transitionEnd: function (el, callback) {
      el = _getElement(el);
      if (!el) return;
      const transitions = {
        transition: 'transitionend',
        OTransition: 'oTransitionEnd',
        MozTransition: 'mozTransitionEnd',
        WebkitTransition: 'webkitTransitionEnd',
        msTransition: 'msTransitionEnd',
      };
      let transition;
      for (let t in transitions) {
        if (el.style[t] !== undefined) {
          transition = transitions[t];
          break;
        }
      }
      KTUtil.one(el, transition, callback);
    },
    animationEnd: function (el, callback) {
      el = _getElement(el);
      if (!el) return;
      const animations = {
        animation: 'animationend',
        OAnimation: 'oAnimationEnd',
        MozAnimation: 'mozAnimationEnd',
        WebkitAnimation: 'webkitAnimationEnd',
        msAnimation: 'msAnimationEnd',
      };
      let animation;
      for (let t in animations) {
        if (el.style[t] !== undefined) {
          animation = animations[t];
          break;
        }
      }
      KTUtil.one(el, animation, callback);
    },
    animateDelay: function (el, value) {
      el = _getElement(el);
      if (!el) return;
      const vendors = ['webkit-', 'moz-', 'ms-', 'o-', ''];
      vendors.forEach(vendor => KTUtil.css(el, vendor + 'animation-delay', value));
    },
    animateDuration: function (el, value) {
      el = _getElement(el);
      if (!el) return;
      const vendors = ['webkit-', 'moz-', 'ms-', 'o-', ''];
      vendors.forEach(vendor => KTUtil.css(el, vendor + 'animation-duration', value));
    },
    scrollTo: function (target, offset, duration = 500) {
      target = _getElement(target);
      const targetPos = target ? KTUtil.offset(target).top : 0;
      const scrollPos =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      const from = scrollPos + (offset || 0);
      KTUtil.animate(from, targetPos, duration, value => {
        document.documentElement.scrollTop = value;
        document.body.parentNode.scrollTop = value;
        document.body.scrollTop = value;
      });
    },
    scrollTop: function (offset, duration) {
      KTUtil.scrollTo(null, offset, duration);
    },
    isArray: function (obj) {
      return obj && Array.isArray(obj);
    },
    ready: function (callback) {
      if (
        document.attachEvent
          ? document.readyState === 'complete'
          : document.readyState !== 'loading'
      ) {
        callback();
      } else {
        document.addEventListener('DOMContentLoaded', callback);
      }
    },
    isEmpty: function (obj) {
      return !Object.keys(obj).length;
    },
    numberString: function (nStr) {
      nStr += '';
      const x = nStr.split('.');
      let x1 = x[0];
      const x2 = x.length > 1 ? '.' + x[1] : '';
      const rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
      }
      return x1 + x2;
    },
    detectIE: function () {
      const ua = window.navigator.userAgent;
      const msie = ua.indexOf('MSIE ');
      if (msie > 0) return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      const trident = ua.indexOf('Trident/');
      if (trident > 0) {
        const rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }
      const edge = ua.indexOf('Edge/');
      if (edge > 0) return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      return false;
    },
    isRTL: function () {
      return KTUtil.attr(document.documentElement, 'direction') === 'rtl';
    },
    scrollInit: function (element, options) {
      element = _getElement(element);
      if (!element) return;
      const init = () => {
        let ps;
        let height =
          typeof options.height === 'function'
            ? parseInt(options.height())
            : parseInt(options.height);
        if (
          (options.mobileNativeScroll || options.disableForMobile) &&
          KTUtil.isInResponsiveRange('tablet-and-mobile')
        ) {
          ps = KTUtil.data(element).get('ps');
          if (ps) {
            if (options.resetHeightOnDestroy) {
              KTUtil.css(element, 'height', 'auto');
            } else {
              KTUtil.css(element, 'overflow', 'auto');
              if (height > 0) KTUtil.css(element, 'height', height + 'px');
            }
            ps?.destroy?.();
            ps = KTUtil.data(element).remove('ps');
          } else if (height > 0) {
            KTUtil.css(element, 'overflow', 'auto');
            KTUtil.css(element, 'height', height + 'px');
          }
          return;
        }
        if (height > 0) KTUtil.css(element, 'height', height + 'px');
        if (options.desktopNativeScroll) {
          KTUtil.css(element, 'overflow', 'auto');
          return;
        }
        KTUtil.css(element, 'overflow', 'hidden');
        ps = KTUtil.data(element).get('ps');
        if (ps) {
          ps.update();
        } else {
          KTUtil.addClass(element, 'kt-scroll');
          ps = new PerfectScrollbar(element, {
            wheelSpeed: 0.5,
            swipeEasing: true,
            wheelPropagation: options.windowScroll !== false,
            minScrollbarLength: 40,
            maxScrollbarLength: 300,
            suppressScrollX: KTUtil.attr(element, 'data-scroll-x') !== 'true',
          });
          KTUtil.data(element).set('ps', ps);
        }
        const uid = KTUtil.attr(element, 'id');
        if (options.rememberPosition === true && Cookies && uid) {
          if (Cookies.get(uid)) {
            const pos = parseInt(Cookies.get(uid));
            if (pos > 0) element.scrollTop = pos;
          }
          element.addEventListener('ps-scroll-y', () => Cookies.set(uid, element.scrollTop));
        }
      };
      init();
      if (options.handleWindowResize) KTUtil.addResizeHandler(init);
    },
    scrollUpdate: function (element) {
      element = _getElement(element);
      const ps = KTUtil.data(element).get('ps');
      if (ps) ps.update();
    },
    scrollUpdateAll: function (parent) {
      parent = _getElement(parent);
      const scrollers = KTUtil.findAll(parent, '.ps');
      scrollers.forEach(scroll => KTUtil.scrollUpdate(scroll));
    },
    scrollDestroy: function (element) {
      element = _getElement(element);
      const ps = KTUtil.data(element).get('ps');
      if (ps) {
        ps?.destroy?.();
        KTUtil.data(element).remove('ps');
      }
    },
    setHTML: function (el, html) {
      el = _getElement(el);
      if (el) el.innerHTML = html;
    },
    getHTML: function (el) {
      el = _getElement(el);
      return el ? el.innerHTML : '';
    },
    getDocumentHeight: function () {
      const body = document.body;
      const html = document.documentElement;
      return Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
    },
    getScrollTop: function () {
      return (document.scrollingElement || document.documentElement).scrollTop;
    },
  };
})();

// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = KTUtil;
}

// Initialize KTUtil class on document ready
KTUtil.ready(function () {
  KTUtil.init();
});

// CSS3 Transitions only after page load(.kt-page-loading class added to body tag and remove with JS on page load)
window.onload = function () {
  KTUtil.removeClass(KTUtil.get('body'), 'kt-page--loading');
};

export default KTUtil;
