import React, { useState } from 'react';
import { Button, ButtonGroup, Card, CardContent, Typography, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';

import { groupBy } from 'lodash';
import { API_DOMAIN } from '../../../../constants';
import { ICart, ICartItem, IGuestCart, IGuestCartItem } from '../interfaces';
import homeStyles from '../../homeStyles';
import { IWorkingTime } from '../../../../interfaces/store';
import { thousands } from '../../../../utils/utils';
import AlertCompanyDetail from '../../products/components/AlertCompanyDetail';
import { ICompany } from '../../../../interfaces/companies';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    buttons: {
      marginRight: theme.spacing(3),
    },
    link: {
      fontSize: 20,
      color: '#000000 !important',
    },
    card: {
      width: '97%',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: 20,
      },
    },
    categoryTree: {
      maxWidth: 400,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      maxHeight: 100,
      cursor: 'pointer',

      [theme.breakpoints.down('sm')]: {
        maxHeight: 150,
      },
    },
    totalCard: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '30%',
      },
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'row',
      // alignItems: 'center',
    },
    infoWrapper: {
      marginLeft: 20,
      width: '100%',
    },
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      height: 50,
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    buttonBackground: {
      width: 30,
      height: 30,
      backgroundColor: '#d01c1f',
      borderRadius: 1,
      textAlign: 'center',
      opacity: 0.65,
      position: 'absolute',
      top: 0,
      left: 0,
    },
    buttonsGroup: {
      marginLeft: 15,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    infoButtonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
      },
    },
    imgContainer: {
      height: 400,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        height: 200,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 350,
        height: 250,
      },
    },
    name: {
      fontSize: 20,
      fontWeight: 600,
      margin: 0,

      [theme.breakpoints.up('md')]: {
        fontSize: 34,
        lineHeight: 1,
      },
    },
    listText: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 'bold',
      color: '#000000',
      paddingLeft: 10,
    },
    listKey: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#757575',
    },
    imgCom: {
      maxWidth: 90,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 75,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: 85,
      },
      cursor: 'pointer',
    },
    cansel_btn: {
      width: 25,
      height: 25,
      backgroundColor: '#fd397a',
      borderRadius: 4,
      position: 'absolute',
      top: -10,
      left: -10,
    },
  })
);

const CategoriesListPage: React.FC<{
  cart: ICart | IGuestCart;
  setProductCount: (data: { product_id: number; count: number }) => void;
  setCountGuestCart: (data: {
    product_id: number;
    count: number;
    type: 'dec' | 'inc';
  }) => void;
  handleImage?: (url?: string) => void;
  setProductCountLoading: boolean;
  isAuthorized: boolean;
  isAdmin?: boolean;
  location?: {
    address: string;
    lng: number;
    lat: number;
    city: string;
    country: string;
    house: string;
    province: string;
    street: string;
    working_times?: IWorkingTime[];
  };
  alertWindow?: any;
}> = ({ cart, setProductCount, isAuthorized, setCountGuestCart, isAdmin, handleImage }) => {
  const classes = useStyles();
  const homeClasses = homeStyles();
  const intl = useIntl();
  const [isOpenFileDialog, setOpenFileDialog] = useState(false);
  const [com, setCompany] = useState<ICompany>();

  const groupedByCompany = groupBy(cart?.items, item => item.product.company?.id);

  const openModalCompany = (company?: ICompany) => {
    setCompany(company);
    setOpenFileDialog(true);
  };

  const getTotalPriceByCompany = (items: ICartItem[] | IGuestCartItem[]): number => {
    let total = 0;
    items.forEach(item => {
      total += +item.product.price * item.count;
    });
    return total;
  };

  return (
    <div>
      {groupedByCompany &&
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(groupedByCompany).map(([_cartKey, items]) => {
          const totalPriceByCompany = getTotalPriceByCompany(items);

          return (
            <>
              <Card
                className={classes.card}
                style={{
                  paddingLeft: 0,
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  marginBottom: 0,
                }}
                onClick={() => openModalCompany(items[0].product?.company)}
              >
                <div
                  style={{
                    padding: 20,
                    borderRadius: 4,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ display: 'flex', width: '70%' }}>
                    <div style={{ paddingLeft: 0 }}>
                      <p className={classes.name}>
                        <b>{items[0].product.company?.brand}</b>
                      </p>

                      <p className={classes.listKey}>{items[0].product.company?.name}</p>

                      {/* <p className={classes.listKey}>12 км от вас</p> */}
                    </div>
                  </div>

                  <div
                    style={{
                      width: '25%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <p className={classes.listKey}>О продавце</p>
                  </div>
                </div>
              </Card>

              {items.map((item, index) => {
                const isLastItem = index === items.length - 1;

                return (
                  <Card
                    className={classes.card}
                    style={{ paddingLeft: 0, marginTop: 6, marginBottom: isLastItem ? 20 : 0 }}
                  >
                    <CardContent
                      className={homeClasses.classes.tableContainer}
                      style={{
                        paddingLeft: 20,
                        paddingTop: 25,
                      }}
                    >
                      <div className={classes.contentWrapper}>
                        <div style={{ position: 'relative' }}>
                          {item.product.photos && item.product.photos.length > 0 ? (
                            <img
                              alt=''
                              onClick={() =>
                                item.product.photos &&
                                handleImage &&
                                handleImage(
                                  `${API_DOMAIN}/${
                                    item?.product?.photos?.find(el => el.main)?.small ||
                                    item.product.photos[0].small ||
                                    ''
                                  }`
                                )
                              }
                              src={`${API_DOMAIN}/${
                                item?.product?.photos?.find(el => el.main)?.small ||
                                item.product.photos[0].small
                              }`}
                              className={classes.img}
                            />
                          ) : (
                            <img
                              alt=''
                              onClick={() =>
                                item.product.photos &&
                                handleImage &&
                                handleImage(
                                  `${API_DOMAIN}/${
                                    item?.product?.photos?.find(el => el.main)?.small ||
                                    item.product.photos[0].small ||
                                    ''
                                  }`
                                )
                              }
                              src='../../../images/placeholder.png'
                              className={classes.img}
                            />
                          )}

                          <div className={classes.cansel_btn}>
                            <CloseIcon
                              style={{
                                color: '#ffffff',
                                fontSize: 24,
                                marginTop: 1.2,
                                cursor: 'pointer',
                                marginLeft: 0.5,
                              }}
                              onClick={() => {
                                isAuthorized
                                  ? setProductCount({
                                      product_id: item.product.id || 0,
                                      count: 0,
                                    })
                                  : setCountGuestCart({
                                      product_id: item.product.id || 0,
                                      count: 0,
                                      type: 'dec',
                                    });
                              }}
                            />
                          </div>
                        </div>

                        <div className={classes.infoWrapper}>
                          <div className={classes.infoButtonsWrapper}>
                            <div
                              style={{
                                width: 250,
                                overflow: 'hidden',
                              }}
                            >
                              <Link
                                to={`${
                                  isAdmin
                                    ? `/products/edit/${item.product.id}`
                                    : `/viewproduct/${item.product.id}`
                                }`}
                                className={classes.link}
                              >
                                {item.product.name}
                              </Link>
                            </div>

                            <div>
                              <Typography
                                style={{
                                  color: '#40271e',
                                  fontSize: 21,
                                  minHeight: 27,
                                  fontWeight: 'bold',
                                }}
                              >
                                {thousands(String(+item.product.price * item.count))} руб.
                              </Typography>
                              <Typography
                                style={{
                                  color: '#000000',
                                  fontSize: 15,
                                  minHeight: 32,
                                  fontWeight: 'normal',
                                }}
                              >
                                Сумма
                              </Typography>

                              <Typography
                                style={{
                                  color: '#000000',
                                  fontSize: 21,
                                  minHeight: 27,
                                  fontWeight: 'normal',
                                }}
                              >
                                {item.product.price} руб.
                              </Typography>
                              <Typography
                                style={{
                                  color: '#000000',
                                  fontSize: 15,
                                  minHeight: 20,
                                  fontWeight: 'normal',
                                }}
                              >
                                {item.product.unit
                                  ? `Цена за ${item.product.unit}`
                                  : 'Цена за 1 шт.'}
                              </Typography>
                              {/* <Typography
                              style={{
                                color: '#757575',
                                fontSize: 17,
                              }}
                            >
                              Вес: {item?.product?.stock_info?.weight || 0} гр.
                            </Typography> */}
                            </div>
                            <div className={classes.buttonsWrapper}>
                              <div className={classes.buttonsGroup}>
                                <ButtonGroup
                                  color='primary'
                                  aria-label='outlined primary button group'
                                >
                                  <Button
                                    style={{
                                      backgroundColor: '#369ff7',
                                      color: 'white',
                                      borderRadius: '4px',
                                      opacity: 0.65,
                                    }}
                                    // disabled={setProductCountLoading}
                                    onClick={() =>
                                      isAuthorized
                                        ? setProductCount({
                                            product_id: item.product.id || 0,
                                            count: +item.count - 1,
                                          })
                                        : setCountGuestCart({
                                            product_id: item.product.id || 0,
                                            count: +item.count - 1,
                                            type: 'dec',
                                          })
                                    }
                                  >
                                    <RemoveIcon fontSize='small' />
                                  </Button>
                                  <Button
                                    disabled
                                    style={{
                                      color: '#000',
                                      borderColor: 'white',
                                      borderLeft: 'none',
                                      borderRight: 'none',
                                      margin: '0 2px',
                                      fontWeight: 'bold',
                                      fontSize: 15,
                                    }}
                                  >
                                    {item.count}
                                  </Button>
                                  <Button
                                    style={{
                                      backgroundColor: '#369ff7',
                                      color: 'white',
                                      borderRadius: '4px',
                                      opacity: 0.65,
                                    }}
                                    // disabled={setProductCountLoading}
                                    onClick={() => {
                                      if (isAuthorized) {
                                        // if (
                                        //   +(item?.product?.stock_info?.available_quantity || 0) >
                                        //   +item.count
                                        // ) {
                                        setProductCount({
                                          product_id: item.product.id || 0,
                                          count: +item.count + 1,
                                          // (item?.product?.stock_info?.available_quantity || 0) >
                                          // +item.count
                                          //   ? +item.count + 1
                                          //   : item.product?.stock_info?.available_quantity || 0,
                                        });
                                        // } else {
                                        //   alertWindow();
                                        // }
                                      } else {
                                        // if (
                                        //   +(item?.product?.stock_info?.available_quantity || 0) >
                                        //   +item.count
                                        // )
                                        setCountGuestCart({
                                          product_id: item.product.id || 0,
                                          count: +item.count + 1,
                                          // (item?.product?.stock_info?.available_quantity || 0) >
                                          // +item.count
                                          //   ? +item.count + 1
                                          //   : item.product?.stock_info?.available_quantity || 0,
                                          type: 'inc',
                                        });
                                        // } else {
                                        //   alertWindow();
                                        // }
                                      }
                                    }}
                                  >
                                    <AddIcon style={{ color: '#fffff' }} fontSize='small' />
                                  </Button>
                                </ButtonGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                );
              })}
              <Card className={classes.card} style={{ paddingLeft: 0, marginTop: 6 }}>
                <CardContent
                  style={{
                    paddingLeft: 20,
                    paddingTop: 25,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{
                      color: '#757575',
                      fontSize: 21,
                      minHeight: 27,
                      fontWeight: 'normal',
                    }}
                  >
                    ИТОГО ПО ПРОДАВЦУ:
                  </Typography>

                  <Typography
                    style={{
                      color: '#000000',
                      fontSize: 21,
                      minHeight: 27,
                      fontWeight: 'bold',
                      marginLeft: 10,
                    }}
                  >
                    {thousands(String(totalPriceByCompany))} руб.
                  </Typography>
                </CardContent>
              </Card>
            </>
          );
        })}

      <AlertCompanyDetail
        open={isOpenFileDialog}
        message={intl.formatMessage({ id: 'FILE.IMPORT.ALERT.TEXT' })}
        company={com}
        handleClose={() => {
          setOpenFileDialog(false);
        }}
      />
    </div>
  );
};

export default CategoriesListPage;
