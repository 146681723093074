import React, { useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { API_DOMAIN } from '../../../../constants';
import { useStyles } from '../hooks/useStylesFiles';
import { toAbsoluteUrl } from '../../../../../_base';
import DropZoneBtn from './DropZoneBtn';
import { useFormatMessage } from '../../../../hooks';
import { IFile } from '../../../../interfaces/file';

interface IProps {
  files: IFile[] | undefined;
  isEditFiles: boolean;
  uploadFile: (file: IFile) => void;
  deleteFile: (id: number | null) => void;
  upload?: boolean;
}

const UploadFilesForm: React.FC<IProps> = ({
  files,
  isEditFiles,
  uploadFile,
  deleteFile,
  upload,
}) => {
  const attachmentTypes = {
    DOCUMENT_1: 'Документ 1',
    DOCUMENT_2: 'Документ 2',
    DOCUMENT_3: 'Документ 3',
    DOCUMENT_4: 'Документ 4',
    DOCUMENT_5: 'Документ 5',
    DOCUMENT_6: 'Документ 6',
    DOCUMENT_7: 'Документ 7',
    DOCUMENT_8: 'Документ 8',
    DOCUMENT_9: 'Документ 9',
    DOCUMENT_10: 'Документ 10',
  };
  const fileTypes = useMemo(
    () => attachmentTypes && Object.entries(attachmentTypes),
    [attachmentTypes]
  );
  const [currentType, setCurrentType] = useState('');
  const fm = useFormatMessage();
  const classes = useStyles();

  return (
    <div
      style={{
        width: '100%',
        padding: !upload ? 24 : 0,
        maxWidth: 800,
      }}
    >
      {!upload && (
        <Typography component='h6' variant='h6' className={classes.titleFile}>
          {fm('PRODUCT.FILES.TITLE')}
        </Typography>
      )}
      {fileTypes &&
        fileTypes.map((item: any, index: any) => {
          const fileIsAvailable = files?.find((_, ind) => ind === index);
          if (upload) {
            return (
              fileIsAvailable && (
                <div className={classes.wrapperFile} style={{ padding: 0 }}>
                  <div className={classes.wrapperBlocks}>
                    <div className={classes.fileInfo}>
                      <b className={classes.fileType}>{item[1]}</b>
                      {fileIsAvailable && (
                        <>
                          {fileIsAvailable.path ? (
                            <a
                              href={`${API_DOMAIN}${fileIsAvailable.path}`}
                              download
                              target='_blank'
                              rel='noreferrer'
                              className={classes.fileName}
                            >
                              {fileIsAvailable.title}
                            </a>
                          ) : (
                            <b className={classes.fileName}>{fileIsAvailable.title}</b>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className={classes.wrapperBlocks}>
                    {fileIsAvailable?.path && (
                      <a
                        href={`${API_DOMAIN}${fileIsAvailable.path}`}
                        download
                        target='_blank'
                        rel='noreferrer'
                      >
                        <div className={classes.fileBtn}>
                          <img alt='check' src={toAbsoluteUrl(`/media/logos/download.svg`)} />
                        </div>
                      </a>
                    )}
                  </div>
                </div>
              )
            );
          }
          return (
            <div className={classes.wrapperFile}>
              <div className={classes.wrapperBlocks} style={{ marginRight: '2%' }}>
                <div className={classes.wrapperBlock}>
                  {fileIsAvailable ? (
                    <img alt='check' src={toAbsoluteUrl(`/media/logos/checkTab.svg`)} />
                  ) : (
                    <b className={classes.indexFile}>{index + 1}</b>
                  )}
                </div>
                <div className={classes.fileInfo}>
                  <b className={classes.fileType}>{item[1]}</b>
                  {fileIsAvailable && (
                    <>
                      {fileIsAvailable.path ? (
                        <a
                          href={`${API_DOMAIN}${fileIsAvailable.path}`}
                          download
                          target='_blank'
                          rel='noreferrer'
                          className={classes.fileName}
                        >
                          {fileIsAvailable.title}
                        </a>
                      ) : (
                        <b className={classes.fileName}>{fileIsAvailable.title}</b>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className={classes.wrapperBlocks}>
                {fileIsAvailable?.path && (
                  <a
                    href={`${API_DOMAIN}${fileIsAvailable.path}`}
                    download
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className={classes.fileBtn}>
                      <img alt='check' src={toAbsoluteUrl(`/media/logos/download.svg`)} />
                    </div>
                  </a>
                )}
                {isEditFiles && fileIsAvailable && (
                  <div
                    onClick={() => {
                      deleteFile(fileIsAvailable?.id);
                    }}
                    className={classes.fileBtn}
                    style={{ backgroundColor: 'rgba(253, 57, 122,0.1)' }}
                  >
                    <img alt='check' src={toAbsoluteUrl(`/media/logos/delete.svg`)} />
                  </div>
                )}
                {!fileIsAvailable && isEditFiles && (
                  <DropZoneBtn
                    item={item}
                    currentType={currentType}
                    setCurrentType={setCurrentType}
                    uploadFiles={uploadFile}
                  />
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default React.memo(UploadFilesForm);
