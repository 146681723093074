import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllProducts } from '../../../../crud/product.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { useFormatMessage } from '../../../../hooks';

export const useGetProducts: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const fm = useFormatMessage();

  const fetchProducts = useCallback(async () => {
    setLoading(true);
    getAllProducts()
      .then(res => {
        setProducts(res.data.data);
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  return [fetchProducts, products, loading];
};
