import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    actions: {
      marginTop: theme.spacing(3),
    },
    cancelBtn: {
      marginRight: theme.spacing(2),
    },
  })
);
