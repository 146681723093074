import { IOrderType } from '../../../interfaces/order';

export enum ListType {
  ABANDONED = 'abandoned',
  FULL = 'full',
  HISTORY = 'history',
}
export const orderTypeList: string[] = [
  'new',
  'formation',
  'ready_to_delivery',
  'in_processing',
  'payed',
  'canceled',
  'completed',
];
export const requestTypeList: string[] = ['new', 'in_work', 'completed'];
export const orderStatusList: string[] = ['paid', 'not_paid'];
export const getOrderTypeWithTranslates = (
  translates: {
    [type: string]: string;
  },
  isFull: boolean
): IOrderType[] => {
  const orderTypes: IOrderType[] = orderTypeList.map(item => ({
    type: item,
    translate: translates[item],
  }));
  if (isFull) {
    orderTypes.unshift({ type: 'cart', translate: translates.cart });
  }
  return orderTypes;
};

export const getRequestTypeWithTranslates = (
  translates: {
    [type: string]: string;
  },
  isFull: boolean
): IOrderType[] => {
  const requestTypes: IOrderType[] = requestTypeList.map(item => ({
    type: item,
    translate: translates[item],
  }));
  if (isFull) {
    requestTypes.unshift({ type: 'cart', translate: translates.cart });
  }
  return requestTypes;
};

export const getStatusTypeWithTranslates = (
  translates: {
    [type: string]: string;
  },
  isFull: boolean
): IOrderType[] => {
  const orderTypes: IOrderType[] = orderStatusList.map(item => ({
    type: item,
    translate: translates[item],
  }));
  if (isFull) {
    orderTypes.unshift({ type: 'cart', translate: translates.cart });
  }
  return orderTypes;
};
