import { makeStyles } from 'tss-react/mui';

export const useOrderTableStyles = makeStyles()(theme => {
  return {
    container: {
      display: 'flex',
      padding: theme.spacing(1),
    },
    text: {
      fontSize: 14,
      flex: 1,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    imgContainer: {
      width: 50,
      height: 50,
      borderRadius: '2px',
      backgroundColor: theme.palette.secondary.dark,
    },
    img: {
      width: '50px',
      maxHeight: 65,
      cursor: 'pointer',
      borderRadius: 4,
      [theme.breakpoints.down('sm')]: {
        maxHeight: 80,
      },
    },
    card: {
      width: '97%',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: 20,
      },
      background: theme.palette.grey[100],
    },
    name: {
      fontSize: 20,
      fontWeight: 600,
      margin: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: 34,
        lineHeight: 1,
      },
    },
    listKey: {
      marginBottom: 0,
      fontSize: 17,
      fontWeight: 400,
      color: '#757575',
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    infoWrapper: {
      marginLeft: 20,
      width: '100%',
      [theme.breakpoints.down(365)]: {
        marginLeft: 13,
      },
      [theme.breakpoints.down(350)]: {
        marginLeft: 4,
      },
    },
    cansel_btn: {
      width: 25,
      height: 25,
      backgroundColor: '#efa66c',
      borderRadius: 4,
      position: 'absolute',
      top: -10,
      left: -10,
    },
    link: {
      marginRight: theme.spacing(3),
      fontSize: 20,
      color: '#000000',
      fontWeight: 'normal',
      height: 32,
    },
    infoButtonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'start',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    btnView: {
      display: 'flex',
      width: 40,
      height: 34,
      backgroundColor: '#40271e',
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4,
    },
    viewICon: {
      width: 22,
      height: 22,
      color: '#ffffff',
    },
    typeList: {
      backgroundColor: 'white',
      maxWidth: 200,
    },
  };
});
