import React, { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';

const AnimateLoading: React.FC = () => {
  const location = useLocation();
  const [stopAnimateState, setStopAnimate] = React.useState(false);
  const widthRef = useRef(0);
  let stopAnimateTimeout: NodeJS.Timeout;
  let animateTimeout: NodeJS.Timeout;

  const stopAnimate = useCallback(() => {
    setStopAnimate(true);
    stopAnimateTimeout = setTimeout(() => {
      widthRef.current = 0;
    }, 300);
  }, []);

  const animate = useCallback(() => {
    animateTimeout = setTimeout(() => {
      if (widthRef.current <= 100) {
        setStopAnimate(false);
        widthRef.current = widthRef.current + 10;
        animate();
      } else {
        stopAnimate();
      }
    }, 30);
  }, [widthRef.current]);

  useEffect(() => {
    animate();
  }, [location]);

  useEffect(() => {
    return () => {
      stopAnimateTimeout && clearTimeout(stopAnimateTimeout);
      animateTimeout && clearTimeout(animateTimeout);
    };
  }, []);

  return (
    <div className='header-progress-bar' style={{ height: '3px', width: '100%' }}>
      {widthRef.current > 0 && !stopAnimateState && (
        <ProgressBar now={50} style={{ height: '3px' }} />
      )}
    </div>
  );
};

export default AnimateLoading;
