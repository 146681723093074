import React, { useLayoutEffect } from 'react';
import { Typography, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import csLocale from 'date-fns/locale/cs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ButtonWithLoader from '../../../../components/other/Buttons/ButtonWithLoader';

import homeStyles from '../../../../constants/homeStyles';
import { useStylesPromocodeEdit } from '../hooks/useStyles';
import { usePromocodeSettings } from '../hooks/usePromocodeSettings';
import { useEditPromocodeSettings } from '../hooks/useEditPromocodeSettings';

const getInitialValues = (setting?: { amount: string; bonus: string }) => ({
  amount: setting?.amount || 0,
  bonus: setting?.bonus || 0,
});

export interface IProps {
  handleChangeRange: (start: string | Date, end: string | Date) => void;
  startDate: string | Date;
  endDate: string | Date;
}

const SettingsPromocodes: React.FC<IProps> = ({ handleChangeRange, startDate, endDate }) => {
  const classes = useStylesPromocodeEdit();
  const homeClasses = homeStyles();
  const intl = useIntl();
  const [getSettings, settings, loadingSettings] = usePromocodeSettings();
  const [editSetting, loadingEdit] = useEditPromocodeSettings();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(settings),
    onSubmit: submitValues => {
      editSetting(submitValues);
    },
  });

  useLayoutEffect(() => {
    getSettings();
  }, []);

  const locales: { [key: string]: any } = {
    ru: ruLocale,
    en: enLocale,
    cz: csLocale,
  };

  return (
    <div className={homeClasses.classes.form}>
      <Typography variant='h6' className={classes.title}>
        {intl.formatMessage({ id: 'SUBMENU.PROMOCODES.DATE.RANGE' })}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locales[intl.locale]}>
        <DatePicker
          // id='received_at'
          // variant='inline'
          className={classes.input}
          // margin='normal'
          value={startDate ? dayjs(startDate) : null}
          onChange={date => date && handleChangeRange(dayjs(date).toString(), endDate)}
          // autoOk
          // error={Boolean(startDate && startDate)}
          // helperText={startDate && startDate}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locales[intl.locale]}>
        <DatePicker
          // id='received_at'
          // variant='inline'
          className={classes.input}
          // margin='normal'
          value={endDate ? dayjs(endDate) : null}
          onChange={date => date && handleChangeRange(startDate, dayjs(date).toString())}
          // autoOk
          // error={Boolean(endDate && endDate)}
          // helperText={endDate && endDate}
        />
      </LocalizationProvider>
      <Typography variant='h6' className={classes.title}>
        {intl.formatMessage({ id: 'SUBMENU.PROMOCODES.REFFERAL.PARAMETER' })}
      </Typography>
      {!loadingSettings && (
        <>
          <TextField
            type='number'
            label={intl.formatMessage({ id: 'PROMOCODE.SETTINGS.AMOUNT' })}
            margin='normal'
            name='amount'
            value={values.amount}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.amount && errors.amount}
            error={Boolean(touched.amount && errors.amount)}
          />
          <TextField
            type='number'
            label={intl.formatMessage({ id: 'PROMOCODE.SETTINGS.BONUS' })}
            margin='normal'
            name='bonus'
            value={values.bonus}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.bonus && errors.bonus}
            error={Boolean(touched.bonus && errors.bonus)}
          />
          <div className={classes.actions}>
            <ButtonWithLoader
              loading={loadingEdit}
              disabled={loadingEdit}
              onPress={handleSubmit}
            >
              {intl.formatMessage({ id: 'USER.BUTTON.EDIT' })}
            </ButtonWithLoader>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(SettingsPromocodes);
