import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Card,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Theme,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { useNavigate } from 'react-router-dom';

import { IAppState } from '../../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../../utils/layout';
import { actions as blogActions } from '../../../../store/ducks/blog.duck';
import ButtonWithLoader from '../../../../components/ui/Buttons/ButtonWithLoader';
import homeStyles from '../../homeStyles';
import useCrudSnackbar from '../../../../hooks/useCrudSnackbar';
import { useFormatMessage, useTabs } from '../../../../hooks';
import FilesDropzone from '../../../../components/formComponents/FilesDropzone';
import { useCustomFormik } from './hooks/useCustomFormik';
import { Tabs } from './components/Tabs';
import { Container } from './components/Container';
import { TabPanel } from '../../../../components/other/Tab/TabPanel';
import CkEditorCustom from '../../../../components/formComponents/CkEditorCustom';

interface IField<T> {
  title: string;
  field: T;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      marginTop: theme.spacing(3),
    },
  })
);

const CreatePublicationPage: React.FC<TPropsFromRedux> = ({
  me,
  loading,
  success,
  error,
  createPublication,
  clearCreatePublication,
}) => {
  const classes = useStyles();
  const homeClasses = homeStyles();
  const navigate = useNavigate();
  const fm = useFormatMessage();

  const [editorState, setEditorState] = useState<any>(null);
  const [tabValue, changeTab] = useTabs();
  const [images, setImages] = useState<File[]>([]);

  const { values, handleBlur, handleChange, errors, touched, handleSubmit } = useCustomFormik({
    submit: createPublication,
    editorState,
    images,
    me,
  });

  const FIELDS: IField<keyof typeof values>[] = [
    { title: fm('title'), field: 'title' },
    { title: fm('description'), field: 'description' },
  ];

  setLayoutSubheader({
    title: fm('SUBMENU.PUBLICATIONS.ADD'),
  });

  setLayoutFooter({ show: true });

  useCrudSnackbar({
    success,
    error,
    clear: clearCreatePublication,
    successMessage: fm('SUCCESS'),
    errorMessage: `${fm('ERROR')}: ${error}`,
    afterSuccess: () => navigate('/blog/all'),
  });
  const TYPE = [
    { label: 'Админский', value: 'admin' },
    { label: 'Партнерский', value: 'partner' },
    { label: 'Пользовательский', value: 'user' },
  ];

  return (
    <Container>
      <Tabs tabValue={tabValue} changeTab={changeTab} />

      <form onSubmit={handleSubmit} className={homeClasses.classes.form} autoComplete='off'>
        <TabPanel value={tabValue} index={0}>
          {FIELDS.map(item => {
            return (
              <TextField
                key={item.field}
                type='text'
                label={item.title}
                margin='normal'
                name={item.field}
                value={values[item.field]}
                multiline={item.field === 'description'}
                rows={item.field === 'description' ? 3 : 0}
                variant='outlined'
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched[item.field] && errors[item.field]}
                error={Boolean(touched[item.field] && errors[item.field])}
              />
            );
          })}
          {me?.is_admin && (
            <TextField
              select
              label={fm('PRODUCT.EDIT.POST.TYPE')}
              margin='normal'
              name='type'
              value={values.type}
              variant='outlined'
              onBlur={handleBlur('type')}
              onChange={handleChange}
            >
              {TYPE.map(typ => (
                <MenuItem key={typ.label?.toString()} value={typ.value}>
                  {typ.label}
                </MenuItem>
              ))}
            </TextField>
          )}
          {me?.is_admin && (
            <FormControlLabel
              control={
                <Switch
                  className={homeClasses.classes.switch}
                  size='small'
                  name='favorite'
                  checked={values.favorite}
                  onChange={handleChange}
                  disabled={!me?.is_admin}
                />
              }
              label={fm('BLOG.EDIT.FAVORITE')}
            />
          )}

          <InputLabel shrink style={{ marginTop: 7 }}>
            {fm('text')}
          </InputLabel>

          <CkEditorCustom html='' setEditorStateCallback={setEditorState} />

          <div className={classes.actions}>
            <ButtonWithLoader disabled={loading} loading={loading}>
              {fm('COMMON.BUTTON.SAVE')}
            </ButtonWithLoader>
          </div>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <div
            style={{
              padding: 40,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {images.length > 0 && (
              <Card style={{ marginBottom: 25 }}>
                <img
                  src={URL.createObjectURL(images[0])}
                  alt=''
                  style={{ maxWidth: 550, maxHeight: 500 }}
                />
              </Card>
            )}
            <FilesDropzone
              uploadFiles={(files: File[]) => setImages(files)}
              avaliableNumberOfFilesToUpload={1}
              title={fm('COMMON.PHOTO.LOAD')}
              withCrop
            />
          </div>
        </TabPanel>
      </form>
    </Container>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    loading: state.blog.createPublicationLoading,
    success: state.blog.createPublicationSuccess,
    error: state.blog.createPublicationError,
  }),
  {
    createPublication: blogActions.createPublicationRequest,
    clearCreatePublication: blogActions.clearCreatePublication,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreatePublicationPage);
