import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, TextField, MenuItem, FormControlLabel, Switch } from '@mui/material';
import { useDefineUserRole, useFormatMessage } from '../../../../../hooks';
import { IAppState } from '../../../../../store/rootDuck';
import NumberFormatPrice from '../../../../../components/NumberFormatPrice';
import { useStylesHeaderAddMultiply } from '../../hooks/useStyles';

type TProps = {
  formik: any;
  i: number;
};

const EditParams: React.FC<TProps> = ({ formik, i }) => {
  const fm = useFormatMessage();
  const classes1 = useStylesHeaderAddMultiply();
  const { classes } = classes1;

  const { me } = useSelector(({ profile }: IAppState) => profile);

  const isVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);

  const { values, handleBlur, handleChange } = formik;
  return (
    <div className={classes.block}>
      <Typography className={classes.title}>{i}</Typography>
      <div className={classes.wrapperFields}>
        <TextField
          type='text'
          label={fm('PRODUCT.EDIT.NAME')}
          color='secondary'
          name={`name_${i}`}
          value={values[`name_${i}`] || ''}
          variant='outlined'
          onBlur={handleBlur(`name_${i}`)}
          onChange={handleChange}
          multiline
          rows={3}
          className={classes.fieldCommon400}
        />
        <TextField
          type='text'
          label={fm('PRODUCT.TABLE.DESCRIPTION')}
          color='secondary'
          name={`description_${i}`}
          value={values[`description_${i}`] || ''}
          variant='outlined'
          onBlur={handleBlur(`description_${i}`)}
          onChange={handleChange}
          multiline
          rows={3}
          className={classes.fieldCommon400}
        />
      </div>
      <div className={classes.wrapperFields}>
        <TextField
          type='text'
          label={fm('PRODUCT.EDIT.PRICE')}
          color='secondary'
          name={`price_${i}`}
          value={values[`price_${i}`] || ''}
          variant='outlined'
          onBlur={handleBlur(`price_${i}`)}
          onChange={handleChange}
          className={classes.fieldCommon130}
          InputProps={{
            inputComponent: NumberFormatPrice as any,
          }}
        />

        {/* <TextField
          type='text'
          label={fm('PRODUCT.VENDOR.PRICE')}
          color='secondary'
          name={`vendor_price_${i}`}
          value={values[`vendor_price_${i}`] || ''}
          variant='outlined'
          onBlur={handleBlur(`vendor_price_${i}`)}
          onChange={handleChange}
          className={classes.fieldCommon130}
          InputProps={{
            inputComponent: NumberFormatPrice as any,
          }}
        /> */}

        <TextField
          type='number'
          label={fm('STOCK')}
          color='secondary'
          name={`stockQuantity_${i}`}
          value={values[`stockQuantity_${i}`] || ''}
          variant='outlined'
          onBlur={handleBlur(`stockQuantity_${i}`)}
          onChange={handleChange}
          className={classes.fieldCommon90}
        />

        <TextField
          select
          label={fm('DELIVERY.TYPE')}
          color='secondary'
          name={`deliveryType_${i}`}
          value={values[`deliveryType_${i}`] || ''}
          variant='outlined'
          onBlur={handleBlur(`deliveryType_${i}`)}
          onChange={handleChange}
          className={classes.fieldCommon135}
        >
          <MenuItem color='secondary' value='in_stock'>
            {fm('DELIVERY.IN.STOCK')}
          </MenuItem>
          <MenuItem color='secondary' value='out_of_stock'>
            {fm('DELIVERY.OUT.STOCK')}
          </MenuItem>
          <MenuItem color='secondary' value='on_order'>
            <div>{fm('DELIVERY.ON.ORDER')}</div>
          </MenuItem>
          {(me?.is_admin || (isVendor && me?.company?.id === 37)) && (
            <MenuItem color='secondary' value='barelton'>
              {fm('DELIVERY.BARELTON')}
            </MenuItem>
          )}
        </TextField>

        <FormControlLabel
          color='secondary'
          className={classes.active}
          control={
            <Switch
              size='small'
              name={`active_${i}`}
              color='secondary'
              checked={values[`active_${i}`] || false}
              onChange={handleChange}
            />
          }
          label={fm('PRODUCT.EDIT.STATUS')}
        />
      </div>
    </div>
  );
};

export default React.memo(EditParams);
