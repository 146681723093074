import React, { useCallback, useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TableCell,
  TextField,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { green } from '@mui/material/colors';
import { IParticipation, ITender } from '../../../../interfaces/order';
import { actions as tendersActions } from '../../../../store/ducks/tenders.duck';
import { IAppState } from '../../../../store/rootDuck';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import homeStyles from '../../homeStyles';
import TablePaginator from '../../../../components/tableComponents/TablePaginator/TablePaginator';
import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import { ColorButton } from '../../../../components/other/Buttons';
import ModalAddParticipation from './ModalAddParticipation';
import { thousands } from '../../../../utils/utils';

const useStyles = makeStyles()(theme => {
  return {
    card: {
      marginBottom: theme.spacing(3),
      boxShadow: 'none !important',
    },
    wrapper: {
      width: '100%',
      backgroundColor: 'white',
      padding: '15px 30px 15px 30px',
      marginTop: 30,
    },
    green: {
      color: '#fff',
      backgroundColor: green[500],
    },
    emptyCompany: {
      width: 40,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    typeList: {
      backgroundColor: 'white',
      maxWidth: 200,
    },
    checkbox: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      marginTop: 8,
    },
    input: {
      backgroundColor: 'rgba(34, 61, 133, 0.1)',
      height: 62,
      border: 'none !important',
      outline: 'none !important',
      borderRadius: 4,
      fontSize: 17,
      paddingTop: 15,
    },
    multilineInput: {
      backgroundColor: 'rgba(34, 61, 133, 0.1)',
      border: 'none !important',
      outline: 'none !important',
      borderRadius: 4,
      fontSize: 17,
      paddingTop: 25,
    },
    inputFocused: {
      backgroundColor: 'white !important',
      outlineColor: '#223d85',
      borderWidth: '1px !important',
    },
    inputLabel: {
      position: 'absolute',
      marginTop: 20,
      marginLeft: 14,
      color: '#5e6278',
      zIndex: 9999,
      fontSize: 12,
      paddingTop: 3,
    },
    notchedOutline: {
      borderWidth: 0,
    },
    productButtonCard: {
      width: '100%',
      backgroundColor: 'white',
      marginTop: 30,
      padding: '0px 30px 10px 30px',
    },
    labelTextField: {
      color: '#5e6278',
    },
    labelTextFieldFocused: {
      marginTop: 15,
      color: '#5e6278',
    },
    labelTextFieldShrink: {
      marginTop: 15,
      color: '#5e6278',
    },
    inputIcon: {
      marginTop: -15,
      cursor: 'pointer',
    },
    selectFocused: {
      '&:focus': {
        backgroundColor: '#FFF',
      },
    },
  };
});

interface IProps {
  participations?: IParticipation[];
  changeStatus: (id: number, status: string) => void;
  handleCreatePart: (submitValues: any, company_id: number, isMain?: boolean) => void;
  formik: any;
  showTable: boolean;
  isEdit: boolean;
  tender?: ITender;
}

const TableParticipations: React.FC<IProps> = ({
  participations,
  changeStatus,
  handleCreatePart,
  formik,
  // isEdit,
  tender,
  showTable,
}) => {
  const fm = useFormatMessage();
  const dispatch = useDispatch();
  const { values, handleBlur, handleChange } = formik;
  const [selectedId, setSelectedId] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const me = useSelector(({ profile }: IAppState) => profile.me, shallowEqual);
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const isVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const isMyTender = useMemo(() => me?.company?.id === tender?.client?.id, [me, tender]);
  const isMainProp = useMemo(
    () => !!participations?.find(item => item.main),
    [participations]
  );

  const classes1 = useStyles();
  const { classes } = classes1;
  const homeClasses = homeStyles();
  const { page, perPage, total } = useSelector(
    ({ tenders }: IAppState) => ({
      page: tenders.pagePart,
      perPage: tenders.perPagePart,
      total: tenders.totalPart,
    }),
    shallowEqual
  );

  const selectAction = useCallback((id: number) => setSelectedId(id), [setSelectedId]);

  const translates: { [type: string]: string } = {
    new: fm('PARTICIPATION.TABLE.ACTIVE_TRUE'),
    canceled: fm('PARTICIPATION.TABLE.ACTIVE_FALSE'),
  };

  return (
    <>
      <div className={classes.wrapper}>
        {(me?.is_admin || isMyTender) && (
          <div className={classes.checkbox}>
            <FormControlLabel
              label={fm('TENDER.PROP.PUBLIC')}
              control={
                <Checkbox
                  color='primary'
                  value={values.publicProposals}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name='publicProposals'
                  checked={values.publicProposals}
                />
              }
            />
          </div>
        )}
        {/* <TextField
          style={{ marginBottom: 16 }}
          select
          margin='normal'
          label={fm('TENDER.TYPE.TYPE')}
          value={values.type}
          onChange={handleChange}
          name='type'
          variant='outlined'
          helperText={touched.type && errors.type}
          error={Boolean(touched.type && errors.type)}
          disabled={!isEdit}
          InputProps={{
            classes: {
              root: classes.input,
              focused: classes.inputFocused,
              notchedOutline: classes.notchedOutline,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.labelTextField,
              focused: classes.labelTextFieldFocused,
              shrink: classes.labelTextFieldShrink,
            },
          }}
          SelectProps={{
            classes: {
              root: classes.selectFocused,
            },
          }}
        >
          <MenuItem value='quote'>{fm('TENDER.TYPE.QUOTATION')}</MenuItem>
          <MenuItem value='placement'>{fm('TENDER.TYPE.PLACEMENT')}</MenuItem>
        </TextField> */}
        {/* <TextField
          style={{ marginBottom: 16 }}
          select
          margin='normal'
          label={fm('TENDER.TYPE.REINS')}
          value={values.proportionally}
          onChange={handleChange}
          name='proportionally'
          variant='outlined'
          helperText={touched.proportionally && errors.proportionally}
          error={Boolean(touched.proportionally && errors.proportionally)}
          disabled={!isEdit}
          InputProps={{
            classes: {
              root: classes.input,
              focused: classes.inputFocused,
              notchedOutline: classes.notchedOutline,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.labelTextField,
              focused: classes.labelTextFieldFocused,
              shrink: classes.labelTextFieldShrink,
            },
          }}
          SelectProps={{
            classes: {
              root: classes.selectFocused,
            },
          }}
        >
          <MenuItem value='prop'>{fm('TENDER.TYPE.PROP')}</MenuItem>
          <MenuItem value='no_prop'>{fm('TENDER.TYPE.NO_PROP')}</MenuItem>
        </TextField> */}
      </div>
      {(me?.is_admin || isManager) && (
        <Button
          color='primary'
          variant='contained'
          onClick={() => setShowModal(true)}
          style={{ marginTop: 25, marginBottom: 25 }}
        >
          {fm('PARTICIPATION.TABLE.ADD')}
        </Button>
      )}
      {showTable && participations && participations.length > 0 && (
        <Card className={classes.card}>
          <CardContent className={homeClasses.classes.tableContainer}>
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <SHeadTableCell>{fm('PARTICIPATION.TABLE.COMPANY')}</SHeadTableCell>
                  <SHeadTableCell style={{ minWidth: 90 }}>
                    {fm('PARTICIPATION.FORM.SUM')}
                  </SHeadTableCell>
                  {/* <SHeadTableCell style={{ minWidth: 90 }}>
                    {fm('PARTICIPATION.FORM.TARIFF')}
                  </SHeadTableCell> */}
                  {/* <SHeadTableCell style={{ minWidth: 110 }}>
                    {fm('PARTICIPATION.FORM.FRANCHISE')}
                  </SHeadTableCell> */}
                  <SHeadTableCell>{fm('PARTICIPATION.FORM.COMMENT')}</SHeadTableCell>
                  {(me?.is_admin || isManager) && (
                    <SHeadTableCell>{fm('COMMON.TABLE.STATUS')}</SHeadTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {participations.map(item => (
                  <TableRow key={item.id}>
                    <TableCell>
                      {item.company ? (
                        <p>{item.company.name}</p>
                      ) : (
                        <div className={classes.emptyCompany}>-</div>
                      )}
                    </TableCell>
                    <TableCell>{thousands(String(item.amount || ''))}</TableCell>
                    {/* <TableCell>{item.rate || ''}</TableCell>
                    <TableCell>{thousands(String(item.franchise || ''))}</TableCell> */}
                    <TableCell style={{ whiteSpace: 'pre-wrap' }}>{item.comment}</TableCell>
                    {(me?.is_admin || isManager) && (
                      <TableCell>
                        {selectedId !== item.id ? (
                          <ColorButton
                            color='#c9f7f5'
                            onClick={() => selectAction(item.id)}
                            disable={!!(isBuyer || isVendor || item.main)}
                          >
                            {item.main ? fm('PARTICIPATION.CEDAR') : translates[item.status]}
                          </ColorButton>
                        ) : (
                          <TextField
                            select
                            margin='normal'
                            value={item.status}
                            onChange={event => {
                              changeStatus(item.id, event.target.value);
                              selectAction(-1);
                            }}
                            variant='outlined'
                            className={classes.typeList}
                            size='small'
                          >
                            <MenuItem value='new'>
                              {fm('PARTICIPATION.TABLE.ACTIVE_TRUE')}
                            </MenuItem>
                            <MenuItem value='canceled'>
                              {fm('PARTICIPATION.TABLE.ACTIVE_FALSE')}
                            </MenuItem>
                          </TextField>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePaginator
                    page={page}
                    realPerPage={participations.length}
                    perPage={perPage}
                    total={total}
                    fetchRows={(data: { page: any; perPage: any }) =>
                      tender?.id &&
                      me &&
                      dispatch(
                        tendersActions.fetchParticipation({
                          tender_id: tender.id,
                          page: data.page,
                          per_page: data.perPage,
                          company_id:
                            isBuyer && !tender.public_proposals ? me.company?.id : undefined,
                          statuses: isVendor ? 'new,accepted' : undefined,
                        })
                      )
                    }
                    label={fm('PARTICIPATION.TABLE.PERPAGE')}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </CardContent>
        </Card>
      )}
      <ModalAddParticipation
        showModal={showModal}
        setShowModal={value => setShowModal(value)}
        handleCreatePart={handleCreatePart}
        isMainProp={isMainProp}
      />
    </>
  );
};

export default React.memo(TableParticipations);
