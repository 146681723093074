import React, { useEffect } from 'react';
import { Typography, TextField } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Preloader from '../../../../components/ui/Preloader/Preloader';
import { IAppState } from '../../../../store/rootDuck';
import { useGetReviews } from '../hooks/useGetReviews';
import Rating from './Rating';
// import { useDeleteReview } from '../hooks/useDeleteReview';
import { useStylesReviewsList } from '../hooks/useStyles';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import { useCreateReview } from '../hooks/useCreateReviewFormik';
import { ButtonWithLoader } from '../../../../components/ui/Buttons';

interface IProps {
  productId: string | number;
  meAdd?: boolean;
  setOpen?: (value: boolean) => void;
}

const ReviewsList: React.FC<IProps> = ({ productId, meAdd = false, setOpen }) => {
  const styles = useStylesReviewsList();
  const { classes } = styles;
  const intl = useIntl();
  const fm = useFormatMessage();
  const reviewsProduct = useSelector(
    ({ products: { reviewsProduct } }: IAppState) => reviewsProduct,
    shallowEqual
  );
  const me = useSelector(({ profile: { me } }: IAppState) => me, shallowEqual);
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);

  const [fetchReviews, loading] = useGetReviews();
  const [createRevieFormik, loadingCreate, successCreate] = useCreateReview(
    productId,
    () => setOpen && setOpen(false)
  );
  // const [deleteReview] = useDeleteReview();
  // const [pageReviews, setPageReviews] = useState({ perPage: 20, page: 1 });

  useEffect(() => {
    // productId && fetchReviews(productId, pageReviews.perPage, pageReviews.page);
    productId && fetchReviews(productId, 20, 1);
  }, [productId, fetchReviews]);

  useEffect(() => {
    if (successCreate) {
      // fetchReviews(productId, pageReviews.perPage, pageReviews.page);
      fetchReviews(productId, 20, 1);
    }
  }, [successCreate]);

  // const handleDeleteReview = useCallback(
  //   (id: number) => {
  //     deleteReview(id, productId, pageReviews.perPage, pageReviews.page);
  //   },
  //   [productId, pageReviews]
  // );

  if (loading || loadingCreate)
    return (
      <div className={classes.container}>
        <Preloader />
      </div>
    );
  return (
    <div className={classes.container}>
      <div>
        <Rating rating={reviewsProduct?.rating} />
        <Typography variant='h6' className={classes.textTotalRating}>
          {reviewsProduct?.rating
            ? fm('RATING.AVERAGE_RATING', { rating: reviewsProduct?.rating })
            : fm('RATING.NO_RATING')}
        </Typography>
      </div>
      {reviewsProduct && reviewsProduct.reviews.length < 1 && (
        <Typography variant='h4' className={classes.textTotalRating}>
          {fm('RATING.NO_REVIEWS')}
        </Typography>
      )}

      {(isBuyer || isAdmin) && !meAdd && (
        <form
          onSubmit={createRevieFormik.handleSubmit}
          className='kt-form'
          style={{ marginTop: 50 }}
        >
          <Rating
            size='24px'
            rating={createRevieFormik.values.rating}
            onChange={rating => createRevieFormik.setFieldValue('rating', rating)}
          />

          {!!createRevieFormik.errors.rating && (
            <Typography
              component='h6'
              variant='subtitle1'
              color='error'
              style={{ textAlign: 'center' }}
            >
              {createRevieFormik.errors.rating}
            </Typography>
          )}

          <TextField
            type='text'
            label={fm('RATING.REVIEW.LABEL')}
            margin='normal'
            multiline
            rows={3}
            variant='outlined'
            name='text'
            value={createRevieFormik.values.text}
            onBlur={createRevieFormik.handleBlur}
            onChange={createRevieFormik.handleChange}
            helperText={createRevieFormik.touched.text && createRevieFormik.errors.text}
            error={Boolean(createRevieFormik.touched.text && createRevieFormik.errors.text)}
          />

          <ButtonWithLoader disabled={loadingCreate} loading={loadingCreate}>
            {intl.formatMessage({ id: 'COMMON.BUTTON.CREATE' })}
          </ButtonWithLoader>
        </form>
      )}
    </div>
  );
};

export default ReviewsList;
